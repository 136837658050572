import React from "react";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import PageTitle from "../../element/page-title";
import { TabPanel, a11yProps } from "../../shared/Tabs";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import BtcChart from "../../charts/btc";
import LtcChart from "../../charts/ltc";
import XrpChart from "../../charts/xrp";

import { connect } from "react-redux";
import { listChartSchemes } from "../../actions/charts";
import {
  twoFactorAuthentication,
  twoFactorNomineeValidation,
} from "../../actions/orders";

import AreaChart from "../../charts/area";
import RadialChart from "../../charts/radial";
import Box from "@mui/material/Box";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";
// import ReactApexChart from "react-apexcharts";
import { Link, useLocation, BrowserRouter as Router } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
//import Spinner from "../../layout/spinner";
import "react-datepicker/dist/react-datepicker.css";
//import DatePicker from 'react-date-picker';
import {
  Container,
  Table,
  Modal,
  Form,
  Row,
  Col,
  Button,
  Label,
  Spinner,
  Alert,
} from "react-bootstrap";
import OTPInput, { ResendOTP } from "otp-input-react";

import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";

class Charts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tab_investment_category: 0,
      tab_portfolio: 0,
      sip_order_frequency_list: [],
      frequency_option_list: [],
      sip_order_frequency_option_list: [],
      sip_installment_numbers_list: [],
      is_sip_calculator: true,

      swp_order_frequency_list: [],

      show_display_option: "portfolio",
      section_display_list: [
        {
          id: "chart",
          header: "VIEW CHART",
          icon: "fa-plus fa-lg",
          show: false,
        },
        {
          id: "returnscalculator",
          header: "RETURNS CALCULATOR",
          icon: "fa-plus fa-lg",
          show: false,
        },
        {
          id: "holdings",
          header: "HOLDINGS LIST",
          icon: "fa-plus fa-lg",
          show: false,
        },
        {
          id: "returns",
          header: "RETURNS",
          icon: "fa-plus fa-lg",
          show: false,
        },
        {
          id: "sector",
          header: "SECTORS",
          icon: "fa-plus fa-lg",
          show: false,
        },
        {
          id: "fundmanager",
          header: "FUND MANAGERS",
          icon: "fa-plus fa-lg",
          show: false,
        },
        {
          id: "schemeinfo",
          header: "SCHEME INFORMATION",
          icon: "fa-plus fa-lg",
          show: false,
        },
      ],

      is_2fa_button_clicked: false,
      modal_2fa_popup: false,
      two_factor_authentication: {
        mobile_otp: "",
        email_otp: "",
        twofactor_token_id: "",
      },

      modal_options_popup: false,
      modal_options: {
        id: ``,
        header: ``,
      },
      setPrincipal: "",
      sipPrincipal: "",
      onetimePrincipal: "",
      setYears: 5,
      setInterest: 10,
      setResult: "",
      sip_order: {
        display_text: "",
        scheme_code: "",
        sip_installment_numbers: "",
        sip_transaction_mode: "",
        sip_amount: "",
        sip_date: "", //new Date(),
        sip_date_value: "", //new Date(),
        is_nach_mandate: false,
        sip_frequency: "",
        lockin_period_flag: "",
        lockin_period: "",
        purchase_cutoff_time: "",
      },
      sip_minimum_date: new Date(new Date().setDate(new Date().getDate() + 8)),
      sip_maximum_date: new Date().setDate(new Date().getDate() + 40),
      sip_exclude_date_list: [],
      general_order_frequency_list: [],
      general_order: {
        display_text: "",
        scheme_code: "",
        order_amount: "",
        order_date: "",
        lockin_period_flag: "",
        lockin_period: "",
        purchase_cutoff_time: "",
      },

      selected_order: {},
      selected_sip_order: {},
      is_order_checkout_allowed: false,
      is_bse_client_code_approved: false,
      order_checkout_validation_message: "",
      order_checkout_redirect_url: "",
      bank_account_list: [],
      nominee_list: [],
      is_no_nominee_registered: true,
      no_nominee_form: {},
      client_code: "",

      is_bse_nominee_verified: false,
      is_kyc_esign_verified: false,
      login_stage: "",
      is_pending_order: false,

      mfapi_scheme_code: "",

      holdings_list: [],
      fund_manager_list: [],
      scheme_information_html: ``,

      returns_result: {
        sip: {},
        onetime: {},
      },

      show_sipdate_popup: false,
      popup_sip_date_list: [],

      sip_returns_calculation: {},
      lumpsum_returns_calculation: {},

      last_nav_display_text: 0.0,
      last_nav_difference_display_text: 0.0,

      scheme_name: "",
      showmodal: "false",
      _date: new Date(),

      series: [],
      chartSeries: [],

      series_bar_height: 80,
      seriespie_sector_length: 0,
      seriespie_sector: [],

      // chartOptions: {
      //   chart: {
      //     type: "area",
      //     height: 300,
      //     foreColor: "#8C87C2",
      //     fontFamily: "Rubik, sans-serif",
      //     stacked: true,
      //     dropShadow: {
      //       enabled: true,
      //       // enabledSeries: [0],
      //       top: -2,
      //       left: 2,
      //       blur: 5,
      //       opacity: 0.06,
      //     },
      //     toolbar: {
      //       show: true,
      //     },
      //   },
      //   colors: ["#7B6FFF", "#1652F0"],
      //   stroke: {
      //     curve: "smooth",
      //     width: 3,
      //   },
      //   dataLabels: {
      //     enabled: false,
      //   },

      //   markers: {
      //     size: 0,
      //     strokeColor: "#fff",
      //     strokeWidth: 3,
      //     strokeOpacity: 1,
      //     fillOpacity: 1,
      //     hover: {
      //       size: 6,
      //     },
      //   },
      //   xaxis: {
      //     axisBorder: {
      //       show: true,
      //     },
      //     type: "datetime",
      //     tickPlacement: 'between'
      //   },
      //   yaxis: {
      //     labels: {
      //       offsetX: 10,
      //       offsetY: 0,
      //     },
      //     tickPlacement: 'between'
      //   },
      //   grid: {
      //     show: false,
      //     padding: {
      //       left: -5,
      //       right: 5,
      //     },
      //   },
      //   tooltip: {
      //     x: {
      //       format: "dd-MMM-yyyy",
      //     },
      //   },
      //   legend: {
      //     position: "top",
      //     horizontalAlign: "left",
      //   },
      //   fill: {
      //     type: "gradient",
      //     gradient: {
      //       shadeIntensity: 1,
      //       opacityFrom: 0.5,
      //       opacityTo: 0,
      //       stops: [0, 100, 100],
      //     },
      //   },
      // },

      chartOptions: {
        series: [],
        chart: {
          id: "area-datetime",
          type: "area",
          height: 350,
          toolbar: { show: false },
          zoom: {
            autoScaleYaxis: true,
          },
        },
        annotations: {
          yaxis: [
            {
              y: 30,
              borderColor: "#999",
              label: {
                show: true,
                text: "Support",
                style: {
                  color: "#fff",
                  background: "#00E396",
                },
              },
            },
          ],
          xaxis: [
            {
              // x: new Date('14 Nov 2017').getTime(),
              borderColor: "#999",
              yAxisIndex: 0,
              label: {
                show: true,
                // text: 'Rally',
                style: {
                  color: "#fff",
                  background: "#775DD0",
                },
              },
            },
          ],
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        xaxis: {
          type: "datetime",
          // min: new Date('01 Mar 2017').getTime(),
          max: new Date().getTime(),
          tickAmount: 6,
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100],
          },
        },
      },

      selection: "1m",

      /************* */
      seriespie_sector_bar: [],
      options_bar: {
        chart: {
          type: "bar",
          height: 50,
          
          padding: {
            top: -20, // Increase top padding to move the chart area down
            // Add other padding values as needed
          },
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: "bottom",
            },
          },
        },
        colors: [
          "#33b2df",
          "#8a8888",
          "#d4526e",
          "#13d8aa",
          "#A5978B",
          "#2b908f",
          "#f9a3a4",
          "#90ee7e",
          "#f48024",
          "#69d2e7",
          "#69d2e7",
          "#69d2e7",
          "#69d2e7",
          "#69d2e7",
          "#69d2e7",
          "#69d2e7",
          "#69d2e7",
          "#69d2e7",
          "#69d2e7",
          "#69d2e7",
          "#69d2e7",
          "#69d2e7",
          "#69d2e7",
          "#69d2e7",
          "#69d2e7",
        ],
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["#575555"],
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val + "%";
          },
          offsetX: 10,
        

          offsetY: 20,
        },

       
        stroke: {
          // width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
           
            show: true,
          },
        },


        // title: {
        //     text: 'Custom DataLabels',
        //     align: 'center',
        //     floating: true
        // },
        // subtitle: {
        //     text: 'Category Names as DataLabels inside bars',
        //     align: 'center',
        // },
        tooltip: {
          theme: "dark",
          x: {
            show: false,
          },
          y: {
            title: {
              // formatter: function () {
              //   return "";
              // },
            },
          },
        },
      },
      /************* */
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", () => {
      if (
        document.getElementById("navbar-chart-container") &&
        document.getElementById("navbar") &&
        document.getElementById("navbar").classList
      ) {
        const topBorder = document
          .getElementById("navbar-chart-container")
          .getBoundingClientRect().top;

        topBorder >= 0
          ? document.getElementById("navbar").classList.remove("fixed-chart")
          : document.getElementById("navbar").classList.add("fixed-chart");
      }
    });

    window.scrollTo(0, 0);

    let isMobile =
      window.matchMedia && window.matchMedia("(max-width: 480px)").matches;

    let received_data =
      isMobile &&
      this.props.location.state &&
      this.props.location.state["param_data"]
        ? this.props.location.state["param_data"]
        : this.props.match.params;

    if (received_data) {
      // received_data = received_data.param_data;
      sessionStorage.setItem("received_data", JSON.stringify(received_data));
      this.setState({ scheme_name: received_data["scheme_name"] });
    } else
      received_data = JSON.parse(
        sessionStorage.getItem("received_data") || JSON.stringify({})
      );

    this.loadChartList(received_data);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", null);
  }

  handleClose = () => this.setState({ showmodal: false });
  handleShow = () => this.setState({ showmodal: true });

  calculate = (e, is_sip) => {
    if (is_sip) this.setState({ sipPrincipal: e.target.value });
    else this.setState({ onetimePrincipal: e.target.value });

    // this.setState({ setPrincipal: e.target.value });
    this.calculateReturns(e.target.value, is_sip);
  };

  calculateReturns(value, is_sip_calculator) {
    let _investment = parseInt(isNaN(value) ? 0.0 : value);
    let _total_investment = 0.0;
    let _percent = 0.0;
    let _returns = 0.0;
    let _array = [];
    let _sip_returns = {};
    let _onetime_returns = {};

    if (is_sip_calculator) {
      //sip
      Object.keys(this.state.sip_returns_calculation).forEach((key) => {
        _sip_returns[key] = this.state.sip_returns_calculation[key];

        switch (key) {
          case "1y_percent":
            _total_investment = parseFloat(12 * _investment);
            _sip_returns["1y"] = (
              _total_investment +
              parseFloat((_sip_returns["1y_percent"] * _total_investment) / 100)
            ).toFixed(2);
            break;

          case "3y_percent":
            _total_investment = parseFloat(36 * _investment);
            _sip_returns["3y"] = (
              _total_investment +
              parseFloat((_sip_returns["3y_percent"] * _total_investment) / 100)
            ).toFixed(2);
            break;

          case "5y_percent":
            _total_investment = parseFloat(60 * _investment);
            _sip_returns["5y"] = (
              _total_investment +
              parseFloat((_sip_returns["5y_percent"] * _total_investment) / 100)
            ).toFixed(2);
            break;

          default:
            break;
        }
      });

      this.setState({
        returns_result: { ...this.state.returns_result, sip: _sip_returns },
      });
    } //one time
    else {
      let _lumpsum_returns = this.state.lumpsum_returns_calculation;

      Object.keys(_lumpsum_returns["absolute"]).forEach((key) => {
        _percent = _lumpsum_returns["absolute"][key];

        _returns =
          (parseFloat(_percent) * parseFloat(_investment)) / 100 +
          parseFloat(_investment);

        _onetime_returns[key.split("_")[0]] = _returns;
        _onetime_returns[key] = parseFloat(_percent).toFixed(2);
        _onetime_returns[key + "_annualized"] =
          _lumpsum_returns["annualized"][key];
      });

      this.setState({
        returns_result: {
          ...this.state.returns_result,
          onetime: _onetime_returns,
        },
      });
    }
  }

  handleRadioChange = (value, attribute) => {
    this.setState({ [attribute]: value });
    // this.calculateReturns(this.state.setPrincipal, value);
    this.calculateReturns(
      value ? this.state.sipPrincipal : this.state.onetimePrincipal,
      value
    );
  };

  filterHistoricalData(timeline, difference) {
    this.setState({
      selection: timeline,
    });

    var today = new Date();
    var monthOnedate = new Date(new Date().setDate(today.getDate() - 90));
    var sixmonthdate = new Date(new Date().setDate(today.getDate() - 180));
    var Yeardate = new Date(new Date().setDate(today.getDate() - 365));

    var filter_date = new Date(
      new Date().setDate(today.getDate() - difference)
    );

    let _nav_data_list = this.props.chart_result["nav_data_list"] || [];
    if (_nav_data_list.length > 0) {
      _nav_data_list = _nav_data_list.filter((el) => {
        return el[0] >= filter_date.getTime();
      });

      this.setState({
        series: [
          {
            name: "Buy",
            data: _nav_data_list,
          },
        ],
        chartSeries: [
          {
            name: "Buy",
            data: _nav_data_list,
          },
        ],
        // options: {
        //   xaxis: {
        //     min: today,
        //     max: filter_date,
        //   },
        // },
      });
    }
  }

  loadChartList = (received_data) => {
    if (this.state.sip_minimum_date.getDate() === 29) {
      this.setState({
        isloading: true,
        sip_minimum_date: new Date(
          new Date(this.state.sip_minimum_date).setDate(
            new Date(this.state.sip_minimum_date).getDate() + 3
          )
        ),
      });
    } else if (this.state.sip_minimum_date.getDate() === 30) {
      this.setState({
        isloading: true,
        sip_minimum_date: new Date(
          new Date(this.state.sip_minimum_date).setDate(
            new Date(this.state.sip_minimum_date).getDate() + 2
          )
        ),
      });
    } else if (this.state.sip_minimum_date.getDate() === 31) {
      this.setState({
        isloading: true,
        sip_minimum_date: new Date(
          new Date(this.state.sip_minimum_date).setDate(
            new Date(this.state.sip_minimum_date).getDate() + 1
          )
        ),
      });
    } else this.setState({ isloading: true });

    if (received_data["scheme_isin"]) {
      this.props
        .listChartSchemes({
          param_isin: received_data["scheme_isin"],
          param_scheme_code: received_data["scheme_code"],
          param_scheme_name: received_data["scheme_name"],
          // param_data: received_data
        })
        .then(async (response) => {
          this.setState({ isloading: false });

          if (this.props.chart_result["code"] === "000") {
            let sip_exclude_date_list = [];
            this.props.chart_result["sip_exclude_date_list"].forEach((el) => {
              sip_exclude_date_list.push(Date.parse(el));
            });

            this.setState({
              sip_order: {
                ...this.state.sip_order,
                sip_date: new Date(this.props.chart_result["sip_minimum_date"]), //this.state.sip_minimum_date
              },
              sip_minimum_date: Date.parse(
                this.props.chart_result["sip_minimum_date"]
              ),
              sip_maximum_date: Date.parse(
                this.props.chart_result["sip_maximum_date"]
              ),
              sip_exclude_date_list: sip_exclude_date_list, //this.props.chart_result['sip_exclude_date_list'],
              is_order_checkout_allowed:
                this.props.chart_result["is_order_checkout_allowed"],
              order_checkout_validation_message:
                this.props.chart_result["order_checkout_validation_message"],
              order_checkout_redirect_url:
                this.props.chart_result["order_checkout_redirect_url"],

              is_bse_client_code_approved:
                this.props.chart_result["is_bse_client_code_approved"],
              is_bse_nominee_verified:
                this.props.chart_result["is_bse_nominee_verified"],
              is_kyc_esign_verified:
                this.props.chart_result["is_kyc_esign_verified"],

              is_pending_order: this.props.chart_result["is_pending_order"],
              login_stage: this.props.chart_result["login_stage"],
              mfapi_scheme_code: this.props.chart_result["scheme_code"],
            });

            let _nav_data_list = this.props.chart_result["nav_data_list"] || [];

            if (this.props.chart_result && _nav_data_list) {
              /*
              let _series = this.state.series;
              _series.forEach((el) => {
                if (el["name"] === "Buy") {
                  el["data"] = _nav_data_list;
                }
              });
              */

              let _last_nav_display_text = parseFloat(_nav_data_list[0][1]);
              let _last_nav_difference_display_text = 0.0;

              if (_nav_data_list.length > 1) {
                _last_nav_difference_display_text =
                  parseFloat(_last_nav_display_text) -
                  parseFloat(_nav_data_list[1][1]);
              }

              this.setState({
                series: [
                  {
                    name: "Buy",
                    data: _nav_data_list,
                  },
                ],
                chartSeries: [
                  {
                    name: "Buy",
                    data: _nav_data_list,
                  },
                ],
                last_nav_display_text: _last_nav_display_text,
                last_nav_difference_display_text:
                  _last_nav_difference_display_text,

                sip_order_frequency_list:
                  this.props.chart_result["sip_order_frequency"],
                frequency_option_list:
                  this.props.chart_result["sip_order_terms"],
                general_order_frequency_list:
                  this.props.chart_result["general_order_terms"],

                sip_returns_calculation:
                  this.props.chart_result["sip_returns_calculation"],
                lumpsum_returns_calculation:
                  this.props.chart_result["lumpsum_returns_calculation"],
              });
            }

            let _seriespie_sector =
              this.props.chart_result["seriespie_sector"] || [];

            _seriespie_sector = _seriespie_sector.map((el) => {
              return parseFloat(el).toFixed(2);
            });

            let _series_bar_height = this.state.series_bar_height;
            if (_seriespie_sector <= 5) _series_bar_height = 120;
            else {
              if (_seriespie_sector > 5 && _seriespie_sector <= 10)
                _series_bar_height = 40;
              else if (_seriespie_sector > 10 && _seriespie_sector <= 15)
                _series_bar_height = 30;
              else if (_seriespie_sector > 15 && _seriespie_sector <= 20)
                _series_bar_height = 20;
              else if (_seriespie_sector > 20) _series_bar_height = 10;
            } 
            this.setState({
              bank_account_list:
                this.props.chart_result["bank_account_list"] || [],
              nominee_list: this.props.chart_result["nominee_list"] || [],
              is_no_nominee_registered:
                this.props.chart_result["is_no_nominee_registered"] || false,
              no_nominee_form: this.props.chart_result["no_nominee_form"],
              client_code: this.props.chart_result["client_code"],

              holdings_list: this.props.chart_result["holdings_list"] || [],
              fund_manager_list:
                this.props.chart_result["fund_manager_list"] || [],
              scheme_information_html:
                this.props.chart_result["scheme_information_html"] || ``,
              seriespie_sector: [
                {
                  data: this.props.chart_result["seriespie_sector"],
                },
              ],
              seriespie_sector_bar: [
                {
                  data: _seriespie_sector,
                },
              ],
              seriespie_sector_length: _seriespie_sector.length,
              series_bar_height: _series_bar_height,
              tab_investment_category:
                this.props.chart_result["sip_order_terms"].length > 0 ? 0 : 1,
            });

            let _optionsbar = this.state.options_bar;
            let _optionspie_sector = [];
            let _optionspie_sector_bar = [];
            

            this.props.chart_result["optionspie_sector"].forEach((el, i) => {
              _optionspie_sector.push(
                el +
                  "(" +
                  parseFloat(
                    this.props.chart_result["seriespie_sector"][i]
                  ).toFixed(2) +
                  "%)"
              );
              _optionspie_sector_bar.push(el);
            });
            

            _optionsbar["xaxis"].categories = _optionspie_sector || []; //this.props.chart_result["optionspie_sector"];

            let __optionsbar = this.state.options_bar;
            __optionsbar["xaxis"].categories = _optionspie_sector_bar; //_optionspie_sector

            this.setState({
              optionspie_sector: _optionsbar,
              options_bar: __optionsbar,
            });
            // await this.sleep(2000);

            // this.setState({pageIndexAvailability: this.props.result['list_availability'] })
          } else alert(this.props.chart_result["messageText"]);
          
        })
        .catch((err) => {
          console.log(err)
          alert(err) ;
        });
    
    }
  };

  handleChangeSelect = (selectedOption, attribute, input, category) => {
    /*
    if (input)
      this.setState({ [attribute]: { ...this.state[attribute], [input]: selectedOption } });
    else
      this.setState({ [input]: selectedOption });
    */

    if (input === "sip_frequency") {
      let _loc = [];
      let _list = this.state.frequency_option_list.filter((el) => {
        _loc = el["value" /*'sip_frequency'*/].split("-");
        return _loc[_loc.length - 1] === selectedOption.value;
      });

      if (category === "sip") {
        this.setState({
          sip_order: {
            ...this.state.sip_order,
            sip_frequency: selectedOption.value,
            display_text: selectedOption.value,
            scheme_code: "",
            scheme_name: "",
            transaction_mode: "",
            lockin_period_flag: "",
            lockin_period: "",
            purchase_cutoff_time: "",
          },
          sip_installment_numbers_list: [],
          sip_order_frequency_option_list: _list,
        });
      }
    } else if (input === "scheme_code") {
      if (category === "sip") {
        let sip_exclude_date_list = [];
        (selectedOption["sip_exclude_date_list"] || []).forEach((el) => {
          sip_exclude_date_list.push(Date.parse(el));
        });

        this.setState({
          selected_sip_order: selectedOption,
          sip_order: {
            ...this.state.sip_order,
            // sip_frequency: selectedOption.label.split('-')[0],
            // display_text: selectedOption.label,
            scheme_code:
              selectedOption.scheme_code || selectedOption.value.split("-")[0],
            scheme_name: selectedOption.scheme_name || "",
            scheme_isin: selectedOption.scheme_isin || "",
            transaction_mode: selectedOption.transaction_mode || "",
            lockin_period_flag: selectedOption.lockin_period_flag || "",
            lockin_period: selectedOption.lockin_period || "",
            purchase_cutoff_time: selectedOption.purchase_cutoff_time || "",
            is_pause_allowed: selectedOption.is_pause_allowed || false,
            sip_exclude_date_list: sip_exclude_date_list,
            pause_text: selectedOption.pause_text || "",
          },
          popup_sip_date_list: selectedOption["date_list"],
          sip_installment_numbers_list:
            selectedOption["installment_number_list"],
          sip_exclude_date_list: sip_exclude_date_list,
        });
      } else {
        this.setState({
          selected_order: selectedOption,
          general_order: {
            ...this.state.general_order,
            display_text: selectedOption.label,
            scheme_code:
              selectedOption.param_scheme_code ||
              selectedOption.value.split("-")[0],
            scheme_name: selectedOption.param_scheme_name || "",
            scheme_isin: selectedOption.param_isin || "",
            transaction_mode: "",
            lockin_period_flag: selectedOption.param_lockin_period_flag || "",
            lockin_period: selectedOption.param_lockin_period || "",
            purchase_cutoff_time:
              selectedOption.param_purchase_cutoff_time || "",
          },
        });
      }
    } else if (input === "sip_installment_numbers") {
      if (category === "sip")
        this.setState({
          sip_order: {
            ...this.state.sip_order,
            sip_installment_numbers: selectedOption.value,
          },
        });
    }
  };

  handleChange = (event, attribute) => {
    this.setState({ [attribute]: 1 });
  };

  startSIP = () => {
    // const { otp } = this.state;
    if (this.state.is_order_checkout_allowed)
      this.props.history.push({
        pathname: "/sipCheckout/",
        state: {
          selected_order: Object.assign(this.state.selected_sip_order, {
            mfapi_scheme_code: this.state.mfapi_scheme_code,
          }),
          sip_order: this.state.sip_order,
          list_bank_account: this.state.bank_account_list,
          list_nominee: this.state.nominee_list.filter((el) => {
            return el["id"] !== "none";
          }),
          is_no_nominee_registered: this.state.is_no_nominee_registered,
          no_nominee_form: this.state.no_nominee_form,
          client_code: this.state.client_code,
          isloading: true,
        },
      });
    else {
      if (this.state.order_checkout_redirect_url.length > 6)
        this.props.history.push({
          pathname: "/" + this.state.order_checkout_redirect_url + "/",
          state: {},
        });
      //
    }
  };

  sipParameterValidation() {
    let _sip_order = this.state.sip_order;
    return (
      parseFloat(_sip_order["sip_amount"]) >=
        parseFloat(
          this.state.selected_sip_order["minimum_installment_amount"]
        ) &&
      parseFloat(_sip_order["sip_amount"]) <=
        parseFloat(
          this.state.selected_sip_order["maximum_installment_amount"]
        ) &&
      _sip_order["sip_frequency"].length > 2 &&
      _sip_order["sip_installment_numbers"] > 0 &&
      this.state.sip_order["sip_date_value"].length > 7
    );
  }

  handleChangeDate(date) {
    this.setState({
      sip_order: { ...this.state.sip_order, sip_date: date },
      // sip_minimum_date: date
    });
  }

  startOrder() {
    this.props.history.push({
      pathname: "/orderCheckout/",
      state: {
        selected_order: Object.assign(this.state.selected_order, {
          mfapi_scheme_code: this.state.mfapi_scheme_code,
        }),
        general_order: this.state.general_order,
        list_bank_account: this.state.bank_account_list,
        list_nominee: this.state.nominee_list.filter((el) => {
          return el["id"] !== "none";
        }),
        is_no_nominee_registered: this.state.is_no_nominee_registered,
        no_nominee_form: this.state.no_nominee_form,
        client_code: this.state.client_code,
      },
    });
  }

  orderParameterValidation() {
    let _general_order = this.state.general_order;

    return (
      (_general_order["order_amount"] || "").trim().length > 0 &&
      !isNaN(_general_order["order_amount"]) &&
      parseFloat(_general_order["order_amount"]) >=
        parseFloat(this.state.selected_order["minimum_purchase_amount"]) &&
      parseFloat(_general_order["order_amount"]) <=
        parseFloat(this.state.selected_order["maximum_purchase_amount"]) &&
      this.state.is_order_checkout_allowed &&
      !this.state.is_pending_order
    );
  }

  on2FactorAuthentication = () => {
    this.setState({ isloading: true });

    this.props
      .twoFactorAuthentication({
        client_info: {
          two_factor_authentication: this.state.two_factor_authentication,
          request_action: "nominee update",
        },
      })
      .then((response) => {
        if (this.props.order_result["code"] !== "000") {
          this.setState({ isloading: false });
          alert(this.props.order_result["messageText"]);
        } else {
          let _result = this.props.order_result; //["result"];

          if (_result["twofactor_id"]) {
            this.setState({
              isloading: false,
              modal_2fa_popup: true,
              two_factor_authentication: {
                ...this.state.two_factor_authentication,
                twofactor_token_id: _result["twofactor_id"],
              },
              is_2fa_button_clicked: true,
            });
          }
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  validate2FactorAuthentication = () => {
    this.setState({ isloading: true });

    this.props
      .twoFactorNomineeValidation({
        two_factor_authentication: this.state.two_factor_authentication,
      })
      .then((response) => {
        if (this.props.order_result["code"] !== "000") {
          this.setState({ isloading: false });
          alert(this.props.order_result["messageText"]);
        } else {
          let _result = this.props.order_result; //["result"];

          if (_result["twofactor_id"]) {
            this.setState({
              isloading: false,
              modal_2fa_popup: false,
              two_factor_authentication: {
                ...this.state.two_factor_authentication,
                mobile_otp: "",
                email_otp: "",
                twofactor_token_id: "",
              },
              is_order_checkout_allowed:
                this.props.order_result["is_order_checkout_allowed"],
              is_bse_client_code_approved:
                this.props.order_result["is_bse_client_code_approved"],
              is_bse_nominee_verified:
                this.props.order_result["is_bse_nominee_verified"],
              is_2fa_button_clicked: false,
            });
          }
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  fnDateOrdinalformat(date, tmp) {
    // console.log(date);
    // console.log(tmp);

    return [
      (tmp = date.getDate()) + ([, "st", "nd", "rd"][/1?.$/.exec(tmp)] || "th"),
      [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ][date.getMonth()],
      date.getFullYear(),
    ].join(" ");
  }

  numberWithCommas(x) {
    return x.toString().split(".")[0].length > 3
      ? x
          .toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  }

  showhideSection = (item) => {
    /*
    let _section_display_list = this.state.section_display_list;

    _section_display_list.forEach(el => {
      if (el['id'] === item['id']) {
        el['show'] = !item['show'];
        el['icon'] = el['show'] ? 'fa-minus fa-lg' : 'fa-plus fa-lg';
      }
      else {
        el['show'] = false;
        el['icon'] = 'fa-plus fa-lg';
      }

    });
    */
    this.setState({
      // section_display_list: _section_display_list,
      modal_options_popup: true,
      modal_options: item,
    });
  };

  createDefaultMarkup(text) {
    return {
      __html: text,
    };
  }

  render() {
    let return_data = this.state.is_sip_calculator
      ? this.state.returns_result["sip"]
      : this.state.returns_result["onetime"];

    return (
      <>
        <Header1 />
        <Sidebar />
        {this.state.isloading && (
          <div id="navbar-chart-container" style={{marginLeft:"10px",paddingRight:"10px"}}>
            <div id="navbar">
              <div class="row">
                <div class="container">
                  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                    {/* <div className="card" style={{ backgroundColor: '#f8f8fb' }}>
                <div className="card-header"> */}
                    <Alert variant="secondary text-center">
                      <Alert.Heading>
                        {`We are fetching `}
                        {this.state.scheme_name.split("-")[0]}
                        {this.state.scheme_name.split("-").length > 1 && (
                          <span style={{ marginLeft: 5 }} class="card-title">
                            {this.state.scheme_name.split("-")[1]}
                          </span>
                        )}
                      </Alert.Heading>
                    </Alert>
                    {this.state.isloading && (
                      <Spinner
                        animation="border"
                        variant="dark"
                        style={{ position: "fixed", top: "50%", left: "50%" }}
                      />
                    )}
                    {/* </div>
              </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {
          <div id="navbar-chart-container">
            <div class="container">
              <div class="row">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  {!this.state.isloading &&
                    !this.state.is_order_checkout_allowed && (
                      <div id="navbar">
                        <div
                          className="card"
                          style={{ backgroundColor: "#f8f8fb" }}
                        >
                          <div className="card-header">
                            <div>
                              <p
                                style={{
                                  textAlign: "center",
                                  fontSize: 16,
                                  backgroundColor: "#f5857d",
                                  padding: 4,
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                  margin: 4,
                                  borderRadius: 4,
                                }}
                              >
                                {this.state.order_checkout_validation_message}
                                {` `}
                              </p>

                              <div>
                                {this.state.is_bse_client_code_approved &&
                                !this.state.is_bse_nominee_verified ? (
                                  <div>
                                    <a
                                      role="button"
                                      style={{
                                        fontSize: 16,
                                        color: "#e05d38",
                                        textDecoration: "underline",
                                      }}
                                      onClick={() => {
                                        this.on2FactorAuthentication();
                                      }}
                                    >
                                      Click here
                                    </a>
                                    <span
                                      style={{
                                        textAlign: "center",
                                        fontSize: 16,
                                        whiteSpace: "pre-wrap",
                                        overflowWrap: "break-word",
                                      }}
                                    >
                                      {`  `} to complete your two factor
                                      authentication
                                    </span>
                                  </div>
                                ) : (
                                  <div>
                                    {
                                      //!this.state.is_kyc_esign_verified &&
                                      <a
                                        role="button"
                                        style={{
                                          fontSize: 16,
                                          color: "#e05d38",
                                          textDecoration: "underline",
                                        }}
                                        onClick={() => {
                                          this.props.history.push({
                                            pathname:
                                              "/" +
                                              this.state
                                                .order_checkout_redirect_url +
                                              "/",
                                          });
                                        }}
                                      >
                                        Click here
                                      </a>
                                    }
                                    {!this.state.is_kyc_esign_verified && (
                                      <span
                                        style={{
                                          textAlign: "center",
                                          fontSize: 16,
                                          whiteSpace: "pre-wrap",
                                          overflowWrap: "break-word",
                                        }}
                                      >
                                        {`  `} to complete the process
                                      </span>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  {!this.state.isloading && (
                    <div id="navbar">
                      <div
                        className="card"
                        style={{ backgroundColor: "#f8f8fb"}}
                      >
                        <p class="card-title" style={{ marginTop: "15px" }}>
                          {this.state.scheme_name}
                          {/* {this.state.scheme_name.split('-')[0]}
                        {this.state.scheme_name.split('-').length > 1 &&
                          <span class="card-title">
                            {` - `}{this.state.scheme_name.split('-')[1]}
                          </span>
                        }
                        {this.state.scheme_name.split('-').length > 2 &&
                          <span class="card-title" style={{
                            padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4,
                          }}>
                            {` `}{this.state.scheme_name.split('-')[2]}
                          </span>
                        } */}
                        </p>
                        <div class="card-title" style={{marginTop:"3px"}}>
                          NAV {` `}
                          <span style={{ fontFamily: "sans-serif" }}>
                            &#8377;{this.state.last_nav_display_text}
                          </span>
                          {` `}(
                          <span
                            style={{
                              color:
                                this.state.last_nav_difference_display_text < 0
                                  ? "red"
                                  : "#03b45e",
                            }}
                          >
                            {this.state.last_nav_difference_display_text.toFixed(
                              2
                            )}
                          </span>
                          )
                          {this.state.last_nav_difference_display_text > 0 && (
                            <i class="fa fa-arrow-up"></i>
                          )}
                          {this.state.last_nav_difference_display_text < 0 && (
                            <i class="fa fa-arrow-down"></i>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        }

        {!this.state.isloading && (
          <div
            style={{
              marginTop:
                this.state.show_display_option === "portfolio" ? 5 : 20,
            }}
          >
            <div class="container">
              {/* <div className="content-body" > */}
              <div class="row">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <div className="row">
                    <div class="col-xl-12">
                      <div className="authincation section-padding">
                        <form onSubmit={(event) => event.preventDefault()}>
                          <div className="container">
                            {/* <div className="row" > */}

                            {this.state.show_display_option === "portfolio" && (
                              <div>
                                
                                <table
                                  class="table table-striped responsive-table"
                                  style={{ marginTop: "40px", alignContent:"center" }}
                                >
                                  <tbody>
                                    {(
                                      this.state.section_display_list || []
                                    ).map((item, index) => (
                                      <div key={index} className="row">
                                        <div
                                          class="col-9"
                                          onClick={() =>
                                            this.showhideSection(item)
                                          }
                                        >
                                          <h5>{item["header"]}</h5>
                                          <hr />
                                        </div>
                                        <div
                                          class="col-3"
                                          onClick={() => {
                                            this.showhideSection(item);
                                          }}
                                        >
                                          {/* <button type="button"
                                                class="btn-sm btn-success"
                                                style={{ color: 'white', fontSize:16 }}
                                                onClick={() => {this.showhideSection(item)}}
                                              >
                                                View
                                              </button> */}
                                          <i
                                            class={`fa ${item["icon"]} fa-lg`}
                                            aria-hidden="true"
                                          ></i>
                                        </div>

                                        {this.state.isloading && (
                                          <Spinner
                                            animation="border"
                                            variant="dark"
                                            style={{
                                              position: "fixed",
                                              top: "50%",
                                              left: "50%",
                                            }}
                                          />
                                        )}

                                        {/* {
                                                      <div class="card-body" >
                                                        {item['id'] === 'chart' && item['show'] &&
                                                          <div id="chart-timeline">
                                                            <ReactApexChart
                                                              options={this.state.chartOptions}
                                                              series={this.state.chartSeries}
                                                              type="area"
                                                              height={350}
                                                            />
                                                          </div>
                                                        }
                                                      </div>
                                                    } */}
                                      </div>
                                    ))}
                                  </tbody>
                                </table>
                                <Fab
                                  style={{
                                    bottom: 22,
                                    left: -25,
                                    transform: "scale(0.8)",
                                  }}
                                  alwaysShowTitle={true}
                                  mainButtonStyles={{
                                    backgroundColor: "#d45950",
                                  }}
                                  icon={
                                    <i className="fa fa-arrow-left  fa-lg"></i>
                                  }
                                  onClick={() => this.props.history.goBack()}
                                ></Fab>

                                <Fab
                                  style={{
                                    bottom: 22,
                                    right: -20,
                                    transform: "scale(0.8)",
                                  }}
                                  // event="hover"
                                  alwaysShowTitle={true}
                                  mainButtonStyles={{
                                    backgroundColor: "#2ea34d",
                                  }}
                                  icon={
                                    <i className="fa fa-arrow-right  fa-lg"></i>
                                  }
                                  onClick={() =>
                                    this.setState({
                                      show_display_option: "investment",
                                    })
                                  }
                                  disabled={
                                    !this.state.is_order_checkout_allowed
                                  }
                                ></Fab>
                              </div>
                            )}

                            {this.state.show_display_option ===
                              "investment" && (
                              <div
                                className="table table-striped responsive-table"
                                style={{ marginTop: -5 }}
                              >
                                <Box>
                                  <Box
                                    sx={{
                                      borderBottom: 1,
                                      borderColor: "divider",
                                    }}
                                  >
                                    <Tabs
                                      value={this.state.tab_investment_category}
                                      onChange={(event, newValue) => {
                                        this.setState({
                                          tab_investment_category: newValue,
                                        });
                                      }}
                                      aria-label="basic tabs example"
                                    >
                                      {this.state.frequency_option_list.length >
                                        0 && (
                                        <Tab
                                          style={{ width: 140 }}
                                          label="SIP"
                                          {...a11yProps(0)}
                                        />
                                      )}
                                      {this.state.general_order_frequency_list
                                        .length > 0 && (
                                        <Tab
                                          style={{ width: 180 }}
                                          label="LUMPSUM"
                                          {...a11yProps(1)}
                                        />
                                      )}
                                    </Tabs>
                                  </Box>
                                  <TabPanel
                                    value={this.state.tab_investment_category}
                                    index={0}
                                  >
                                    <div class="buy-sell-widget">
                                      <form
                                        name="myform"
                                        class="currency_validate"
                                      >
                                        <div class="form-group">
                                          {/* <h5 class="mr-sm-2 text-gray-dark">Choose Frequency</h5 > */}

                                          <Select
                                            placeholder={`Choose Frequency`}
                                            value={this.state.sip_order_frequency_list.find(
                                              (object) =>
                                                object.value ===
                                                  this.state.sip_order[
                                                    "sip_frequency"
                                                  ] || ""
                                            )}
                                            onChange={(e) =>
                                              this.handleChangeSelect(
                                                e,
                                                "sip_order",
                                                "sip_frequency",
                                                "sip"
                                              )
                                            }
                                            options={
                                              this.state
                                                .sip_order_frequency_list
                                            }
                                            isSearchable={false}
                                          />
                                        </div>
                                        <div>
                                          <label></label>
                                        </div>
                                        <div class="form-group">
                                          <Select
                                            placeholder={`Choose Your Investment Range`}
                                            value={this.state.sip_order_frequency_option_list.find(
                                              (obj) =>
                                                obj.scheme_code ===
                                                  this.state.sip_order[
                                                    "scheme_code"
                                                  ] || ""
                                            )}
                                            onChange={(e) =>
                                              this.handleChangeSelect(
                                                e,
                                                "sip_order",
                                                "scheme_code",
                                                "sip"
                                              )
                                            }
                                            options={
                                              this.state
                                                .sip_order_frequency_option_list
                                            }
                                            isSearchable={false}
                                          />
                                        </div>

                                        {this.state.sip_order[
                                          "is_pause_allowed"
                                        ] && (
                                          <p
                                            class="mb-0"
                                            style={{ color: "green" }}
                                          >
                                            {this.state.sip_order["pause_text"]}
                                          </p>
                                        )}
                                        <div>
                                          <label></label>
                                        </div>

                                        <div class="form-group">
                                          <Select
                                            placeholder={`Choose number of installments`}
                                            value={this.state.sip_installment_numbers_list.find(
                                              (obj) =>
                                                obj.value ===
                                                  this.state.sip_order[
                                                    "sip_installment_numbers"
                                                  ] || ""
                                            )}
                                            onChange={(e) =>
                                              this.handleChangeSelect(
                                                e,
                                                "sip_order",
                                                "sip_installment_numbers",
                                                "sip"
                                              )
                                            }
                                            options={
                                              this.state
                                                .sip_installment_numbers_list
                                            }
                                            isSearchable={false}
                                          />
                                        </div>
                                        <div>
                                          <label></label>
                                        </div>

                                        <div class="form-group">
                                          <div className="row">
                                            <div class="col">
                                              {(
                                                this.state.sip_order[
                                                  "sip_frequency"
                                                ] || ""
                                              ).length < 4 && (
                                                <div
                                                  style={{ marginTop: 10 }}
                                                ></div>
                                              )}
                                              <span>
                                                Enter Amount{" "}
                                                <span
                                                  style={{
                                                    fontFamily: "sans-serif",
                                                  }}
                                                >
                                                  {" "}
                                                  &#8377;
                                                </span>
                                                {(
                                                  this.state.sip_order[
                                                    "sip_frequency"
                                                  ] || ""
                                                ).length > 4
                                                  ? " (" +
                                                    this.state.sip_order[
                                                      "sip_frequency"
                                                    ] +
                                                    ")"
                                                  : ""}
                                              </span>
                                              <input
                                                type="tel"
                                                pattern="0-9"
                                                name="sip_amount"
                                                class="form-control"
                                                placeholder=""
                                                maxLength="8"
                                                style={{ letterSpacing: 2 }}
                                                value={
                                                  this.state.sip_order[
                                                    "sip_amount"
                                                  ]
                                                }
                                                onKeyPress={(event) => {
                                                  if (
                                                    event.target.key === "Enter"
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                onChange={(event) => {
                                                  const targetValue =
                                                    event.target.value.replace(
                                                      /\s/g,
                                                      ""
                                                    );
                                                  if (targetValue.length === 0)
                                                    this.setState({
                                                      sip_order: {
                                                        ...this.state.sip_order,
                                                        sip_amount: targetValue,
                                                      },
                                                    });
                                                  else {
                                                    if (
                                                      targetValue.match(
                                                        /^[0-9]+$/
                                                      )
                                                    )
                                                      if (
                                                        parseFloat(
                                                          targetValue
                                                        ) <=
                                                        parseFloat(
                                                          this.state
                                                            .selected_sip_order[
                                                            "maximum_installment_amount"
                                                          ]
                                                        )
                                                      )
                                                        this.setState({
                                                          sip_order: {
                                                            ...this.state
                                                              .sip_order,
                                                            sip_amount:
                                                              targetValue,
                                                          },
                                                        });
                                                  }
                                                }}
                                                disabled={
                                                  (
                                                    this.state.sip_order[
                                                      "display_text"
                                                    ] || ""
                                                  ).length < 4
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div>
                                            <label></label>
                                          </div>

                                          <div className="row">
                                            <div class="col">
                                              {/* {(this.state.sip_order['sip_frequency'] || '').length < 4 &&
                                                      <div style={{ marginTop: 10 }} ></div>
                                                    } */}
                                              SIP Payment Date
                                              <input
                                                type="text"
                                                name="sip_amount"
                                                class="form-control"
                                                placeholder=""
                                                maxLength="2"
                                                readOnly={true}
                                                value={
                                                  this.state.sip_order[
                                                    "sip_date_value"
                                                  ]
                                                }
                                                onClick={(event) => {
                                                  this.setState({
                                                    show_sipdate_popup: true,
                                                  });
                                                }}
                                                disabled={
                                                  (
                                                    this.state.sip_order[
                                                      "sip_frequency"
                                                    ] || ""
                                                  ).length < 4
                                                }
                                              />
                                              {/* <DatePicker className="form-control"
                                                        selected={this.state.sip_order.sip_date}
                                                        onChangeRaw={(e) => e.preventDefault()}
                                                        // readOnly={true}
                                                        onChange={(date) => {
                                                          this.setState({
                                                            sip_order: { ...this.state.sip_order, sip_date: date }
                                                            // sip_minimum_date: date
                                                          })
                                                        }
                                                        }
                                                        minDate={this.state.sip_minimum_date}
                                                        maxDate={this.state.sip_maximum_date}
                                                        shouldCloseOnSelect={true}
                                                        excludeDates={this.state.sip_exclude_date_list}
                                                      /> */}
                                            </div>
                                          </div>
                                        </div>

                                        {this.state.sip_order[
                                          "purchase_cutoff_time"
                                        ].length > 4 && (
                                          <div class="form-group">
                                            <div class="d-flex justify-content-between mt-3">
                                              <h5 class="mb-0">
                                                Lockin Period Enabled ?:{" "}
                                                {this.state.sip_order[
                                                  "lockin_period_flag"
                                                ] === "Y"
                                                  ? "YES"
                                                  : "NO"}
                                              </h5>
                                              {this.state.sip_order[
                                                "lockin_period_flag"
                                              ] === "Y" && (
                                                <h5 class="mb-0">
                                                  Lockin Period :{" "}
                                                  {
                                                    this.state.sip_order[
                                                      "lockin_period"
                                                    ]
                                                  }
                                                </h5>
                                              )}
                                            </div>
                                            <div class="d-flex justify-content-between mt-3">
                                              <h5 class="mb-0">
                                                Cut-Off Time :{" "}
                                                {
                                                  this.state.sip_order[
                                                    "purchase_cutoff_time"
                                                  ]
                                                }
                                              </h5>
                                            </div>
                                          </div>
                                        )}
                                        {/* <div>
                                                  <label></label>
                                                </div>
                                                <div className="form-group">
                                                  {this.state.sip_order['sip_frequency'].length > 4 &&
                                                    <div className="form-group">
                                                      <div >
                                                        <p style={{ backgroundColor: '#dbcec5', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>SIP amount will be debited from your primary account on
                                                          <b>{' '}{this.fnDateOrdinalformat(this.state.sip_order['sip_date']).split(' ')[0]}</b>
                                                          {' of every '}
                                                          {this.state.sip_order['sip_frequency'].toLowerCase().replace('ly', '')}
                                                          {'.'}
                                                        </p>
                                                        <p style={{ backgroundColor: '#dbcec5', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>
                                                          {'Your first installment date would be '}
                                                          <b>{this.state.sip_order['sip_date'].toLocaleDateString('en-GB', {
                                                            day: 'numeric', month: 'short', year: 'numeric'
                                                          }).replace(/ /g, '-')}</b></p>
                                                      </div>
                                                      <hr />
                                                    </div>
                                                  }
                                                </div> */}

                                        {this.state.is_order_checkout_allowed &&
                                          !this.state.is_pending_order && (
                                            <div>
                                              <label></label>
                                            </div>
                                          )}

                                        {
                                          //this.state.is_order_checkout_allowed && !this.state.is_pending_order &&
                                          <div
                                            class="row"
                                            style={{ marginTop: 10 }}
                                          >
                                            <div class="col">
                                              <button
                                                type="submit"
                                                name="submit"
                                                class="btn btn-success btn-block"
                                                onClick={() => {
                                                  this.startSIP();
                                                }}
                                                disabled={
                                                  isNaN(
                                                    this.state.sip_order[
                                                      "sip_amount"
                                                    ] || ""
                                                  ) ||
                                                  !this.sipParameterValidation() ||
                                                  !this.state
                                                    .is_order_checkout_allowed ||
                                                  this.state.is_pending_order
                                                }
                                              >
                                                Start SIP
                                              </button>
                                            </div>
                                          </div>
                                        }
                                      </form>
                                    </div>
                                  </TabPanel>
                                  <TabPanel
                                    value={this.state.tab_investment_category}
                                    index={1}
                                  >
                                    <div class="buy-sell-widget">
                                      <form
                                        name="myform"
                                        class="currency_validate"
                                      >
                                        <div class="form-group">
                                          <Select
                                            placeholder={`Select Scheme Option`}
                                            value={this.state.general_order_frequency_list.find(
                                              (obj) =>
                                                obj.param_scheme_code ===
                                                  this.state.general_order[
                                                    "scheme_code"
                                                  ] || ""
                                            )}
                                            onChange={(e) =>
                                              this.handleChangeSelect(
                                                e,
                                                "general_order",
                                                "scheme_code",
                                                "order"
                                              )
                                            }
                                            options={
                                              this.state
                                                .general_order_frequency_list
                                            }
                                            isSearchable={false}
                                          />
                                        </div>
                                        <div>
                                          <label></label>
                                        </div>

                                        <div class="form-group">
                                          <div className="row">
                                            <div class="col">
                                              <div
                                                style={{ marginTop: 10 }}
                                              ></div>
                                              <label
                                                style={{ fontSize: 16 }}
                                                class="mr-sm-2"
                                              >
                                                {" "}
                                                Enter Amount{" "}
                                                <span
                                                  style={{
                                                    fontFamily: "sans-serif",
                                                  }}
                                                >
                                                  {" "}
                                                  &#8377;
                                                </span>
                                              </label>
                                            </div>
                                          </div>

                                          <div className="row">
                                            <div class="col">
                                              <input
                                                type="tel"
                                                pattern="0-9"
                                                className="form-control font-weight-bold"
                                                value={
                                                  this.state.general_order[
                                                    "order_amount"
                                                  ]
                                                }
                                                maxLength="8"
                                                style={{ letterSpacing: 2 }}
                                                onKeyPress={(event) => {
                                                  if (
                                                    event.target.key === "Enter"
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                onChange={(event) => {
                                                  const targetValue =
                                                    event.target.value; //.replace(/\s/g, '');
                                                  if (targetValue === "")
                                                    this.setState({
                                                      general_order: {
                                                        ...this.state
                                                          .general_order,
                                                        order_amount:
                                                          targetValue,
                                                      },
                                                    });
                                                  else {
                                                    if (
                                                      targetValue.match(
                                                        /^[0-9]+$/
                                                      ) &&
                                                      targetValue.toString()
                                                        .length < 9
                                                    ) {
                                                      if (
                                                        parseFloat(
                                                          targetValue
                                                        ) <=
                                                        parseFloat(
                                                          this.state
                                                            .selected_order[
                                                            "maximum_purchase_amount"
                                                          ]
                                                        )
                                                      )
                                                        this.setState({
                                                          general_order: {
                                                            ...this.state
                                                              .general_order,
                                                            order_amount:
                                                              targetValue,
                                                          },
                                                        });
                                                    }
                                                  }
                                                }}
                                                disabled={
                                                  this.state.general_order[
                                                    "display_text"
                                                  ].length < 4
                                                }
                                              />
                                            </div>
                                          </div>

                                          {/* <div className="row">
                                                    <div class="col">
                                                      <input type="text" className="form-control font-weight-bold"
                                                        value={this.state.general_order['order_amount']}
                                                        maxLength="8"
                                                        onKeyPress={event => {
                                                          if (event.target.key === 'Enter') {
                                                            event.preventDefault();
                                                          }
                                                        }}
                                                        onChange={event => {

                                                          const targetValue = event.target.value; //.replace(/\s/g, '');
                                                          if (targetValue === "")
                                                            this.setState({ general_order: { ...this.state.general_order, order_amount: targetValue } });
                                                          else {

                                                            if (targetValue.match(/^[0-9]+$/) && targetValue.toString().length < 9) {

                                                              if (parseFloat(targetValue) <= parseFloat(this.state.selected_order['maximum_purchase_amount']))
                                                                this.setState({ general_order: { ...this.state.general_order, order_amount: targetValue } });

                                                            }

                                                          }

                                                        }}

                                                        disabled={this.state.general_order['display_text'].length < 4}
                                                      />
                                                    </div>
                                                  </div> */}
                                        </div>

                                        <div>
                                          <label></label>
                                        </div>

                                        {this.state.general_order[
                                          "display_text"
                                        ].length > 4 && (
                                          <div class="form-group">
                                            <div className="row">
                                              {/* {this.state.general_order['display_text']} */}
                                              <div class="col">
                                                <p
                                                  style={{
                                                    width: 210,
                                                    backgroundColor: "#dbcec5",
                                                    padding: 4,
                                                    paddingLeft: 8,
                                                    paddingRight: 8,
                                                    margin: 4,
                                                    borderRadius: 4,
                                                  }}
                                                >
                                                  {
                                                    this.state.general_order[
                                                      "display_text"
                                                    ].split("|")[1]
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                            {/* <div className="row">
                                                      <div class="col">
                                                        <p
                                                          style={{ width: 210, backgroundColor: '#dbcec5', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }}>
                                                          {this.state.general_order['display_text'].split('|')[2]}
                                                        </p>
                                                      </div>
                                                    </div> */}
                                            <div className="row">
                                              <div class="col">
                                                <p
                                                  style={{
                                                    backgroundColor: "#dbcec5",
                                                    padding: 4,
                                                    paddingLeft: 8,
                                                    paddingRight: 8,
                                                    margin: 4,
                                                    borderRadius: 4,
                                                  }}
                                                >
                                                  {
                                                    this.state.general_order[
                                                      "display_text"
                                                    ].split("|")[3]
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                            <div className="row">
                                              <div class="col">
                                                <p
                                                  style={{
                                                    backgroundColor: "#dbcec5",
                                                    padding: 4,
                                                    paddingLeft: 8,
                                                    paddingRight: 8,
                                                    margin: 4,
                                                    borderRadius: 4,
                                                  }}
                                                >
                                                  <p class="mb-0">
                                                    Cut-Off Time :{" "}
                                                    {
                                                      this.state.general_order[
                                                        "purchase_cutoff_time"
                                                      ]
                                                    }
                                                  </p>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        {this.state.general_order[
                                          "display_text"
                                        ].length > 4 && (
                                          <div class="form-group">
                                            <div
                                              class="d-flex justify-content-between mt-3"
                                              style={{
                                                backgroundColor: "#dbcec5",
                                                padding: 4,
                                                paddingLeft: 8,
                                                paddingRight: 8,
                                                margin: 4,
                                                borderRadius: 4,
                                              }}
                                            >
                                              <p class="mb-0">
                                                Exit Load Enabled:{" "}
                                                {this.state.general_order[
                                                  "param_exit_load_flag"
                                                ] === "Y"
                                                  ? "YES"
                                                  : "NO"}
                                              </p>
                                              {this.state.general_order[
                                                "param_exit_load_flag"
                                              ] === "Y" && (
                                                <p class="mb-0">
                                                  Exit Load Value:{" "}
                                                  {
                                                    this.state.general_order[
                                                      "param_exit_load"
                                                    ]
                                                  }
                                                </p>
                                              )}
                                            </div>

                                            <div
                                              class="d-flex justify-content-between mt-3"
                                              style={{
                                                backgroundColor: "#dbcec5",
                                                padding: 4,
                                                paddingLeft: 8,
                                                paddingRight: 8,
                                                margin: 4,
                                                borderRadius: 4,
                                              }}
                                            >
                                              <p class="mb-0">
                                                Lockin Period Enabled:{" "}
                                                {this.state.general_order[
                                                  "lockin_period_flag"
                                                ] === "Y"
                                                  ? "YES"
                                                  : "NO"}
                                              </p>
                                              {this.state.general_order[
                                                "lockin_period_flag"
                                              ] === "Y" && (
                                                <p class="mb-0">
                                                  Lockin Period :{" "}
                                                  {
                                                    this.state.general_order[
                                                      "lockin_period"
                                                    ]
                                                  }
                                                </p>
                                              )}
                                            </div>

                                            <hr />
                                          </div>
                                        )}

                                        {/* {
                                              <button
                                                type="submit"
                                                name="submit"
                                                class="btn btn-success btn-block"
                                                onClick={() => { this.startOrder() }}
                                                disabled={ !this.orderParameterValidation()}
                                              >
                                                Invest Now{" "}
                                              </button>
                                            } */}

                                        {this.orderParameterValidation() && (
                                          <Fab
                                            style={{
                                              bottom: 22,
                                              right: -18,
                                              transform: "scale(0.8)",
                                            }}
                                            alwaysShowTitle={true}
                                            mainButtonStyles={{
                                              backgroundColor: "#2ea34d",
                                            }}
                                            icon={
                                              <i className="fa fa-arrow-right  fa-lg"></i>
                                            }
                                            onClick={() => {
                                              this.startOrder();
                                            }}
                                            disabled={
                                              !this.state
                                                .is_order_checkout_allowed
                                            }
                                          ></Fab>
                                        )}
                                      </form>
                                    </div>
                                  </TabPanel>
                                </Box>
                                <Fab
                                  style={{
                                    top: 100,
                                    right: -15,
                                    transform: "scale(0.8)",
                                  }}
                                  alwaysShowTitle={true}
                                  mainButtonStyles={{
                                    backgroundColor: "#d45950",
                                  }}
                                  icon={<i className="fa fa-times fa-lg"></i>}
                                  onClick={() =>
                                    this.setState({
                                      show_display_option: "portfolio",
                                    })
                                  }
                                ></Fab>
                              </div>
                            )}
                          </div>
                          {/* </div> */}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        )}

        <Modal
          show={this.state.modal_2fa_popup}
          onHide={() => {
            this.setState({ modal_2fa_popup: false });
          }}
          /*fullscreen={true}*/
          backdrop="static"
          // keyboard={false}
          size="lg"
          // aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title>Two Factor Authentication</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div class="col">
                Enter OTP received on your registered mobile
              </div>
            </div>
            <br />
            <div className="row">
              <div class="col">
                <OTPInput
                  value={this.state.two_factor_authentication["mobile_otp"]}
                  onChange={(event) => {
                    this.setState({
                      two_factor_authentication: {
                        ...this.state.two_factor_authentication,
                        mobile_otp: event,
                      },
                    });
                  }}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  secure
                  isInputNum={true}
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div class="col">Enter OTP received on your registered email</div>
            </div>
            <br />
            <div className="row">
              <div class="col">
                <OTPInput
                  value={this.state.two_factor_authentication["email_otp"]}
                  onChange={(event) => {
                    this.setState({
                      two_factor_authentication: {
                        ...this.state.two_factor_authentication,
                        email_otp: event,
                      },
                    });
                  }}
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  secure
                  isInputNum={true}
                />
              </div>
            </div>
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({ modal_2fa_popup: false });
              }}
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                this.validate2FactorAuthentication();
              }}
            >
              Validate
            </Button>
          </Modal.Footer>
        </Modal>

        {this.state.modal_options_popup && (
          <Modal
            show={this.state.modal_options_popup}
            onHide={() => {
              this.setState({ modal_options_popup: false });
            }}
            fullscreen={true}
            backdrop="static"
            size="lg"
            centered
          >
            <Modal.Header>
              <Modal.Title>
                {this.state.modal_options.header}
                {this.state.modal_options["id"] === "holdings"
                  ? ` (` + this.state.holdings_list.length + `)`
                  : ``}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.modal_options.id === "chart" && (
                <div id="chart-timeline">
                  <ReactApexChart
                    options={this.state.chartOptions}
                    series={this.state.chartSeries}
                    type="area"
                    height={350}
                  />
                </div>
              )}

              {this.state.modal_options.id === `returnscalculator` && (
                <form action="#">
                  <div className="row">
                    <div class="col-6">
                      <Form.Check
                        type="radio"
                        label={
                          <span>
                            <span
                              style={{
                                backgroundColor: "#f5f0e4",
                                fontSize: 16,
                                padding: 4,
                                paddingLeft: 8,
                                paddingRight: 8,
                                margin: 4,
                                borderRadius: 4,
                              }}
                            >
                              {`Monthly SIP`}
                            </span>
                          </span>
                        }
                        name="sip"
                        id="true"
                        value={true}
                        checked={this.state.is_sip_calculator === true}
                        onChange={(e) =>
                          this.handleRadioChange(true, "is_sip_calculator")
                        }
                      />
                    </div>
                    <div class="col-6">
                      <Form.Check
                        type="radio"
                        label={
                          <span>
                            <span
                              style={{
                                backgroundColor: "#f5f0e4",
                                fontSize: 16,
                                padding: 4,
                                paddingLeft: 8,
                                paddingRight: 8,
                                margin: 4,
                                borderRadius: 4,
                              }}
                            >
                              {`One-Time`}
                            </span>
                          </span>
                        }
                        name="onetime"
                        id="false"
                        value={false}
                        checked={this.state.is_sip_calculator === false}
                        onChange={(e) =>
                          this.handleRadioChange(false, "is_sip_calculator")
                        }
                      />
                    </div>
                  </div>
                  <hr />
                  {/* <table style={{ width: '100%' }}>
                    <tr>
                      <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                        
                      </td>
                      <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                        
                      </td>
                    </tr>
                  </table> */}

                  <div
                    className="row"
                    style={{ marginTop: 20, marginBottom: 20 }}
                  >
                    <span
                      style={{
                        fontFamily: "sans-serif",
                        marginTop: 14,
                        fontSize: 18,
                      }}
                    >
                      Enter Amount &#8377;
                    </span>
                  </div>
                  {/* <div style={{height: 7}}></div> */}
                  <div
                    className="row"
                    style={{ marginTop: 20, marginBottom: 20 }}
                  >
                    <div className="form-group">
                      {this.state.is_sip_calculator ? (
                        <input
                          className="form-control"
                          pattern="[0-9]*"
                          maxLength="7"
                          type="tel"
                          value={this.state.sipPrincipal}
                          style={{ marginTop: -10, letterSpacing: 4 }}
                          onChange={(event) => {
                            const targetValue = event.target.value;
                            if (
                              targetValue.length === 0 ||
                              targetValue.match(/^[0-9]+$/)
                            ) {
                              this.calculate(event, true);
                            }
                          }}
                        />
                      ) : (
                        <input
                          type="tel"
                          className="form-control"
                          value={this.state.onetimePrincipal}
                          maxLength="8"
                          pattern="[0-9]*"
                          style={{ marginTop: -10, letterSpacing: 4 }}
                          onKeyPress={(event) => {
                            if (event.target.key === "Enter") {
                              event.preventDefault();
                            }
                          }}
                          onChange={(event) => {
                            const targetValue = event.target.value;
                            if (
                              targetValue.length === 0 ||
                              targetValue.match(/^[0-9]+$/)
                            ) {
                              this.calculate(event, false);
                            }
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div style={{ height: 7 }}></div>

                  <table style={{ width: "100%" }}>
                    <tr>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          textAlign: "center",
                        }}
                      ></td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          backgroundColor: "#f7f5f5",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Returns{" "}
                          <span style={{ fontFamily: "sans-serif" }}>
                            {" "}
                            (&#8377;){" "}
                          </span>
                        </span>
                      </td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          backgroundColor: "#f7f5f5",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Absolute</span>
                      </td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          backgroundColor: "#f7f5f5",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Annualised</span>
                      </td>
                    </tr>
                    <div style={{ height: 5 }}></div>
                    <tr>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          backgroundColor: "#f7f5f5",
                          textAlign: "left",
                        }}
                      >
                        1Y
                      </td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          textAlign: "center",
                        }}
                      >
                        {return_data["1y"] && !isNaN(return_data["1y"]) ? (
                          <span
                            style={{
                              color:
                                parseFloat(return_data["1y_percent"]) < 0
                                  ? "red"
                                  : "#03b45e",
                            }}
                          >
                            <span style={{ fontFamily: "sans-serif" }}>
                              {" "}
                              &#8377;
                            </span>
                            {this.numberWithCommas(parseInt(return_data["1y"]))}
                          </span>
                        ) : (
                          <span>{"-"}</span>
                        )}
                      </td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          textAlign: "center",
                        }}
                      >
                        {return_data["1y"] && !isNaN(return_data["1y"]) ? (
                          <span
                            style={{
                              color:
                                parseFloat(return_data["1y_percent"]) < 0
                                  ? "red"
                                  : "#03b45e",
                            }}
                          >
                            {return_data["1y_percent"]}%
                          </span>
                        ) : (
                          <span>{"-"}</span>
                        )}
                      </td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          textAlign: "center",
                        }}
                      >
                        {return_data["1y"] && !isNaN(return_data["1y"]) ? (
                          <span
                            style={{
                              color:
                                parseFloat(
                                  return_data["1y_percent_annualized"]
                                ) < 0
                                  ? "red"
                                  : "#03b45e",
                            }}
                          >
                            {return_data["1y_percent_annualized"]}%
                          </span>
                        ) : (
                          <span>{"-"}</span>
                        )}
                      </td>
                    </tr>
                    <div style={{ height: 5 }}></div>
                    <tr>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          backgroundColor: "#f7f5f5",
                          textAlign: "center",
                        }}
                      >
                        3Y
                      </td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          textAlign: "center",
                        }}
                      >
                        {return_data["3y"] && !isNaN(return_data["3y"]) ? (
                          <span
                            style={{
                              color:
                                parseFloat(return_data["3y_percent"]) < 0
                                  ? "red"
                                  : "#03b45e",
                            }}
                          >
                            <span style={{ fontFamily: "sans-serif" }}>
                              {" "}
                              &#8377;
                            </span>
                            {this.numberWithCommas(parseInt(return_data["3y"]))}
                          </span>
                        ) : (
                          <span>{"-"}</span>
                        )}
                      </td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          textAlign: "center",
                        }}
                      >
                        {return_data["3y"] && !isNaN(return_data["3y"]) ? (
                          <span
                            style={{
                              color:
                                parseFloat(return_data["3y_percent"]) < 0
                                  ? "red"
                                  : "#03b45e",
                            }}
                          >
                            {return_data["3y_percent"]}%
                          </span>
                        ) : (
                          <span>{"-"}</span>
                        )}
                      </td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          textAlign: "center",
                        }}
                      >
                        {return_data["3y"] && !isNaN(return_data["3y"]) ? (
                          <span
                            style={{
                              color:
                                parseFloat(
                                  return_data["3y_percent_annualized"]
                                ) < 0
                                  ? "red"
                                  : "#03b45e",
                            }}
                          >
                            {return_data["3y_percent_annualized"]}%
                          </span>
                        ) : (
                          <span>{"-"}</span>
                        )}
                      </td>
                    </tr>
                    <div style={{ height: 5 }}></div>
                    <tr>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          backgroundColor: "#f7f5f5",
                          textAlign: "center",
                        }}
                      >
                        5Y
                      </td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          textAlign: "center",
                        }}
                      >
                        {return_data["5y"] && !isNaN(return_data["5y"]) ? (
                          <span
                            style={{
                              color:
                                parseFloat(return_data["5y_percent"]) < 0
                                  ? "red"
                                  : "#03b45e",
                            }}
                          >
                            <span style={{ fontFamily: "sans-serif" }}>
                              {" "}
                              &#8377;
                            </span>
                            {this.numberWithCommas(parseInt(return_data["5y"]))}
                          </span>
                        ) : (
                          <span>{"-"}</span>
                        )}
                      </td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          textAlign: "center",
                        }}
                      >
                        {return_data["5y"] && !isNaN(return_data["5y"]) ? (
                          <span
                            style={{
                              color:
                                parseFloat(return_data["5y_percent"]) < 0
                                  ? "red"
                                  : "#03b45e",
                            }}
                          >
                            {return_data["5y_percent"]}%
                          </span>
                        ) : (
                          <span>{"-"}</span>
                        )}
                      </td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          textAlign: "center",
                        }}
                      >
                        {return_data["5y"] && !isNaN(return_data["5y"]) ? (
                          <span
                            style={{
                              color:
                                parseFloat(
                                  return_data["5y_percent_annualized"]
                                ) < 0
                                  ? "red"
                                  : "#03b45e",
                            }}
                          >
                            {return_data["5y_percent_annualized"]}%
                          </span>
                        ) : (
                          <span>{"-"}</span>
                        )}
                      </td>
                    </tr>
                  </table>
                  <br />
                  <div>{`Note : "Y" denotes Year`}</div>
                </form>
              )}
              {this.state.modal_options.id === `returns` && (
                <form action="#">
                  {/* <Table > */}

                  <div className="row">
                    <div style={{ textAlign: "center" }}>
                      <h5>Lumpsum</h5>
                    </div>
                  </div>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          textAlign: "left",
                        }}
                      ></td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          backgroundColor: "#f7f5f5",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>1Y</span>
                      </td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          backgroundColor: "#f7f5f5",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>3Y</span>
                      </td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          backgroundColor: "#f7f5f5",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>5Y</span>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          backgroundColor: "#f7f5f5",
                          textAlign: "left",
                        }}
                      >
                        Absolute
                      </td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          textAlign: "center",
                        }}
                      >
                        {!isNaN(
                          this.state.lumpsum_returns_calculation["absolute"][
                            "1y_percent"
                          ]
                        ) && (
                          <span
                            style={{
                              fontSize: 13,
                              color:
                                this.state.lumpsum_returns_calculation[
                                  "absolute"
                                ]["1y_percent"].substring(0, 1) === "-" || ""
                                  ? "#f57362"
                                  : "#03b45e",
                            }}
                          >
                            {
                              this.state.lumpsum_returns_calculation[
                                "absolute"
                              ]["1y_percent"]
                            }
                            %
                          </span>
                        )}
                      </td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          textAlign: "center",
                        }}
                      >
                        {!isNaN(
                          this.state.lumpsum_returns_calculation["absolute"][
                            "3y_percent"
                          ]
                        ) && (
                          <span
                            style={{
                              fontSize: 13,
                              color:
                                this.state.lumpsum_returns_calculation[
                                  "absolute"
                                ]["3y_percent"].substring(0, 1) === "-" || ""
                                  ? "#f57362"
                                  : "#03b45e",
                            }}
                          >
                            {
                              this.state.lumpsum_returns_calculation[
                                "absolute"
                              ]["3y_percent"]
                            }
                            %
                          </span>
                        )}
                      </td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          textAlign: "center",
                        }}
                      >
                        {!isNaN(
                          this.state.lumpsum_returns_calculation["absolute"][
                            "5y_percent"
                          ]
                        ) && (
                          <span
                            style={{
                              fontSize: 13,
                              color:
                                this.state.lumpsum_returns_calculation[
                                  "absolute"
                                ]["5y_percent"].substring(0, 1) === "-" || ""
                                  ? "#f57362"
                                  : "#03b45e",
                            }}
                          >
                            {
                              this.state.lumpsum_returns_calculation[
                                "absolute"
                              ]["5y_percent"]
                            }
                            %
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          backgroundColor: "#f7f5f5",
                          textAlign: "left",
                        }}
                      >
                        Annualised
                      </td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          textAlign: "center",
                        }}
                      >
                        {!isNaN(
                          this.state.lumpsum_returns_calculation["annualized"][
                            "1y_percent"
                          ]
                        ) && (
                          <span
                            style={{
                              fontSize: 13,
                              color:
                                this.state.lumpsum_returns_calculation[
                                  "annualized"
                                ]["1y_percent"].substring(0, 1) === "-" || ""
                                  ? "#f57362"
                                  : "#03b45e",
                            }}
                          >
                            {
                              this.state.lumpsum_returns_calculation[
                                "annualized"
                              ]["1y_percent"]
                            }
                            %
                          </span>
                        )}
                      </td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          textAlign: "center",
                        }}
                      >
                        {!isNaN(
                          this.state.lumpsum_returns_calculation["annualized"][
                            "3y_percent"
                          ]
                        ) && (
                          <span
                            style={{
                              fontSize: 13,
                              color:
                                this.state.lumpsum_returns_calculation[
                                  "annualized"
                                ]["3y_percent"].substring(0, 1) === "-" || ""
                                  ? "#f57362"
                                  : "#03b45e",
                            }}
                          >
                            {
                              this.state.lumpsum_returns_calculation[
                                "annualized"
                              ]["3y_percent"]
                            }
                            %
                          </span>
                        )}
                      </td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          textAlign: "center",
                        }}
                      >
                        {!isNaN(
                          this.state.lumpsum_returns_calculation["annualized"][
                            "5y_percent"
                          ]
                        ) && (
                          <span
                            style={{
                              fontSize: 13,
                              color:
                                this.state.lumpsum_returns_calculation[
                                  "annualized"
                                ]["5y_percent"].substring(0, 1) === "-" || ""
                                  ? "#f57362"
                                  : "#03b45e",
                            }}
                          >
                            {
                              this.state.lumpsum_returns_calculation[
                                "annualized"
                              ]["5y_percent"]
                            }
                            %
                          </span>
                        )}
                      </td>
                    </tr>
                  </table>

                  <br />

                  <div className="row">
                    <div style={{ textAlign: "center" }}>
                      <h5>SIP</h5>
                    </div>
                  </div>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          textAlign: "left",
                        }}
                      ></td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          backgroundColor: "#f7f5f5",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>1Y</span>
                      </td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          backgroundColor: "#f7f5f5",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>3Y</span>
                      </td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          backgroundColor: "#f7f5f5",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>5Y</span>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          backgroundColor: "#f7f5f5",
                          textAlign: "left",
                        }}
                      >
                        Absolute
                      </td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          textAlign: "center",
                        }}
                      >
                        {!isNaN(
                          this.state.sip_returns_calculation["1y_percent"]
                        ) && (
                          <span
                            style={{
                              fontSize: 13,
                              color:
                                this.state.sip_returns_calculation[
                                  "1y_percent"
                                ].substring(0, 1) === "-" || ""
                                  ? "#f57362"
                                  : "#03b45e",
                            }}
                          >
                            {this.state.sip_returns_calculation["1y_percent"]}%
                          </span>
                        )}
                      </td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          textAlign: "center",
                        }}
                      >
                        {!isNaN(
                          this.state.sip_returns_calculation["3y_percent"]
                        ) && (
                          <span
                            style={{
                              fontSize: 13,
                              color:
                                this.state.sip_returns_calculation[
                                  "3y_percent"
                                ].substring(0, 1) === "-" || ""
                                  ? "#f57362"
                                  : "#03b45e",
                            }}
                          >
                            {this.state.sip_returns_calculation["3y_percent"]}%
                          </span>
                        )}
                      </td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          textAlign: "center",
                        }}
                      >
                        {!isNaN(
                          this.state.sip_returns_calculation["5y_percent"]
                        ) && (
                          <span
                            style={{
                              fontSize: 13,
                              color:
                                this.state.sip_returns_calculation[
                                  "5y_percent"
                                ].substring(0, 1) === "-" || ""
                                  ? "#f57362"
                                  : "#03b45e",
                            }}
                          >
                            {this.state.sip_returns_calculation["5y_percent"]}%
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          backgroundColor: "#f7f5f5",
                          textAlign: "left",
                        }}
                      >
                        Annualised
                      </td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          textAlign: "center",
                        }}
                      >
                        {!isNaN(
                          this.state.sip_returns_calculation[
                            "1y_percent_annualized"
                          ]
                        ) && (
                          <span
                            style={{
                              fontSize: 13,
                              color:
                                this.state.sip_returns_calculation[
                                  "1y_percent_annualized"
                                ].substring(0, 1) === "-" || ""
                                  ? "#f57362"
                                  : "#03b45e",
                            }}
                          >
                            {
                              this.state.sip_returns_calculation[
                                "1y_percent_annualized"
                              ]
                            }
                            %
                          </span>
                        )}
                      </td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          textAlign: "center",
                        }}
                      >
                        {!isNaN(
                          this.state.sip_returns_calculation[
                            "3y_percent_annualized"
                          ]
                        ) && (
                          <span
                            style={{
                              fontSize: 13,
                              color:
                                this.state.sip_returns_calculation[
                                  "3y_percent_annualized"
                                ].substring(0, 1) === "-" || ""
                                  ? "#f57362"
                                  : "#03b45e",
                            }}
                          >
                            {
                              this.state.sip_returns_calculation[
                                "3y_percent_annualized"
                              ]
                            }
                            %
                          </span>
                        )}
                      </td>
                      <td
                        style={{
                          height: 30,
                          border: "1px solid #e2e0e4",
                          textAlign: "center",
                        }}
                      >
                        {!isNaN(
                          this.state.sip_returns_calculation[
                            "5y_percent_annualized"
                          ]
                        ) && (
                          <span
                            style={{
                              fontSize: 13,
                              color:
                                this.state.sip_returns_calculation[
                                  "5y_percent_annualized"
                                ].substring(0, 1) === "-" || ""
                                  ? "#f57362"
                                  : "#03b45e",
                            }}
                          >
                            {
                              this.state.sip_returns_calculation[
                                "5y_percent_annualized"
                              ]
                            }
                            %
                          </span>
                        )}
                      </td>
                    </tr>
                  </table>

                  {/* </Table> */}
                </form>
              )}
              {this.state.modal_options.id === "fundmanager" && (
                <Table>
                  {this.state.fund_manager_list.map((item) => (
                    <tbody key={item["person_id"]}>
                      <tr>
                        <h5 class="card-title">{item["person_name"]}</h5>
                        <div>{item["education"]}</div>
                        <div>{item["experience"]}</div>
                      </tr>
                    </tbody>
                  ))}
                </Table>
              )}
              {this.state.modal_options.id === "holdings" && (
                <div className="row">
                  <table class="table table-striped responsive-table">
                    <thead>
                      <tr style={{ border: "1px solid #e2e0e4" }}>
                        <th
                          colSpan={10}
                          style={{ border: "1px solid #e2e0e4" }}
                        >
                          Company & Sector
                        </th>
                        <th style={{ border: "1px solid #e2e0e4" }}>Assets</th>
                      </tr>
                    </thead>
                    {this.state.holdings_list.map((item, index) => (
                      <tbody key={item["stock_search_id"]}>
                        <tr>
                          <td
                            colSpan={10}
                            style={{ border: "1px solid #e2e0e4" }}
                          >
                            {item["company_name"]} <br />
                            <span
                              style={{
                                backgroundColor: "#fce6eb",
                                padding: 2,
                                paddingLeft: 4,
                                paddingRight: 4,
                                borderRadius: 4,
                              }}
                            >
                              {item["sector_name"]}
                              <span style={{ marginLeft: 5 }}>
                                ({item["instrument_name"]})
                              </span>
                            </span>
                          </td>
                          <td style={{ border: "1px solid #e2e0e4" }}>
                            {" "}
                            {item["corpus_per"].toFixed(2)}%
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                </div>
              )}
              {this.state.modal_options.id === "sector" && (
                <div
                  className="row"
                 
                >
                     <ReactApexChart
                    options={this.state.options_bar}
                    series={this.state.seriespie_sector_bar}
                    type="bar"
                    height={
                      this.state.seriespie_sector_length *
                      this.state.series_bar_height
                    }
                    width="350"
                  />
               
                </div>
              )}
              {this.state.modal_options.id === "schemeinfo" && (
                <div className="row">
                  {this.state.scheme_information_html.length > 16 ? (
                    <div
                      dangerouslySetInnerHTML={this.createDefaultMarkup(
                        this.state.scheme_information_html
                      )}
                    />
                  ) : (
                    <Table>
                      <tbody>
                        <tr>
                          <td>Available Soon....</td>
                        </tr>
                      </tbody>
                    </Table>
                  )}
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ modal_options_popup: false });
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        {this.state.show_sipdate_popup && (
          <Modal
            show={this.state.show_sipdate_popup}
            onHide={() => this.setState({ show_sipdate_popup: false })}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>Choose a monthly SIP date</Modal.Header>
            <Modal.Body>
              <div>
                {[1, 6, 11, 16, 21, 26].map((item, index) => (
                  <div className="row" key={index}>
                    <br />
                    {[0, 1, 2, 3, 4].map((increment, _index) => (
                      <div class="col-2" key={_index}>
                        {this.state.popup_sip_date_list.indexOf(
                          (item + increment).toString()
                        ) > -1 ? (
                          <button
                            type="button"
                            className="btn-sm btn-success"
                            style={{
                              color: "white",
                              width: "30px",
                              marginTop: "3px",
                              marginLeft: "3px",
                            }}
                            onClick={() => {
                              this.setState({
                                show_sipdate_popup: false,
                                sip_order: {
                                  ...this.state.sip_order,
                                  sip_date: item + increment,
                                  sip_date_value:
                                    moment(
                                      "1982-11-" +
                                        (item + increment < 10 ? `0` : ``) +
                                        (item + increment)
                                    ).format("Do ") +
                                    " of every " +
                                    this.state.sip_order["sip_frequency"]
                                      .toLowerCase()
                                      .replace("ly", ""),
                                },
                              });
                            }}
                            disabled={
                              this.state.popup_sip_date_list.indexOf(
                                (item + increment).toString()
                              ) === -1
                            }
                          >
                            {item + increment}
                          </button>
                        ) : (
                          <div>
                            {item + increment <= 28 && (
                              <p
                                style={{
                                  width: "30px",
                                  textAlign: "center",
                                  backgroundColor: "#dbcec5",
                                  padding: 4,
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                  margin: 4,
                                  borderRadius: 4,
                                }}
                              >
                                {item + increment}
                              </p>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </Modal.Body>
          </Modal>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  chart_result: state.charts.chart_result,
  order_result: state.order.order_result,
  loading: state.charts.loading,
});

export default connect(mapStateToProps, {
  listChartSchemes,
  twoFactorAuthentication,
  twoFactorNomineeValidation,
})(Charts);
