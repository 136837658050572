import React from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { Button, Spinner, Row, Col, Modal, Container } from 'react-bootstrap';
import PropTypes from "prop-types";
import {
  verifyPan,
  verifyStage,
  loadStageInfo,
} from "../../../jsx/actions/verify";
import {
  twoFactorAuthentication
} from "../../actions/orders";
import { Redirect } from "react-router-dom";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import Select from 'react-select';
import { Fab, Action } from 'react-tiny-fab';
import OTPInput, { ResendOTP } from "otp-input-react";

class PanVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: false,
      is_edit: false,
      is_stage_verified: false,
      is_verify_enabled: false,
      is_confirm_chk_enabled: false,
      is_confirm_chk_checked: false,
      is_verified_editable: false,
      selectedFile: null,
      src_selectedFile: null,
      pan_information: [],
      pan_attachment_list: [],

      show_verify_button: true,
      show_popup: false,
      pop_messagetext: "",
      show_modal_confirm_dialog: false,
      modal_confirm_dialog_messagetext: 'You are above to submit the pan id proof and once submitted you cannot undone. Are you sure to submit the pan id proof ?',

      show_duplicate_pan_popup: false,
      popup_duplicate_id_messageText: ``,
      is_upload_id_duplicate: false,
      duplicate_id_validation_messageText: ``,
      is_duplicate_pan_otp_enabled: false,
      is_otp_enabled: false,
      two_factor_authentication: {
        mobile_otp: '',
        email_otp: '',
        twofactor_token_id: ''
      },
      original_user_info: {},

      valid_pan_invalid_dob: false,
      popup_dob_messageText: '',
      show_dob_popup: false,
      pan_dob_month: '',
      pan_dob_date: '',
      pan_dob_year: new Date().getFullYear() - 18,
      pan_dob_date_list: [],
      pan_dob_month_list: [
        { label: 'January', value: '01' }, { label: 'February', value: '02' },
        { label: 'March', value: '03' }, { label: 'April', value: '04' },
        { label: 'May', value: '05' }, { label: 'June', value: '06' },
        { label: 'July', value: '07' }, { label: 'August', value: '08' },
        { label: 'September', value: '09' }, { label: 'October', value: '10' },
        { label: 'November', value: '11' }, { label: 'December', value: '12' },
      ],
      pan_dob_year_list: [],
      pan_dob: '',
      is_pan_dob_valid: true
    };
  }

  componentDidMount() {
    this.onLoadStageInfo();
  }

  closePopup = () => {
    this.setState({
      show_popup: false,
      pop_messagetext: "",
    });
  };

  changeHandler = (event) => {

    if (
      event.target.files[0] &&
      (event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpeg" ||
        event.target.files[0].type === "image/jpg")
    ) {
      this.setState({
        selectedFile: event.target.files[0],
        src_selectedFile: URL.createObjectURL(event.target.files[0]),
      });
    } else {

      if (event.target.files.length > 0)
        this.setState({
          show_popup: true,
          pop_messagetext: "Upload file format should be of PNG, JPEG, JPG only",
        });

    }
  };

  handleConfirmCheck = (event) => {

    let _format_pan_dob = this.state.pan_dob.split('/');

    let _dob_from_pan = this.state.pan_information.filter(el => {
      return el['attribute'] === 'dob'
    });

    if (_dob_from_pan.length > 0) {
      if (_format_pan_dob.length <= 1)
        _format_pan_dob = _dob_from_pan[0]['text'].split('/');
    }
    // _dob_from_pan = _dob_from_pan[0]['text'].split('/');

    let _is_valid = this.isValidDate(_format_pan_dob[2], _format_pan_dob[1], _format_pan_dob[0]);

    window.scrollTo(0, event.target.checked ? 0 : 1000);
    let _setState = {};

    if (/*this.state.valid_pan_invalid_dob && */event.target.checked) {

      if (_is_valid) {
        _setState = Object.assign({
          show_dob_popup: true,
          popup_dob_messageText: 'Confirm your date of birth as per the pan card'
        }, {
          pan_dob_date: _format_pan_dob[0],
          pan_dob_month: _format_pan_dob[1],
          pan_dob_year: _format_pan_dob[2],
          pan_dob: _format_pan_dob.join('/')
        });
      }
      else
        _setState = {
          show_dob_popup: true,
          popup_dob_messageText: 'Confirm your date of birth as per the pan card'
        };
    }


    {
      this.setState(Object.assign({
        is_confirm_chk_checked: event.target.checked, // !this.state.is_confirm_chk_checked,
      }, _setState));
    }

  };

  isEdit = () => {
    this.setState({
      is_edit: true,
      is_verify_enabled: true,
      // is_confirm_chk_enabled: false
    });
  };

  isCancelEdit = () => {
    this.setState({
      is_edit: false,
      is_verify_enabled: false,
    });
  };

  onLoadStageInfo = () => {

    this.setState({ isloading: true });

    this.props
      .loadStageInfo({ stage_name: "pan" })
      .then((response) => {

        let pan_result = this.props.pan_result;

        let pan_dob_date_list = [];
        for (var index = 1; index <= 31; index++) {
          pan_dob_date_list.push({
            label: (index < 10 ? '0' : '') + index,
            value: (index < 10 ? '0' : '') + index
          })
        }

        let dob_minimum_year = new Date().getFullYear() - 18;
        let pan_dob_year_list = [];
        for (var index = dob_minimum_year; index >= dob_minimum_year - 72; index--) {
          pan_dob_year_list.push({
            label: index,
            value: index.toString()
          })
        }

        if (pan_result["code"] !== "000") {
          this.setState({ isloading: false });
          alert(pan_result["messageText"]);
        }
        else {

          let _pan_information = pan_result['id_information'];
          let _pan_dob_year = '';
          _pan_information.forEach(el => {
            if (el['attribute'] === 'dob') {
              if (el['text'].trim().length >= 4)
                _pan_dob_year = el['text'].substring(el['text'].length - 4);
            }
          });

          if (pan_result["state_parameters"]) {
            this.setState(Object.assign(
              pan_result["state_parameters"],
              {
                isloading: false,
                pan_dob_date_list: pan_dob_date_list,
                pan_dob_year_list: pan_dob_year_list,
                valid_pan_invalid_dob: !pan_result['id_information']['is_dob_valid'],
                pan_dob_year: _pan_dob_year
              }
            )
            );
          }
          else
            this.setState({
              is_stage_verified: pan_result["is_stage_verified"],
              is_verify_enabled: pan_result["is_verify_enabled"],
              is_confirm_chk_enabled:
                pan_result["is_confirm_chk_enabled"],
              is_verified_editable:
                pan_result["is_verified_editable"],
              pan_information: pan_result["id_information"],
              pan_attachment_list: pan_result["attachment_list"],
              isloading: false,
              pan_dob_date_list: pan_dob_date_list,
              pan_dob_year_list: pan_dob_year_list,
              valid_pan_invalid_dob: !pan_result['id_information']['is_dob_valid'],
              pan_dob_year: _pan_dob_year,
            });
        }

      })
      .catch((err) => {
        this.setState({ isloading: false });
        alert(err);
      });
  };

  sendOTP = () => {

    this.setState({ isloading: true });

    this.props
      .twoFactorAuthentication({
        client_info: {
          two_factor_authentication: this.state.two_factor_authentication,
          request_action: 'upload pan validation',
          is_otp_email_required: true,
          original_user_info: this.state.original_user_info
        },
      })
      .then((response) => {

        if (this.props.order_result["code"] !== "000") {
          this.setState({ isloading: false });
          alert(this.props.order_result["messageText"]);
        }
        else {

          let _result = this.props.order_result; //["result"];

          if (_result && _result['twofactor_id']) {

            this.setState({
              isloading: false,
              is_otp_enabled: true,
              is_duplicate_pan_otp_enabled: true,
              two_factor_authentication: {
                mobile_otp: '',
                email_otp: '',
                twofactor_token_id: _result['twofactor_id']
              }
            });

          }

        }
      })
      .catch((err) => {
        alert(err);
      });

  }

  onVerify = () => {

    this.setState({ isloading: true })
    let formData = new FormData();

    formData.append("upload_pan", this.state.selectedFile);
    formData.append("id_type", "pan");
    formData.append("folder_name", "pan");
    formData.append("attachment_category", "pan");

    this.props
      .verifyPan(formData)
      .then((response) => {

        let pan_result = this.props.pan_result;

        if (pan_result['code'] === '000') {

          if (pan_result["state_parameters"]) {

            if (pan_result['state_parameters']['valid_pan_invalid_dob']) {
              let _pan_information = pan_result['id_information'];
              _pan_information.forEach(el => {
                if (el['attribute'] === 'dob') {
                  if (el['text'].trim().length >= 4)
                    pan_result["state_parameters"]['pan_dob_year'] = el['text'].substring(el['text'].length - 4);
                }
              });
              //

              this.setState(
                Object.assign(pan_result["state_parameters"],
                  {
                    isloading: false,
                    show_verify_button: false,
                    show_dob_popup: true,
                    popup_dob_messageText: pan_result["messageText"]
                  })
              );

            }
            else
              this.setState(
                Object.assign(pan_result["state_parameters"],
                  { isloading: false, show_verify_button: false })
              );

          }
          else
            this.setState({
              isloading: false,
              show_verify_button: false,
              is_stage_verified: pan_result["is_stage_verified"],
              is_verify_enabled: pan_result["is_verify_enabled"],
              is_confirm_chk_enabled:
                pan_result["is_confirm_chk_enabled"],
              is_verified_editable:
                pan_result["is_verified_editable"],
              pan_information: pan_result["id_information"],
              pan_attachment_list: pan_result["attachment_list"],
            });
        }
        // else if (pan_result["code"] === "131") {
        //   this.setState({
        //     isloading: false,
        //     show_duplicate_pan_popup: true,
        //     popup_duplicate_id_messageText: pan_result["messageText"],
        //   });
        // }
        else {

          this.setState({
            isloading: false,
            show_popup: true,
            pop_messagetext: pan_result["messageText"],
          });
        }

      })
      .catch((err) => {
        alert(err);
      });

  };

  onVerifyStage = () => {

    this.setState({
      show_modal_confirm_dialog: false, isloading: true
    });

    let _body_parameters = {
      verify_stage: "pan",
      valid_pan_invalid_dob: this.state.valid_pan_invalid_dob,
      pan_dob: this.state.pan_dob,
      two_factor_authentication: {}
    }

    if(this.state.is_upload_id_duplicate)
      _body_parameters['two_factor_authentication'] = Object.assign(this.state.two_factor_authentication,
        { original_user_info: this.state.original_user_info });

    this.props.verifyStage(_body_parameters, "pan", (response) => {
      
      this.setState({ isloading: false });

      let pan_result = this.props.pan_result;

      if (pan_result['code'] === '000')
        this.props.history.push({
          pathname: "/" + this.props.pan_result["navigateScreen"] + "/",
        });
      else
      {
        if(this.state.is_upload_id_duplicate)
          this.setState({
            duplicate_id_validation_messageText: pan_result["messageText"]
          });
        else 
          this.setState({
            isloading: false,
            show_popup: true,
            pop_messagetext: pan_result["messageText"],
          });   
      }

    });

  };

  handleChangeSelect = (selectedOption, attribute) => {

    let _pan_dob = '';
    if (attribute === 'pan_dob_date')
      _pan_dob = selectedOption.value + '/' + this.state.pan_dob_month + '/' + this.state.pan_dob_year;
    else if (attribute === 'pan_dob_month')
      _pan_dob = this.state.pan_dob_date + '/' + selectedOption.value + '/' + this.state.pan_dob_year;
    else if (attribute === 'pan_dob_year')
      _pan_dob = this.state.pan_dob_date + '/' + this.state.pan_dob_month + '/' + selectedOption.value;

    let _pan_information = this.props.pan_result['id_information'] || [];
    _pan_information.forEach(el => {
      if (el['attribute'] === 'dob')
        el['text'] = _pan_dob;
    });

    let _format_pan_dob = _pan_dob.split('/');

    if (this.isValidDate(_format_pan_dob[2], _format_pan_dob[1], _format_pan_dob[0])) {
      this.setState({
        [attribute]: selectedOption.value,
        pan_dob: _pan_dob,
        pan_information: _pan_information,
        // is_pan_dob_valid: true
      });
    }
    else {
      this.setState({
        [attribute]: selectedOption.value,
        pan_dob: _pan_dob,
        // is_pan_dob_valid: false
      });
    }

  }

  isValidDate = (year, month, day) => {
    var d = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    return month == d.getMonth() + 1;
  }

  handle2FactorChange = (input, attribute) => e => {

    if (e)
      this.setState({
        [attribute]: { ...this.state[attribute], [input]: e }
      });
    else
      this.setState({ [input]: e });

  }

  createDefaultMarkup(html_text) {
    return {
      __html: html_text,
    };
  }

  render() {

    const renderButton = buttonProps => {
      return (
        <div class="row">
          <div class="col">
            <button onClick={this.sendOTP} {...buttonProps}
              class={buttonProps.remainingTime !== 0 ? `btn btn-outline-secondary` : `btn btn-outline-success`}>
              {`Resend otp`}
            </button>
          </div>
          {buttonProps.remainingTime !== 0 &&
            <div class="col" ><span>You can resend otp in
              <span style={{ fontWeight: 'bold', color: 'green' }}>{' '}{buttonProps.remainingTime}</span> secs.
            </span>
            </div>
          }
        </div>
      );
    };
    const renderTime = () => React.Fragment;

    return (
      <>
        <Header1 />
        <Sidebar />

        <div className="authincation section-padding">
          <div className="content-body" style={{ marginTop: 40 }}>
            {this.state.isloading &&
              <Spinner animation="border" variant="dark"
                style={{ position: "fixed", top: "50%", left: "50%" }} />
            }
            <div className="container">
              <div class="row">

                <div class="col-xl-12">

                  <h4 className="text-center">
                    {this.state.is_stage_verified ? `Your PAN Information` : `Verify PAN`}
                  </h4>
                  {!this.state.is_stage_verified &&
                    <span>
                      <span style={{ color: 'green', fontSize: 14, fontWeight: 'bold' }}>
                        Note : </span> Format should be of png, jpeg, jpg only.
                    </span>
                  }
                  <br />
                  <Form>

                    <Form.Group controlId="formFile" >
                      {(this.state.is_verify_enabled ||
                        this.state.is_verified_editable) &&
                        this.state.show_verify_button && (
                          <div>
                            <br />
                            <Form.Control
                              type="file"
                              onClick={(event) => { const { target = {} } = event || {}; target.value = ""; }}
                              onChange={this.changeHandler}
                            />

                          </div>
                        )}
                    </Form.Group>

                  </Form>

                  {(this.state["src_selectedFile"] || this.state.pan_information.length > 0) &&
                    <div>

                      {this.state["src_selectedFile"] &&
                        <div className="card">

                          <form action="#">

                            {
                              //this.state.is_verified_editable &&
                              <div>

                                {(this.state.is_verify_enabled ||
                                  this.state.is_verified_editable) &&
                                  this.state.show_verify_button &&
                                  <Form>
                                    <Form.Group controlId="formFile" >
                                      <div className="text-center">
                                        <img
                                          src={this.state["src_selectedFile"]}
                                          style={{ width: 330, height: 250 }}
                                        />
                                      </div>
                                      <div style={{ height: 3 }} />
                                    </Form.Group>
                                  </Form>
                                }

                                <div className="text-center">
                                  {(this.state.is_verify_enabled ||
                                    this.state.is_verified_editable) &&
                                    this.state["src_selectedFile"] && (
                                      this.state.show_verify_button ?
                                        <Button
                                          className="btn btn-success btn-block"
                                          variant="success"
                                          onClick={this.onVerify}
                                          disabled={this.state.isloading}
                                        >
                                          Verify
                                        </Button>
                                        :
                                        <Button
                                          className="btn btn-success btn-block"
                                          variant="success"
                                          onClick={() => {
                                            this.setState({ show_verify_button: true })
                                          }}
                                          disabled={this.state.isloading}
                                        >
                                          Re-upload
                                        </Button>
                                    )}
                                </div>
                              </div>
                            }

                          </form>

                        </div>
                      }

                      {this.state.is_stage_verified && this.state.pan_information.length > 0 &&
                        <h4 style={{ textAlign: 'center', }}>
                          Submitted Pan Information
                        </h4>
                      }

                      {this.state.pan_information.length > 0 &&

                        <div className="card">

                          <table style={{ width: '100%' }}>
                            {this.state.pan_information.map((el, i) =>

                              el["text"].length < 400 &&
                              <tr key={i} >
                                <td style={{ width: 120, height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                  <span>
                                    {el['attribute']
                                      .replace(/_/gm, " ")
                                      .toUpperCase()
                                    }
                                  </span>
                                </td>
                                <td style={{ wordWrap: 'break-word', border: '1px solid #e2e0e4', textAlign: 'center', marginRight: 5 }}>
                                  {el["text"] === true ? `YES` :
                                    (el["text"] === false ? 'NO' : el["text"])}
                                </td>
                              </tr>
                            )}
                          </table>
                          <div style={{ height: 10 }} />
                          <table style={{ width: '100%' }}>
                            {(this.state.pan_attachment_list || []).map((el, i) =>
                              <tr>
                                <td >
                                  <img key={i} src={el} style={{ width: 330, height: 200 }} />
                                </td>
                              </tr>
                            )
                            }
                          </table>



                          <div className="card-body" style={{ marginTop: -30 }}>

                            {this.state.isloading &&
                              <Spinner animation="border" variant="dark"
                                style={{ position: "fixed", top: "50%", left: "50%" }} />
                            }

                            <form action="#">

                              {!this.state.is_stage_verified && (
                                <div>
                                  <hr />
                                  {this.state.is_confirm_chk_enabled && (
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicCheckbox"
                                    >
                                      <Form.Check
                                        type="checkbox"
                                        label="I agree & confirm to save my pan details"
                                        value={this.state.is_confirm_chk_checked}
                                        onChange={this.handleConfirmCheck}
                                        checked={this.state.is_confirm_chk_checked == true}
                                      // defaultChecked={
                                      //   this.state.is_confirm_chk_checked
                                      // }
                                      />
                                    </Form.Group>
                                  )}
                                  {this.state.is_confirm_chk_checked && (
                                    <div className="text-center">
                                      <Button
                                        className="btn btn-success btn-block"
                                        variant="success"
                                        onClick={() => {
                                          this.setState({ show_modal_confirm_dialog: true })
                                        }}
                                        disabled={this.state.isloading}
                                      >
                                        Confirm
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              )}

                            </form>

                          </div>

                        </div>
                      }


                      <table style={{ width: '100%' }}>
                        <tr>
                          <td style={{ height: 30, textAlign: 'center' }}>
                            {this.state.pan_information.length > 0 && this.state.is_stage_verified &&
                              <Link to={'/aadhaarVerification'} type="submit"
                                className="btn btn-success pl-5 pr-5">Next</Link>
                            }
                          </td>
                        </tr>
                      </table>
                    </div>
                  }

                  {this.state.show_popup && (
                    <Modal show={this.state.show_popup} onHide={() => this.closePopup()}>
                      <Modal.Header>

                      </Modal.Header>
                      <Modal.Body>

                        <p>{this.state["pop_messagetext"]}</p>

                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="primary" onClick={() => this.closePopup()}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  )}

                  {this.state.show_modal_confirm_dialog && (
                    <Modal show={this.state.show_modal_confirm_dialog}
                      // onHide={() => this.setState({
                      //   show_modal_confirm_dialog: false
                      // })}
                      centered
                      keyboard={false}
                      // fullscreen={true}
                      backdrop="static"
                    >
                      <Modal.Header>

                      </Modal.Header>
                      <Modal.Body>

                        <p>{this.state["modal_confirm_dialog_messagetext"]}</p>

                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="primary"
                          onClick={() => {

                            if (this.state.is_upload_id_duplicate) {
                              this.setState({
                                show_duplicate_pan_popup: true,
                                popup_duplicate_id_messageText: this.state.popup_duplicate_id_messageText,
                              });
                            }
                            else
                              this.onVerifyStage()
                          }
                          }>
                          Yes
                        </Button>
                        <Button variant="primary"
                          onClick={() => this.setState({
                            show_modal_confirm_dialog: false
                          })}>
                          No
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  )}

                  {this.state.show_dob_popup && (
                    <Modal show={this.state.show_dob_popup}
                      centered
                    >
                      <Modal.Header>
                        <p>{this.state.popup_dob_messageText}</p>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-5">
                            Month
                          </div>
                          <div className="col">
                            <Select placeholder={`select`}
                              value={this.state.pan_dob_month_list.find(object => object.value === this.state.pan_dob_month || '')}
                              onChange={(e) => { this.handleChangeSelect(e, 'pan_dob_month') }}
                              options={this.state.pan_dob_month_list}
                              isSearchable={false} //
                            />
                          </div>
                        </div>
                        <p></p>
                        <div className="row">
                          <div className="col-5">
                            Date
                          </div>
                          <div className="col">
                            <Select placeholder={`select`}
                              value={this.state.pan_dob_date_list.find(object => object.value === this.state.pan_dob_date || '')}
                              onChange={(e) => { this.handleChangeSelect(e, 'pan_dob_date') }}
                              options={this.state.pan_dob_date_list}
                              isSearchable={false}
                            />
                          </div>
                        </div>
                        <p></p>
                        <div className="row">
                          <div className="col-5">
                            Year
                          </div>
                          <div className="col">
                            <Select placeholder={`select`}
                              value={this.state.pan_dob_year_list.find(object => object.value === this.state.pan_dob_year || '')}
                              onChange={(e) => { this.handleChangeSelect(e, 'pan_dob_year') }}
                              options={this.state.pan_dob_year_list}
                              isSearchable={false}
                            />
                          </div>
                        </div>
                        <hr />
                        {!this.state.is_pan_dob_valid &&
                          <div className="row">
                            <span style={{ color: 'red' }}>
                              Entered Date Of Birth is not valid
                            </span>
                          </div>
                        }
                        {this.state.is_pan_dob_valid && this.state.pan_dob.length === 10 &&
                          <div className="row">
                            <div class="col">
                              Your Date Of Birth is
                              {/* </div>
                            <div class="col"> */}
                              {` `}
                              <span style={{ fontWeight: 'bold', color: 'green' }}>
                                {this.state.pan_dob}
                              </span>
                            </div>
                          </div>
                        }
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="primary"
                          onClick={() => {
                            let _format_pan_dob = this.state.pan_dob.split('/');
                            let _is_valid = this.isValidDate(_format_pan_dob[2], _format_pan_dob[1], _format_pan_dob[0]);

                            {
                              this.setState({
                                is_pan_dob_valid: _is_valid,
                                show_dob_popup: !_is_valid
                              });
                            }

                          }}
                          disabled={this.state.pan_dob.length !== 10}>
                          Submit
                        </Button>
                        {`      `}
                        <Button
                          variant="primary"
                          onClick={() => {
                            this.setState({
                              is_confirm_chk_checked: false,
                              show_dob_popup: false
                            });
                          }
                          }
                        >
                          Cancel
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  )}

                </div>
              </div>
            </div>

            

            <Modal
                    show={
                      this.state
                        .show_duplicate_pan_popup
                    }
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header>
                      <div
                        dangerouslySetInnerHTML={this.createDefaultMarkup(
                          this.state.popup_duplicate_id_messageText
                        )}
                      />
                    </Modal.Header>
                    <Modal.Body>
                    {this.state.isloading &&
                      <Spinner animation="border" variant="dark"
                        style={{ position: "fixed", top: "50%", left: "50%" }} />
                    }
                      <div>
                        {!this.state.is_duplicate_pan_otp_enabled ?
                          <Container>

                            <div style={{ height: 4 }} />
                            <Button
                              className="btn btn-success btn-block"
                              variant="success"
                              onClick={this.sendOTP}
                              disabled={this.state.isloading}
                            >
                              Send OTP
                            </Button>
                            <div style={{ height: 20 }} />

                            <Button
                              className="btn btn-secondary btn-block"
                              onClick={() =>
                                this.setState({
                                  show_duplicate_pan_popup: false,
                                  is_duplicate_pan_otp_enabled: false,
                                  is_otp_enabled: true,
                                  duplicate_id_validation_messageText: ``,
                                  two_factor_authentication: {
                                    mobile_otp: '',
                                    email_otp: '',
                                    twofactor_token_id: ``
                                  }
                                })
                              }
                              disabled={this.state.isloading}
                            >
                              Close
                            </Button>

                          </Container>
                          :
                          <Container>

                            <div>

                              <div class="row">
                                <div class="col">
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <label>Enter OTP received on your linked mobile</label>
                                    <OTPInput
                                      value={this.state.two_factor_authentication['mobile_otp']}
                                      onChange={this.handle2FactorChange('mobile_otp', 'two_factor_authentication')}
                                      autoFocus
                                      OTPLength={6}
                                      otpType="number"
                                      secure
                                      isInputNum={true}
                                    />
                                  </Form.Group>
                                </div>
                              </div>
                              <br />
                              <Form.Group
                                className="mb-3"
                              >
                                <label>Enter OTP received on your linked email</label>
                                <OTPInput
                                  value={this.state.two_factor_authentication['email_otp']}
                                  onChange={this.handle2FactorChange('email_otp', 'two_factor_authentication')}
                                  OTPLength={6}
                                  otpType="number"
                                  secure
                                  isInputNum={true}
                                />
                              </Form.Group>
                              <div class="row">
                                <ResendOTP maxTime={120} renderButton={renderButton} style={{ color: 'black', marginTop: 20 }} renderTime={renderTime}
                                  onResendClick={(e) => { this.sendOTP() }}
                                />
                              </div>
                              <div style={{height:7}} />
                              <div>
                                {this.state.duplicate_id_validation_messageText}
                              </div>
                              <div style={{height:7}} />
                              <div class="row">
                                <div class="col"
                                >
                                  <button
                                    className="btn btn-success btn-block"
                                    onClick={this.onVerifyStage}
                                    disabled={this.state.isloading ||
                                      (this.state.two_factor_authentication['mobile_otp'] || '').length < 6 ||
                                      (this.state.two_factor_authentication['email_otp'] || '').length < 6}
                                  >
                                    Confirm
                                  </button>
                                </div>
                                <div class="col">
                                  <Button
                                    className="btn btn-secondary btn-block"
                                    onClick={() =>
                                      this.setState({
                                        show_duplicate_pan_popup: false,
                                        is_duplicate_pan_otp_enabled: false,
                                        is_otp_enabled: true,
                                        duplicate_id_validation_messageText: ``,
                                        two_factor_authentication: {
                                          mobile_otp: '',
                                          email_otp: '',
                                          twofactor_token_id: ``
                                        }
                                      })
                                    }
                                    disabled={this.state.isloading}
                                  >
                                    Close
                                  </Button>
                                </div>
                              </div>

                            </div>

                          </Container>
                        }
                      </div>
                    </Modal.Body>
                  </Modal>

          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  pan_result: state.verify.pan_result,
  order_result: state.order.order_result,
});

export default connect(mapStateToProps, {
  verifyPan,
  verifyStage,
  loadStageInfo,
  twoFactorAuthentication
})(PanVerification);
