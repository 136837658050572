import React, { } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { connect } from "react-redux";
import { Button, Spinner, Alert, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { verifyStage, loadStageInfo } from '../../../jsx/actions/verify';
import { Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { Redirect } from 'react-router-dom';
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";

class EsignkycVerification extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            config_digio: {
                bullbox_logo: '',
                environment: 'sandbox'
            },
            is_stage_verified: false,
            is_verify_enabled: false,
            is_confirm_chk_enabled: false,
            is_kyc_submission_chk_enabled: false,
            is_verified_editable: false,
            esignkyc_information: {},
            esignkyc_attachment_list: {},
            is_esign_verified: false,
            is_esign_button_disabled: false,
            isloading: false,
            is_kyc_verified: false,
            is_client_code_approved: false,
            digio_html: `<html></html>`,
            show_popup: false,
            pop_messagetext: "",
        };

    }

    componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://app.digio.in/sdk/v9/digio.js";
        script.async = true;
        document.head.appendChild(script);
        
        this.onLoadStageInfo();
    }

    handleConfirmCheck = (event) => {
        this.setState({ is_confirm_chk_checked: !this.state.is_confirm_chk_checked })
    }

    onLoadStageInfo = () => {

        this.setState({ isloading: true });
        this.props.loadStageInfo({ stage_name: 'esignkyc' }).then(response => {

            this.setState({ isloading: false });

            if (this.props.personal_result['code'] !== '000')
                alert(this.props.personal_result['messageText']);

            else {
                if (this.props.personal_result['state_parameters'])
                    this.setState(this.props.personal_result['state_parameters']);
                else
                    this.setState({
                        config_digio: this.props.personal_result['config_digio'],
                        is_stage_verified: this.props.personal_result['is_verified'],
                        is_verify_enabled: this.props.personal_result['is_verified'],
                        is_confirm_chk_enabled: true,
                        is_kyc_submission_chk_enabled: true,
                        esignkyc_information: this.props.personal_result['id_information']
                    });
            }

        })
            .catch(err => {
                alert(err)
            });


    };

    onVerifyStage = (props, state) => {

        let _esignkyc_information = this.state.esignkyc_information['digio_esign_parameters'];
        let _digioInfo = _esignkyc_information['digio_configuration'];
        var digio;

        var options = {

            environment: _digioInfo['environment'], // || (this.state.config_digio['environment'] || 'sandbox'),
            logo: _digioInfo['logo'] || 'http://pharmacyvials.com/assets/images/yourlogohere.jpg',
            method: 'otp',
            is_iframe: true,

            callback: function (response) {

                let result = response || {};

                if (result['error_code'] === 'CANCELLED') {
                    window.onscroll = function () { };
                    state.setState({ is_esign_button_disabled: false });
                }
                else {

                    if (result['txn_id'])
                        props.verifyStage({
                            verify_stage: 'esignkyc', esign_response: result
                        },
                            'esignkyc',
                            response => {

                                // alert(props);
                                // alert(JSON.stringify(response));
                                // alert(JSON.stringify(props.personal_result));

                                let _esign_response = response || props.personal_result;

                                if (_esign_response && _esign_response['esignkyc_stage_verified']) {
                                    //alert(_esign_response['messageText']);
                                    state.setState({
                                        show_popup: true,
                                        pop_messagetext: _esign_response['messageText'],
                                        is_esign_verified: true,
                                        is_kyc_verified: true
                                    });
                                    window.onscroll = function () { };
                                    digio.cancel();
                                }
                            });

                }

                /*
                if (result.hasOwnProperty('error_code')) 
                { } 
                else { }
                */

            }
        };

        digio = new window.Digio(options);
        digio.init();
        digio.submit(_esignkyc_information['digio_id'], _esignkyc_information['digio_email']);

        this.setState({ is_esign_button_disabled: true });

        window.scrollTo(0, 0);
        // window.onscroll = function () {
        //     window.scrollTo(0, 0);
        // };

    };

    createDefaultMarkup(text) {
        return {
            __html: this.state.digio_html,
        }
    };

    closePopup = () => {
        this.setState({
            show_popup: false,
            pop_messagetext: "",
        });
    };


    render() {

        // if (this.props.personal_result && this.props.personal_result['esignkyc_stage_verified'])
        //     return <Redirect to={this.props.personal_result['navigateScreen']} />;

        return (
            <>
                <Header1 />
                <Sidebar />
                <div className="authincation section-padding">
                    <div className="container" style={{ marginTop: 50 }}>
                        <div className="content-body">
                            <div class="row">
                                <div class="card">
                                    <div class="col-xl-12">

                                        <h5 className="text-center" style={{ marginTop: 20 }}>ESign KYC</h5>
                                        {!this.state.isloading &&
                                            <div style={{ textAlign: 'left' }}>
                                                {(this.state.is_esign_verified && this.state.is_kyc_verified && !this.state.is_client_code_approved) ?
                                                    <span style={{
                                                        fontSize: 16,
                                                        backgroundColor: '#dcd1e6', borderRadius: 4
                                                    }} >
                                                        Your signed KYC is awaiting approval from BSE Star MF Platform. Normally It would take 1-3 working days.
                                                    </span>
                                                    :
                                                    <span>
                                                        {(!this.state.is_esign_verified || !this.state.is_kyc_verified) &&
                                                            <div>
                                                                <span style={{
                                                                    fontSize: 16,
                                                                    backgroundColor: '#dcd1e6', borderRadius: 4
                                                                }} >
                                                                    Your KYC is not submitted and current verification stage is {this.state.login_stage}
                                                                </span>
                                                                {this.state.login_stage !== 'esignkyc' &&
                                                                    <a href={`/` + this.state.login_stage + `Verification`} className="btn btn-success btn-block">
                                                                        Click here to complete
                                                                    </a>
                                                                }
                                                            </div>

                                                        }
                                                    </span>
                                                }
                                            </div>
                                        }

                                        <div>

                                            {this.state.isloading && <Spinner animation="border" variant="dark" style={{ position: "fixed", top: "50%", left: "50%" }} />}

                                            <hr />
                                            {this.state.isloading ?
                                                <Alert variant="secondary text-center">
                                                    <Alert.Heading>
                                                        We are fetching your kyc information
                                                    </Alert.Heading>
                                                </Alert>
                                                :
                                                <div class="card">
                                                    <div class="row">
                                                        <span style={{ width: 90 }}>
                                                            Email Id
                                                        </span> {this.state.login_email}
                                                    </div>
                                                    <div class="row">
                                                        <span style={{ width: 90 }}>
                                                            Mobile
                                                        </span> {this.state.login_mobile_number}
                                                    </div>
                                                    <hr />
                                                </div>
                                            }

                                            {this.state.esignkyc_information && (this.state.esignkyc_information['pan'] || []).length > 0 &&
                                                ((this.state.esignkyc_information['pan'] || [])[0]['text'] || ``).length > 6 ?
                                                <div>
                                                    {this.state.login_stage !== 'pan' &&
                                                        <Card >
                                                            <b>PAN INFORMATION</b>
                                                            <table style={{ width: '100%' }}>
                                                                {this.state.esignkyc_information['pan'].map((el, i) =>
                                                                    <tr key={i} >
                                                                        <td style={{ width: 160, height: 30, border: '1px solid #f0eded', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                            <span>
                                                                                {el['attribute']}
                                                                            </span>
                                                                        </td>
                                                                        <td style={{ height: 30, border: '1px solid #f0eded', textAlign: 'center' }}>
                                                                            {el['text'] && el['text'].length < 20 ?
                                                                                <label>
                                                                                    {el['text']}
                                                                                </label> :
                                                                                <label style={{ paddingBottom: 20 }}>
                                                                                    {el['text']}
                                                                                </label>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </table>
                                                            <div style={{ height: 10 }} />
                                                            <table style={{ width: '100%' }}>
                                                                {(this.state.esignkyc_information['pan_attachment_list'] || []).map((el, i) =>
                                                                    <tr>
                                                                        <td>
                                                                            <img key={i} src={el} style={{ width: 330, height: 250 }} />
                                                                        </td>
                                                                    </tr>
                                                                )
                                                                }
                                                            </table>
                                                        </Card>
                                                    }

                                                    {this.state.esignkyc_information['aadhaar'] &&
                                                        ((this.state.esignkyc_information['aadhaar'] || [])[0]['text'] || ``).length > 5 &&
                                                        <Card >
                                                            <div style={{ height: 10 }} />
                                                            <b>AADHAAR INFORMATION</b>
                                                            <table style={{ width: '100%' }}>
                                                                {this.state.esignkyc_information['aadhaar'].map((el, i) =>

                                                                    el['attribute'] !== 'address_information' &&
                                                                    <tr key={i} >
                                                                        <td style={{ width: 120, height: 30, border: '1px solid #f0eded', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                            <span>
                                                                                {el['attribute']}
                                                                            </span>
                                                                        </td>
                                                                        <td style={{ height: 30, border: '1px solid #f0eded', textAlign: 'left' }}>
                                                                            {el['text'] && el['text'].length < 20 ?
                                                                                <label>
                                                                                    {el['text']}
                                                                                </label> :
                                                                                <label style={{ paddingBottom: 20 }}>
                                                                                    {el['text']}
                                                                                </label>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </table>
                                                            <div style={{ height: 10 }} />
                                                            <table style={{ width: '100%' }}>
                                                                {(this.state.esignkyc_information['aadhaar_attachment_list'] || []).map((el, i) =>
                                                                    <tr>
                                                                        <td>
                                                                            <img key={i} src={el} style={{ width: 330, height: 250 }} />
                                                                        </td>
                                                                    </tr>
                                                                )
                                                                }
                                                            </table>

                                                        </Card>
                                                    }
                                                    {this.state.esignkyc_information['bank'] &&
                                                        ((this.state.esignkyc_information['bank'] || [])[0]['text'] || ``).length > 5 &&
                                                        <Card>
                                                            <div style={{ height: 10 }} />
                                                            <b>BACK ACCOUNT INFORMATION</b>
                                                            <table style={{ width: '100%' }}>
                                                                {this.state.esignkyc_information['bank'].map((el, i) =>
                                                                    el['attribute'] !== 'BRANCH ADDRESS' &&
                                                                    <tr key={i} >
                                                                        <td style={{ width: 120, height: 30, border: '1px solid #f0eded', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                            <span>
                                                                                {el['attribute']}
                                                                            </span>
                                                                        </td>
                                                                        <td style={{ height: 30, border: '1px solid #f0eded', textAlign: 'left' }}>
                                                                            <label>
                                                                                {el['text']}
                                                                            </label>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </table>
                                                            <div style={{ height: 10 }} />
                                                            <table style={{ width: '100%' }}>
                                                                {(this.state.esignkyc_information['bank_attachment_list'] || []).map((el, i) =>
                                                                    <tr>
                                                                        <td>
                                                                            <img key={i} src={el} style={{ width: 330, height: 250 }} />
                                                                        </td>
                                                                    </tr>
                                                                )
                                                                }
                                                            </table>
                                                        </Card>
                                                    }
                                                    {((this.state.esignkyc_information['address'] || [])[0]['text'] || ``).length > 10 &&
                                                        this.state.esignkyc_information['address'] &&
                                                        <Card>
                                                            <div style={{ height: 10 }} />
                                                            <div class="row">
                                                                <form action="#">
                                                                    <b>COMMUNICATION ADDRESS</b>
                                                                    {[this.state.esignkyc_information['address'][0]].map((el, i) =>
                                                                        <div key={i}>
                                                                            <label style={{ height: 22 }}>
                                                                                <span style={{ color: '#13855d' }}>
                                                                                    {el['attribute']}
                                                                                </span>
                                                                                {' : '}
                                                                                <label>
                                                                                    {el['text']}
                                                                                </label>
                                                                            </label>
                                                                        </div>
                                                                    )
                                                                    }
                                                                    <div style={{ height: 5 }} />
                                                                    <table style={{ width: '100%' }}>
                                                                        {this.state.esignkyc_information['address'].map((el, i) =>

                                                                            el['attribute'].toLowerCase() !== 'address' &&
                                                                            <tr key={i} >
                                                                                <td style={{ width: 120, height: 30, border: '1px solid #f0eded', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                                    <span>
                                                                                        {el['attribute']}
                                                                                    </span>
                                                                                </td>
                                                                                <td style={{ height: 30, border: '1px solid #f0eded', textAlign: 'left' }}>
                                                                                    {el['text'] && el['text'].length < 20 ?
                                                                                        <label>
                                                                                            {el['text']}
                                                                                        </label> :
                                                                                        <label style={{ paddingBottom: 20 }}>
                                                                                            {el['text']}
                                                                                        </label>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </table>
                                                                </form>
                                                            </div>
                                                            {((this.state.esignkyc_information['general'] || [])[0]['text'] || ``).length > 2 &&
                                                                <div class="row">
                                                                    <form action="#">
                                                                        <div style={{ height: 10 }} />
                                                                        <b>GENERAL INFORMATION</b>
                                                                        <table style={{ width: '100%' }}>
                                                                            {this.state.esignkyc_information['general'].map((el, i) =>

                                                                                el['attribute'].toLowerCase() !== 'email' &&
                                                                                <tr key={i} >
                                                                                    <td style={{ width: 180, height: 30, border: '1px solid #f0eded', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                                        <span>
                                                                                            {el['attribute']}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td style={{ height: 30, border: '1px solid #f0eded', textAlign: 'center' }}>
                                                                                        {el['text'] && el['text'].length < 20 ?
                                                                                            <label>
                                                                                                {el['text']}
                                                                                            </label> :
                                                                                            <label style={{ paddingBottom: 20 }}>
                                                                                                {el['text']}
                                                                                            </label>
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            )}
                                                                        </table>
                                                                    </form>
                                                                </div>
                                                            }
                                                            <div class="row">
                                                                {this.state.esignkyc_information['general_attachment_list'] &&
                                                                    <div>
                                                                        {this.state.esignkyc_information['general_attachment_list'].map((el, i) =>
                                                                            <div key={i} className="form-group">
                                                                                <img src={el} style={{ width: 330, height: 250 }} />
                                                                            </div>
                                                                        )
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>

                                                            {this.state.esignkyc_information['nominee'] &&
                                                                ((this.state.esignkyc_information['nominee'] || [])[0]['text'] || ``).length > 4 &&
                                                                <div class="row">
                                                                    <div class="col">
                                                                        <form action="#">
                                                                            <div style={{ height: 10 }} />
                                                                            <b>NOMINEE INFORMATION</b>
                                                                            <table style={{ width: '100%' }}>
                                                                                {this.state.esignkyc_information['nominee'].map((el, i) =>

                                                                                    el['attribute'].toLowerCase() !== 'email' &&
                                                                                    <tr key={i} >
                                                                                        <td style={{ height: 30, border: '1px solid #f0eded', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                                                                            <span>
                                                                                                {el['attribute']}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td style={{ height: 30, border: '1px solid #f0eded', textAlign: 'center' }}>
                                                                                            {el['text'] && el['text'].length < 20 ?
                                                                                                <label>
                                                                                                    {el['text']}
                                                                                                </label> :
                                                                                                <label style={{ paddingBottom: 20 }}>
                                                                                                    {el['text']}
                                                                                                </label>
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                )}
                                                                            </table>
                                                                        </form>
                                                                    </div>
                                                                    <div class="col">
                                                                        {this.state.esignkyc_information['nominee_attachment_list'] &&
                                                                            <div >
                                                                                {this.state.esignkyc_information['nominee_attachment_list'].map((el, i) =>
                                                                                    <div key={i} className="form-group">
                                                                                        <img src={el} style={{ width: 330, height: 250 }} />
                                                                                    </div>
                                                                                )
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </Card>
                                                    }
                                                </div>
                                                :
                                                <p>
                                                    {this.state.isloading ? `` : `You have not uploaded the required information.`}
                                                </p>
                                            }

                                            {
                                                this.state.is_esign_verified && this.state.is_kyc_verified &&
                                                <div id="div-message">
                                                    <hr />
                                                    {!this.state.is_client_code_approved &&
                                                        <span style={{
                                                            fontSize: 16,
                                                            backgroundColor: '#dcd1e6', borderRadius: 4
                                                        }}>
                                                            Your signed KYC is awaiting approval from BSE Star MF platform. Normally it would take 1-3 working days.
                                                        </span>
                                                    }
                                                </div>
                                            }

                                            {

                                /*this.state.is_esign_verified &&*/ this.state.is_kyc_submission_chk_enabled &&
                                                <div>
                                                    <div className="card-body">
                                                        <div>
                                                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                                <Form.Check type="checkbox" label="I here by agree to e-Sign my KYC with aadhaar otp authentication supported by digio."
                                                                    value={this.state.is_confirm_chk_checked}
                                                                    onChange={this.handleConfirmCheck} defaultChecked={this.state.is_confirm_chk_checked}
                                                                />
                                                            </Form.Group>

                                                            {
                                                                this.state.is_confirm_chk_checked &&
                                                                <div className="text-center">
                                                                    <Button
                                                                        className="btn btn-success btn-block"
                                                                        variant="success"
                                                                        disabled={this.state.is_esign_button_disabled ||
                                                                            this.state.isloading
                                                                        }
                                                                        onClick={() => this.onVerifyStage(this.props, this)}>eSIGN</Button>
                                                                </div>
                                                            }
                                                            {/* {
                                                             this.state.is_confirm_chk_checked &&
                                                             this.state.is_esign_button_disabled &&
                                                            <div dangerouslySetInnerHTML={ this.createDefaultMarkup()} />
                                                        } */}
                                                        </div>
                                                    </div>
                                                </div>

                                            }
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div></div>
                        </div></div></div>
                {this.state.show_popup && (
                    <Modal show={this.state.show_popup} onHide={() => this.closePopup()}
                        centered>
                        <Modal.Header>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{this.state["pop_messagetext"]}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => this.closePopup()}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </>
        )

    }

}
// EsignkycVerification.propTypes = {
//     validateOtp: PropTypes.func.isRequired,
//     isAuthenticated: PropTypes.bool
// };

const mapStateToProps = state => ({
    personal_result: state.verify.personal_result
});

export default connect(mapStateToProps, { verifyStage, loadStageInfo })
    (EsignkycVerification);