import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Form, Row, Col, Button, Label, Spinner } from "react-bootstrap";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import { Fab, Action } from 'react-tiny-fab';

const IncomeDetails = ({ prevStep, nextStep, handleChange, values, handleSelectChange, handleRadioChange, errors, loading }) => {
  console.log(errors)
  const Continue = e => {
    e.preventDefault();
    nextStep();
  }

  const Previous = e => {
    e.preventDefault();
    prevStep();
  }
  return (
    <>
      <Header1 />
      <Sidebar />
      <div id="navbar-personalinfo-container">
        <div id="navbar" >
          <div className="card" style={{ backgroundColor: '#f8f8fb' }}>
            <div className="row">
              <h5 style={{ textAlign: 'center', marginTop: 5 }}> Your Income Information</h5>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: 15 }}>
        <div className="container">
          <div class="row">
            <div class="col-xl-12">

              <h6 className="text-center mb-5"></h6>
              <div className="card" style={{ marginTop: -45 }} >
                <div className="card-body">
                  <Form>


                    <Form.Group as={Row} className="mb-3" controlId="formBasicsourcewealth">

                      <Col sm={4}>

                        <Form.Label className="text-dark">Source Of Wealth*</Form.Label>
                        <Select options={values['master_list']['source_wealth']}
                          onChange={handleSelectChange('income', 'source_of_wealth')}
                          value={values['master_list']['source_wealth'].find(obj => obj.value === values.source_of_wealth)}
                          isDisabled={values['is_bse_nominee_verified']}
                          isSearchable={false}
                        /> <Form.Text className="text-danger">
                          {errors && errors["source_of_wealth"]}
                        </Form.Text>

                      </Col>
                      <Col sm={4}>
                        <Form.Label className="text-dark">Occupation*</Form.Label>
                        <Select options={values['master_list']['occupation_list']} onChange={handleSelectChange('income', 'occupation')}
                          value={values['master_list']['occupation_list'].find(obj => obj.value === values.occupation)}
                          isDisabled={values['is_bse_nominee_verified']}
                          isSearchable={false}
                        />

                        <Form.Text className="text-danger">
                          {errors && errors["occupation"]}
                        </Form.Text>
                      </Col>
                      <Col sm={4}>
                        <Form.Label className="text-dark">Annual Income Range*</Form.Label>
                        <Select options={values['master_list']['income_range']} onChange={handleSelectChange('income', 'annual_income')}
                          value={values['master_list']['income_range'].find(obj => obj.value === values.annual_income)}
                          isDisabled={values['is_bse_nominee_verified']}
                          isSearchable={false}
                        />

                        <Form.Text className="text-danger">
                          {errors && errors["annual_income"]}
                        </Form.Text>
                      </Col>

                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">

                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicIncomedetails" as={Row} >
                      <Form.Label className="text-dark">Do you have income outside india and paying tax in other countries ?*</Form.Label>
                      <Col>
                        <Form.Check
                          type="radio"
                          label="No"
                          name="incomeOutsideIndia"
                          value="false"
                          checked={values.is_income_outside_india === false}
                          onChange={handleRadioChange('is_income_outside_india')}
                          defaultValue={values.is_income_outside_india}
                          disabled={values['is_bse_nominee_verified']}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          type="radio"
                          label="Yes"
                          name="incomeOutsideIndia"
                          value="true"
                          checked={values.is_income_outside_india === true}
                          onChange={handleRadioChange('is_income_outside_india')}
                          defaultValue={values.is_income_outside_india}
                          disabled={values['is_bse_nominee_verified']}
                        />
                      </Col>
                      <Col></Col>
                    </Form.Group>
                    <Form.Text className="text-danger">
                      {errors && errors["incomeOutsideIndia"]}
                    </Form.Text>
                    <Form.Group className="mb-3" controlId="formBasicpoliticallyexposed" as={Row} >
                      <Form.Label className="text-dark">Are you related to politically exposed person in india ?*</Form.Label>
                      <Col>
                        <Form.Check
                          type="radio"
                          label="No"
                          name="politicallyExposedIndia"
                          id="false"
                          value="false"
                          checked={values.is_political_india === false}
                          onChange={handleRadioChange('is_political_india')}
                          defaultValue={values.is_political_india}
                          disabled={values['is_bse_nominee_verified']}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          type="radio"
                          label="Yes"
                          name="politicallyExposedIndia"
                          id="true"
                          value="true"
                          checked={values.is_political_india === true}
                          onChange={handleRadioChange('is_political_india')}
                          disabled={values['is_bse_nominee_verified']}
                        />
                      </Col>
                      <Col></Col>
                    </Form.Group>
                    <Form.Text className="text-danger">
                      {errors && errors["is_political_india"]}
                    </Form.Text>
                    <Form.Group className="mb-3" controlId="formBasicpoliticallyexposed" as={Row}>
                      <Form.Label className="text-dark">Are you related to politically exposed person in a foreign countries?*</Form.Label>
                      <Col>
                        <Form.Check
                          type="radio"
                          label="No / Not Applicable"
                          name="politicallyExposedForeign"
                          id="No"
                          value="false"
                          style={{width:150}}
                          checked={values.is_political_foreign === false}
                          onChange={handleRadioChange('is_political_foreign')}
                          disabled={values['is_bse_nominee_verified']}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          type="radio"
                          label="Yes"
                          name="politicallyExposedForeign"
                          id="Yes"
                          value="true"
                          checked={values.is_political_foreign === true}
                          onChange={handleRadioChange('is_political_foreign')}
                          defaultValue={values.is_political_foreign}
                          disabled={values['is_bse_nominee_verified']}
                        />
                      </Col>
                      <Col>
                      </Col>
                    </Form.Group>
                    <Form.Text className="text-danger">
                      {errors && errors["is_political_foreign"]}
                    </Form.Text>
                    {/* <Form.Label className="text-dark">Your confirming that your Information is true by clicking up the next button</Form.Label> */}
                    {/* <div class="row">
                        <div class="col-3">
                          <Button
                            onClick={Previous}
                            type="submit"
                            variant="secondary"
                            color="primary"
                            style={{ 'width': '120px' }}
                          >
                            Previous
                          </Button>

                        </div>
                        <div class="col-6">
                          <span style={{ 'margin-right': '70px' }}></span>
                          <Button
                            onClick={Continue}
                            type="submit"
                            variant="success"
                            color="primary"
                            style={{ 'width': '120px' }}
                          >
                            Next
                          </Button>
                        </div>
                      </div> */}

                  </Form>

                  <Fab
                    style={{ bottom: 22, left: -15, transform: 'scale(0.8)' }}
                    alwaysShowTitle={true}
                    mainButtonStyles={{
                      backgroundColor: "#d45950"
                    }}
                    disabled={loading}
                    icon={<i className="fa fa-arrow-left  fa-lg" ></i>}
                    onClick={Previous}
                  >
                  </Fab>

                  <Fab
                    style={{ bottom: 22, right: -18, transform: 'scale(0.8)' }}
                    // event="hover"
                    alwaysShowTitle={true}
                    mainButtonStyles={{
                      backgroundColor: "#2ea34d"
                    }}
                    disabled={loading}
                    icon={<i className="fa fa-arrow-right  fa-lg" ></i>}
                    onClick={Continue}
                  >
                  </Fab>

                  {loading && (
                    <Spinner
                      animation="border"
                      variant="dark"
                      style={{ position: "fixed", top: "50%", left: "50%" }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default IncomeDetails;