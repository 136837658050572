import React from "react";
import { connect } from "react-redux";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import PageTitle from "../../element/page-title";
import { Modal, ListGroup, Row, Col, Container, Spinner } from "react-bootstrap";

import Select, { components } from "react-select";
import { listSchemes } from "../../../jsx/actions/master";
import { Button, Badge, Collapse, Alert } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Emitter from "../../utils/emitter";

import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';

const filter_conditional_parameters = [
  "growth",
  "minimum_investment",
  "investment_method",
];

class SchemeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      masterList: JSON.parse(
        localStorage.getItem("masterList") || JSON.stringify({})
      ),
      error: null,
      isLoaded: false,
      mutualFundList: [],
      pageIndex: 1,
      openCollapse: false,
      pageIndexAvailability: true,
      filterOption: {
        scheme_amc: /*selectedOptionAmc*/[],
        category: [],
        risk: [],
        sub_category: [],
        growth: [],
        minimum_investment: [],
        investment_method: [],
      },
      sub_category_list: [],
      selectedOptionAmc: [],
      selectedOptionCategory: [],
      selectedOptionRisk: [],
      selectedOptionSubCategory: [],
      selectedOptionGrowth: [],
      selectedOptionMinInvestment: [],
      selectedOptionInvestmentMethod: [],
      options: [],
      newValue: 0,
      scheme_list: [],
      perPage: 30,
      page: 0,
      pages: 0,
      loading: false,
      show_modal_filter: false
    };
  }

  componentDidMount() {

    window.addEventListener("scroll", () => {

      if (document.getElementById("navbar-schemelist-container") &&
        document.getElementById("navbar") &&
        document.getElementById("navbar").classList) {
        const topBorder = document
          .getElementById("navbar-schemelist-container")
          .getBoundingClientRect().top;

        topBorder >= 0
          ? document.getElementById("navbar").classList.remove("fixed-schemelist")
          : document.getElementById("navbar").classList.add("fixed-schemelist");
      }

    });

    this.setState({
      masterList: JSON.parse(
        localStorage.getItem("masterList") || JSON.stringify({})
      ),
    });

    if (localStorage.getItem("profileData")) {
      Emitter.emit("HEADER1", JSON.parse(localStorage.getItem("profileData")));
      Emitter.emit("APPJS", "VANAKKAM");
      this.loadSchemeList();
    } else
      this.props.history.push({
        pathname: "/signin",
      });
      
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', null);
  }

  handleChangeAmc = (selectedOption) => {
    this.setState(
      {
        filterOption: {
          ...this.state.filterOption,
          scheme_amc: selectedOption,
        },
        selectedOptionAmc: selectedOption,
        page: 0,
      },
      () => this.filterGridList(/*selectedOption, 'scheme_amc'*/)
    );
  };

  handleChangeCategory = (selectedOption) => {
    
    this.setState(
      {
        filterOption: { ...this.state.filterOption, category: selectedOption },
        selectedOptionCategory: selectedOption,
        // sub_category_list: sub_category_list
      },
      () => this.filterGridList(/*selectedOption, 'category'*/)
    );
  };

  handleChangeSubCategory = (selectedOption) => {
    this.setState({ selectedOptionSubCategory: selectedOption });
    this.filterGridList(/*selectedOption, 'sub_category'*/);
  };

  handleChangeRisk = (selectedOption) => {
    this.setState(
      {
        filterOption: { ...this.state.filterOption, risk: selectedOption },
        selectedOptionRisk: selectedOption,
      },
      () => this.filterGridList(/*selectedOption, 'risk'*/)
    );
  };

  handleChangeGrowth = (selectedOption) => {
    this.setState(
      {
        filterOption: { ...this.state.filterOption, growth: selectedOption },
        selectedOptionGrowth: selectedOption,
      },
      () => this.filterGridList(/*selectedOption, 'growth'*/)
    );

    // this.filterGrowthoption(selectedOption);
    // this.setState({ selectedOptionGrowth: selectedOption });
  };

  handleChangeMinInvestment = (selectedOption) => {
    this.setState(
      {
        filterOption: {
          ...this.state.filterOption,
          minimum_investment: selectedOption,
        },
        selectedOptionMinInvestment: selectedOption,
      },
      () => this.filterGridList(/*selectedOption, 'minimum_investment'*/)
    );
  };

  handleChangeInvestmentMode = (selectedOption) => {
    this.setState(
      {
        filterOption: {
          ...this.state.filterOption,
          investment_method: selectedOption,
        },
        selectedOptionInvestmentMode: selectedOption,
      },
      () => this.filterGridList(/*selectedOption, 'investment_method'*/)
    );
  };

  routeChange = (item) => {
    if (window.matchMedia && window.matchMedia("(max-width: 480px)").matches) {
      this.props.history.push({
        pathname: "/chartsmobile",
        state: {
          param_data: {
            scheme_isin: item["scheme_isin"],
            scheme_code: item["scheme_code"],
            scheme_name: item["scheme_name"],
          },
        },
      });
    } else {
      const win = window.open(
        "/charts/" +
        item["scheme_isin"] +
        "/" +
        item["scheme_code"] +
        "/" +
        item["scheme_name"],
        "_blank"
      );
      win.focus();
    }
  };

  filterGridList_BackUp = (amclistFilter) => {

    let _filtered_list = this.props.result["list_scheme"] || []; //this.state.scheme_list || [];
    let _filterOption = amclistFilter ? amclistFilter : this.state.filterOption;

    if (amclistFilter) {
      this.setState({
        filterOption: {
          ...this.state.filterOption,
          scheme_amc: _filterOption["scheme_amc"],
          category: _filterOption["category"],
          risk: _filterOption["risk"],
          growth: _filterOption["growth"],
          minimum_investment: _filterOption["minimum_investment"],
          investment_method: _filterOption["investment_method"],
        },
        selectedOptionGrowth: _filterOption["growth"],
        selectedOptionMinInvestment: _filterOption["minimum_investment"],
        selectedOptionInvestmentMode: _filterOption["investment_method"],
        /*
        selectedOptionAmc: _filterOption['scheme_amc'],
        selectedOptionCategory: _filterOption['category'],
        selectedOptionRisk: _filterOption['risk'],
        selectedOptionSubCategory: _filterOption['sub_category']
        */
      });
    }

    let _selected_list = [];

    Object.keys(_filterOption).forEach((key) => {

      _selected_list = _filterOption[key];

      if (_selected_list.length > 0) {

        if (
          filter_conditional_parameters.filter((__el) => {
            return __el === key;
          }).length > 0
        ) {
          switch (key) {
            case "growth":
              let _filter = _filterOption[key];
              let _arr_values = [];
              let boolTrue = false;

              let __filtered_list = [];

              _filtered_list.forEach((el) => {
                boolTrue = false;

                _filter.forEach((el_) => {
                  if (!boolTrue) {
                    _arr_values = el_.value.split("-");

                    boolTrue =
                      parseFloat(el["one_year_percent"] || 0) >=
                      parseFloat(_arr_values[0]) &&
                      parseFloat(el["one_year_percent"] || 0) <=
                      parseFloat(_arr_values[1]);

                    if (!boolTrue)
                      boolTrue =
                        parseFloat(el["three_year_percent"] || 0) >=
                        parseFloat(_arr_values[0]) &&
                        parseFloat(el["three_year_percent"] || 0) <=
                        parseFloat(_arr_values[1]);

                    if (!boolTrue)
                      boolTrue =
                        parseFloat(el["five_year_percent"] || 0) >=
                        parseFloat(_arr_values[0]) &&
                        parseFloat(el["five_year_percent"] || 0) <=
                        parseFloat(_arr_values[1]);
                  }

                  if (boolTrue) __filtered_list.push(el);
                });

                // return boolTrue;

                /*
                return _filter.filter(el_ =>  {
                  _arr_values = el_.value.split('-');
                  return (parseFloat(el['one_year_percent'] || 0) >= parseFloat(_arr_values[0]) && 
                    parseFloat(el['one_year_percent'] || 0) <= parseFloat(_arr_values[1])) 
                    || 
                    (parseFloat(el['three_year_percent'] || 0) >= parseFloat(_arr_values[0]) && 
                    parseFloat(el['three_year_percent'] || 0) <= parseFloat(_arr_values[1]))
                    ||
                    (parseFloat(el['five_year_percent'] || 0) >= parseFloat(_arr_values[0]) && 
                    parseFloat(el['five_year_percent'] || 0) <= parseFloat(_arr_values[1])) ;
                });*/ //.length > 0;
              });

              _filtered_list = __filtered_list;

              // _filtered_list = [...new Set(_filtered_list)];

              break;
            case "minimum_investment":
              _filtered_list = _filtered_list.filter((el) => {
                return (
                  _filterOption[key].filter((el_) => {
                    return (
                      parseFloat(el["scheme_minimum_investment_amount"] || 0) >=
                      parseFloat(el_.value) &&
                      parseFloat(el["scheme_minimum_investment_amount"] || 0) <=
                      parseFloat(el_.value) + 10
                    );
                  }).length > 0
                );
              });
              break;
            case "investment_method":
              let _condition = _filterOption[key].map((el) => {
                return el["value"];
              });

              _filtered_list = _filtered_list.filter((el) => {
                if (_condition.length > 1)
                  return el[_condition[0]] === "Y" || el[_condition[1]] === "Y";
                else return el[_condition[0]] === "N";
              });
              break;

            case "category":

              let condition_category = _filterOption[key].map((el) => {
                return el["value"];
              });

              _filtered_list = _filtered_list.filter((el) => {
                if (condition_category.length > 1)
                  return el[condition_category[0]];
              });
              break;

            default:
              break;
          }
        } else {

          let _loc = "";
          let _condition = _selected_list; /*.map(el => {
               return el.value.toLowerCase();
             });*/

          _filtered_list = _filtered_list.filter((el) => {

            _loc = (el[key] || "").toLowerCase();
            return (
              _condition.filter((el_) => {
                return _loc === el_["value"].toLowerCase();
              }).length > 0
            );
          });
        }
      }
    });

    sessionStorage.setItem("amclistFilter", JSON.stringify(_filterOption));

    this.setState({
      scheme_list: _filtered_list,
      pages: Math.round(
        /*floor*/ _filtered_list.length / this.props.result["per_page_count"] ||
        this.state.perPage
      ),
    });
  };

  filterGridList = (amclistFilter) => {

    let _filtered_list = this.props.result["list_scheme"] || []; //this.state.scheme_list || [];
    let _filterOption = amclistFilter ? amclistFilter : this.state.filterOption;

    if (amclistFilter) {

      this.setState({
        filterOption: {
          ...this.state.filterOption,
          scheme_amc: _filterOption["scheme_amc"],
          category: _filterOption["category"],
          risk: _filterOption["risk"],
          growth: _filterOption["growth"],
          minimum_investment: _filterOption["minimum_investment"],
          investment_method: _filterOption["investment_method"],
        },
        selectedOptionGrowth: _filterOption["growth"],
        selectedOptionMinInvestment: _filterOption["minimum_investment"],
        selectedOptionInvestmentMode: _filterOption["investment_method"]

      });

    }

    let _selected_list = [];
    
    Object.keys(_filterOption).forEach((key) => {

      _selected_list = _filterOption[key];

      if (_selected_list.length > 0) {

        if (
          filter_conditional_parameters.filter((__el) => {
            return __el === key;
          }).length > 0
        ) {
          
          switch (key) {

            case "growth":
              let _filter = _filterOption[key];
              let _arr_values = [];
              let boolTrue = false;

              let __filtered_list = [];

              _filtered_list.forEach((el) => {
                boolTrue = false;

                _filter.forEach((el_) => {
                  if (!boolTrue) {
                    _arr_values = el_.value.split("-");

                    boolTrue =
                      parseFloat(el["one_year_percent"] || 0) >=
                      parseFloat(_arr_values[0]) &&
                      parseFloat(el["one_year_percent"] || 0) <=
                      parseFloat(_arr_values[1]);

                    if (!boolTrue)
                      boolTrue =
                        parseFloat(el["three_year_percent"] || 0) >=
                        parseFloat(_arr_values[0]) &&
                        parseFloat(el["three_year_percent"] || 0) <=
                        parseFloat(_arr_values[1]);

                    if (!boolTrue)
                      boolTrue =
                        parseFloat(el["five_year_percent"] || 0) >=
                        parseFloat(_arr_values[0]) &&
                        parseFloat(el["five_year_percent"] || 0) <=
                        parseFloat(_arr_values[1]);
                  }

                  if (boolTrue) __filtered_list.push(el);
                });

              });

              _filtered_list = __filtered_list;

              // _filtered_list = [...new Set(_filtered_list)];

              break;
            case "minimum_investment":
              _filtered_list = _filtered_list.filter((el) => {
                return (
                  _filterOption[key].filter((el_) => {
                    return (
                      parseFloat(el["scheme_minimum_investment_amount"] || 0) >=
                      parseFloat(el_.value) &&
                      parseFloat(el["scheme_minimum_investment_amount"] || 0) <=
                      parseFloat(el_.value) + 10
                    );
                  }).length > 0
                );
              });
              break;
            case "investment_method":
              let _condition = _filterOption[key].map((el) => {
                return el["value"];
              });

              _filtered_list = _filtered_list.filter((el) => {
                if (_condition.length > 1)
                  return el[_condition[0]] === "Y" || el[_condition[1]] === "Y";
                else return el[_condition[0]] === "N";
              });
              break;

            case "category":

              let condition_category = _filterOption[key].map((el) => {
                return el["value"];
              });
              
              _filtered_list = _filtered_list.filter((el) => {
                if (condition_category.length > 1)
                  return el[condition_category[0]];
              });
              break;

            default:
              break;
          }

        } else {

          let _loc = false;
          let _condition = _selected_list.map(el => {
            return el.value.toLowerCase();
          });

          _filtered_list = _filtered_list.filter((el) => {

            el['_loc'] = false;
            _condition.forEach(_el => {

              if (key === 'scheme_amc') {
                if (!el['_loc'])
                  el['_loc'] = (el['scheme_amc'] || ``).toLowerCase().indexOf((_el || ``).toLowerCase()) > -1;
              }
              else if (key === 'category') {
                if (!el['_loc'])
                  el['_loc'] = (el['scheme_name'] || ``).toLowerCase().indexOf((_el || ``).toLowerCase()) > -1 ||
                    (el['scheme_category'] || ``).toLowerCase().indexOf((_el || ``).toLowerCase()) > -1;
              }
              else if (key === 'risk') {
                if (!el['_loc'])
                  el['_loc'] = (el['risk'] || ``).toLowerCase().indexOf((_el || ``).toLowerCase()) > -1;
              }

            });

            return el['_loc'];

          });

        }
      }

    });

    sessionStorage.setItem("amclistFilter", JSON.stringify(_filterOption));

    this.setState({
      scheme_list: _filtered_list,
      pages: Math.round(
        /*floor*/ _filtered_list.length / this.props.result["per_page_count"] ||
        this.state.perPage
      ),
    });
  };

  filterGrowthoption = (selectedOption) => {
    let _filtered_list = this.state.scheme_list || [];

    let text = selectedOption[0].value || "";
    let filtered_list = _filtered_list.filter((item) => {
      return text.length > 1
        ? (
          item.three_year_percent.toString() ||
          item.one_month_percent.toString() ||
          item.one_year_percent.toString()
        ).match(text)
        : true;
    });

    filtered_list = [...new Set(filtered_list)];

    this.setState({
      scheme_list: filtered_list,
      pages: Math.round(
        /*floor*/ filtered_list.length / this.props.result["per_page_count"] ||
        this.state.perPage
      ),
    });
  };

  filterMininvestmentoption = (selectedOption) => {
    let _filtered_list = this.state.scheme_list || [];

    let text = selectedOption[0].value || "";
    let filtered_list = _filtered_list.filter((item) => {
      return text.length > 1
        ? item.minimum_investment_amount.toString().match(text)
        : true;
    });

    this.setState({
      scheme_list: filtered_list,
      pages: Math.round(
        /*floor*/ filtered_list.length / this.props.result["per_page_count"] ||
        this.state.perPage
      ),
    });
  };

  resetFilter = () => {

    let _filtered_list = this.props.result["list_scheme"] || [];

    this.setState({
      filterOption: {
        ...this.state.filterOption,
        scheme_amc: [],
        category: [],
        risk: [],
        growth: [],
        minimum_investment: [],
        investment_method: [],
      },
      selectedOptionAmc: [],
      selectedOptionCategory: [],
      selectedOptionRisk: [],
      selectedOptionSubCategory: [],
      selectedOptionGrowth: [],
      selectedOptionMinInvestment: [],
      selectedOptionInvestmentMethod: [],

      scheme_list: _filtered_list,
      pages: Math.round(
        _filtered_list.length / this.props.result["per_page_count"] ||
        this.state.perPage
      )
    });

    sessionStorage.setItem("amclistFilter", JSON.stringify({
      scheme_amc: [],
      category: [],
      risk: [],
      sub_category: [],
      growth: [],
      minimum_investment: [],
      investment_method: [],
    }));

    this.setState();

  }

  loadSchemeList = () => {
    this.setState({ loading: true });
    this.props
      .listSchemes({
        file_index: this.state.pageIndex,
      })
      .then((response) => {
        const { perPage } = this.state;

        if (this.props.result["code"] === "000") {
          this.setState({
            scheme_list: this.props.result["list_scheme"] || [],
            pages: Math.round(
              /*floor*/(this.props.result["list_scheme"] || []).length /
              this.props.result["per_page_count"] || perPage
            ),
            loading: false,
            perPage: this.props.result["per_page_count"] || this.state.perPage,
          });

          this.filterGridList(
            sessionStorage.getItem("amclistFilter")
              ? JSON.parse(sessionStorage.getItem("amclistFilter"))
              : this.state.masterList['amc_filter']
          );

        } else {
          alert(this.props.result["messageText"]);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handlePageClick = (event) => {
    let page = event.selected;
    this.setState({ page });
    window.scrollTo(0, 0);
  };

  onNextPrevious = (count) => {
    // this.setState({pageIndex: this.state.pageIndex + count});
  };

  getBase64Image = (imgUrl) => {
    var img = new Image();

    // onload fires when the image is fully loadded, and has width and height

    img.onload = function () {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL("image/png"),
        dataURL = dataURL.replace(/^data:image\/(png|jpg);base64,/, "");

      return dataURL; // the base64 string
    };

    // set attributes and src
    img.setAttribute("crossOrigin", "anonymous"); //
    img.src = imgUrl;
  };

  render() {
    /*
    const SingleValue = (props) => {
      
      const { label } = props.getValue()[0];
    
      return (
        <components.SingleValue {...props}>
          <span>&#8377;</span> <span>{label.replace('100', `&#8377;`)}</span>
        </components.SingleValue>
      );
    };

    const Option = (props) => {
      const { label } = props.data;
    
      return (
        <components.Option {...props}>
          <span>&#8377;</span> <span >{label}</span>
        </components.Option>
      );
    };
    */

    const {
      filterOption,
      selectedOptionGrowth,
      selectedOptionMinInvestment,
      selectedOptionInvestmentMode,
    } = this.state;
    const { page, perPage, pages, scheme_list } = this.state;

    let _list_scheme =
      scheme_list.length > perPage
        ? scheme_list.slice(page * perPage, (page + 1) * perPage)
        : scheme_list;
    // let _list_scheme = scheme_list.slice( (page - 1) * perPage, page * perPage);

    return (
      <>
        <Header1 />
        <Sidebar />
        <PageTitle />
        <div style={{ marginBottom: 70 }}>
          <div id="navbar-schemelist-container">
            <div id="navbar" >
              <div className="card" style={{ backgroundColor: '#f8f8fb' }}>
                <div

                >
                  {_list_scheme && scheme_list.length >= perPage && (
                    <ReactPaginate
                      breakLabel="..."
                      previousLabel={"←"}
                      nextLabel={"→"}
                      pageCount={pages}
                      onPageChange={this.handlePageClick}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* <div> */}
          <div className="container" >

            <div class="row">
              <div class="col-xl-12" style={{ marginBottom: 30 }}>

                <div class="transaction-widget">
                  {_list_scheme.length < 1 && (
                    <Alert
                      variant="secondary text-center"
                      style={{ marginBottom: 150 }}
                    >
                      <Alert.Heading>
                        {this.state.loading
                          ? "We are loading the schemes, Kindly wait ....."
                          : "No results found"}
                      </Alert.Heading>
                    </Alert>
                  )}
                  {this.state.loading && (
                    <Spinner
                      animation="border"
                      variant="dark"
                      style={{ position: "fixed", top: "50%", left: "50%" }}
                    />
                  )}


                  <ListGroup >
                    {_list_scheme.map((item) => (
                      <ListGroup.Item
                        key={item["_id"]}
                        style={{
                          marginBottom: 10, border: '1px solid #e2e0e4',
                          borderCollapse: 'collapse'
                        }}
                        action
                        variant="light"
                        className="wallet-address text-dark"
                        onClick={() => this.routeChange(item)}
                      >

                        <table style={{ width: '100%' }}>
                          <tr>
                            <td style={{ textAlign: 'center' }}>
                              <img className="fundlogoMedium" src={item["logo_url"]} />
                            </td>
                            <td style={{ width: 10 }}>
                              <span>
                                {` `}
                              </span>
                            </td>
                            <td style={{ textAlign: 'left' }}>
                              <span > {item["scheme_name"].replace(new RegExp('-', 'g'), ' ')} </span>
                            </td>
                          </tr>
                        </table>

                        {/* {` `}{item["scheme_name_display_1"]}
                            {item["scheme_name_display_2"].length > 2 &&
                              <span>
                                {` `} {item["scheme_name_display_2"]}
                              </span>
                            } */}

                        <div style={{ height: 7 }}></div>
                        <Row>
                          <Col xs={11}>
                            <span
                              style={{
                                fontSize: 13,
                                // backgroundColor: "#F6F3F1"
                              }}
                            >
                              {item["scheme_category"]}
                            </span>
                          </Col>
                        </Row>
                        <div style={{ height: 7 }}></div>
                        {item["one_year_percent"] &&
                          !isNaN(parseInt(item["one_year_percent"])) &&

                          <table style={{ width: '100%' }}>
                            <tr>
                              <td style={{ height: 20, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'center' }}>
                                <span style={{
                                  margin: 4
                                }}>
                                  Lumpsum (%)
                                </span>
                              </td>
                              <td style={{ width: 10 }}>
                                <span>
                                  {` `}
                                </span>
                              </td>
                              <td style={{ height: 20, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'center' }}>
                                <span style={{
                                  margin: 4
                                }}>
                                  SIP (%)
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <table style={{ width: '100%' }}>
                                  <tr>
                                    {item["one_year_percent"] &&
                                      !isNaN(parseInt(item["one_year_percent"])) &&
                                      <td style={{ border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                        <span style={{
                                          margin: 4
                                        }}>
                                          1Y
                                        </span>
                                      </td>
                                    }
                                    {item["three_year_percent"] &&
                                      !isNaN(parseInt(item["three_year_percent"])) &&
                                      <td style={{ border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                        <span style={{
                                          margin: 4
                                        }}>
                                          3Y
                                        </span>
                                      </td>
                                    }
                                    {item["five_year_percent"] &&
                                      !isNaN(parseInt(item["five_year_percent"])) &&
                                      <td style={{ border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                        <span style={{
                                          margin: 4
                                        }}>
                                          5Y
                                        </span>
                                      </td>
                                    }
                                  </tr>
                                  <tr>
                                    {item["one_year_percent"] &&
                                      !isNaN(parseInt(item["one_year_percent"])) &&
                                      <td style={{ border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                        <span style={{
                                          margin: 4,
                                          color:
                                            item[
                                              "one_year_percent"
                                            ].substring(0, 1) === "-" ||
                                              ""
                                              ? "#f57362"
                                              : "#03b45e"
                                        }}>
                                          {item["one_year_percent"]}
                                        </span>
                                      </td>
                                    }
                                    {item["three_year_percent"] &&
                                      !isNaN(parseInt(item["three_year_percent"])) &&
                                      <td style={{ border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                        <span style={{
                                          margin: 4,
                                          color:
                                            item[
                                              "three_year_percent"
                                            ].substring(0, 1) === "-" ||
                                              ""
                                              ? "#f57362"
                                              : "#03b45e"
                                        }}>
                                          {item["three_year_percent"]}
                                        </span>
                                      </td>
                                    }
                                    {item["five_year_percent"] &&
                                      !isNaN(parseInt(item["five_year_percent"])) &&
                                      <td style={{ border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                        <span style={{
                                          margin: 4,
                                          color:
                                            item[
                                              "five_year_percent"
                                            ].substring(0, 1) === "-" ||
                                              ""
                                              ? "#f57362"
                                              : "#03b45e"
                                        }}>
                                          {item["five_year_percent"]}
                                        </span>
                                      </td>
                                    }
                                  </tr>
                                </table>
                              </td>
                              <td style={{ width: 10 }}>
                                <span>
                                  {` `}
                                </span>
                              </td>
                              <td>
                                <table style={{ width: '100%' }}>
                                  <tr>
                                    {item["sip_returns_data"]["1y_percent"] &&
                                      !isNaN(parseInt(item["sip_returns_data"]["1y_percent"])) &&
                                      <td style={{ border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                        <span style={{
                                          margin: 4
                                        }}>
                                          1Y
                                        </span>
                                      </td>
                                    }
                                    {item["sip_returns_data"]["3y_percent"] &&
                                      !isNaN(parseInt(item["sip_returns_data"]["3y_percent"])) &&
                                      <td style={{ border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                        <span style={{
                                          margin: 4
                                        }}>
                                          3Y
                                        </span>
                                      </td>
                                    }
                                    {item["sip_returns_data"]["5y_percent"] &&
                                      !isNaN(parseInt(item["sip_returns_data"]["5y_percent"])) &&
                                      <td style={{ border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                        <span style={{
                                          margin: 4
                                        }}>
                                          5Y
                                        </span>
                                      </td>
                                    }
                                  </tr>
                                  <tr>
                                    {item["sip_returns_data"]["1y_percent"] &&
                                      !isNaN(parseInt(item["sip_returns_data"]["1y_percent"])) &&
                                      <td style={{ border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                        <span style={{
                                          margin: 4,
                                          color:
                                            item["sip_returns_data"][
                                              "1y_percent"
                                            ].substring(0, 1) === "-" ||
                                              ""
                                              ? "#f57362"
                                              : "#03b45e"
                                        }}>
                                          {item["sip_returns_data"]["1y_percent"]}
                                        </span>
                                      </td>
                                    }
                                    {item["sip_returns_data"]["3y_percent"] &&
                                      !isNaN(parseInt(item["sip_returns_data"]["3y_percent"])) &&
                                      <td style={{ border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                        <span style={{
                                          margin: 4,
                                          color:
                                            item["sip_returns_data"][
                                              "3y_percent"
                                            ].substring(0, 1) === "-" ||
                                              ""
                                              ? "#f57362"
                                              : "#03b45e"
                                        }}>
                                          {item["sip_returns_data"]["3y_percent"]}
                                        </span>
                                      </td>
                                    }
                                    {item["sip_returns_data"]["5y_percent"] &&
                                      !isNaN(parseInt(item["sip_returns_data"]["5y_percent"])) &&
                                      <td style={{ border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                        <span style={{
                                          margin: 4,
                                          color:
                                            item["sip_returns_data"][
                                              "5y_percent"
                                            ].substring(0, 1) === "-" ||
                                              ""
                                              ? "#f57362"
                                              : "#03b45e"
                                        }}>
                                          {item["sip_returns_data"]["5y_percent"]}
                                        </span>
                                      </td>
                                    }
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        }
                        <br />
                        <table style={{ width: '100%' }}>
                          <tr>
                            <td style={{
                              backgroundColor:
                                item["risk"].toLowerCase().indexOf("low") >
                                  -1
                                  ? "#c0d5f0"
                                  : item["risk"]
                                    .toLowerCase()
                                    .indexOf("high") > -1
                                    ? "#f797af"
                                    : "#ede9cc",
                              height: 30, border: '1px solid #e2e0e4', textAlign: 'center'
                            }}>
                              <span >
                                {item["risk"]}
                              </span>
                            </td>
                            <td style={{
                              height: 30, border: '1px solid #e2e0e4', textAlign: 'center'
                            }}>
                              <span
                                style={{ fontSize: 15 }}
                                class="text-dark"
                              >
                                {`  `}
                                {item["one_month_percent"] ? (
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color:
                                        item["one_month_percent"].substring(
                                          0,
                                          1
                                        ) === "-" || ""
                                          ? "red"
                                          : "#03b45e",
                                    }}

                                  >
                                    {item["one_month_percent"]}%
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                              {`  `}
                              <span style={{ fontSize: 15 }}>1M</span>
                            </td>
                          </tr>
                        </table>

                      </ListGroup.Item>
                    ))}
                  </ListGroup>

                </div>{" "}

              </div>
              {!this.state.loading &&
                <Fab
                  style={{ bottom: 25, left: 245, transform: 'scale(0.82)' }}
                  // event="hover"
                  alwaysShowTitle={true}
                  mainButtonStyles={{
                    backgroundColor: "#5e72e4"
                  }}
                  icon={<i className="mdi mdi-filter" ></i>}
                  onClick={() => this.setState({ show_modal_filter: true })}
                >
                  {/* <Action
            style={{
              backgroundColor: "#8e44ad"
            }}
            onClick={() => console.log("First button clicked")}
          >
            <FontAwesomeIcon icon={faHome} />
          </Action>
          <Action onClick={() => console.log("Second button clicked")}>
            <FontAwesomeIcon icon={faFileCsv} />
          </Action> */}
                </Fab>
              }
            </div>

          </div>
          {/* </div> */}



          {this.state
            .show_modal_filter && (
              <Modal
                show={
                  this.state
                    .show_modal_filter
                }
                onHide={() =>
                  this.setState({ show_modal_filter: false })
                }
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header>
                  Search with your preferred option
                </Modal.Header>
                <Modal.Body>
                  <div >
                    {!this.state.loading && (
                      <div>
                        <Container>
                          <Row >
                            <Col>
                              <div class="form-group">
                                <h5
                                  class="text-gray-dark"
                                  style={{ fontSize: 14 }}
                                >
                                  Choose AMC
                                </h5>
                                <div>
                                  <Select
                                    isMulti
                                    value={filterOption["scheme_amc"]}
                                    onChange={this.handleChangeAmc}
                                    options={this.state.masterList["amc_list"]}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <p></p>
                          <Row >
                            <Col>
                              {" "}
                              <div class="form-group">
                                <h5
                                  class="text-gray-dark"
                                  style={{ fontSize: 14 }}
                                >
                                  Choose Category
                                </h5>

                                <Select
                                  isMulti
                                  value={filterOption["category"]}
                                  onChange={this.handleChangeCategory}
                                  options={this.state.masterList["amc_category"]}
                                  isSearchable={false}
                                />
                              </div>
                            </Col>
                          </Row>
                          <p></p>
                          <Row >
                            <Col>
                              <div class="form-group">
                                <h5
                                  class="text-gray-dark"
                                  style={{ fontSize: 14 }}
                                >
                                  Choose Risk
                                </h5>

                                <Select
                                  isMulti
                                  value={filterOption["risk"]}
                                  onChange={this.handleChangeRisk}
                                  options={this.state.masterList["amc_risk"]}
                                  isSearchable={false}
                                />
                              </div>
                            </Col>
                          </Row>
                          <p></p>
                          <Row >
                            <Col>
                              {" "}
                              <div class="form-group" style={{ marginTop: 10 }}>
                                <h5
                                  class="text-gray-dark"
                                  style={{ fontSize: 14 }}
                                >
                                  Choose Growth
                                </h5>
                                <div>
                                  <Select
                                    isMulti
                                    value={selectedOptionGrowth}
                                    onChange={this.handleChangeGrowth}
                                    options={
                                      this.state.masterList["growth_percentage"]
                                    }
                                    isSearchable={false}
                                  />
                                </div>
                              </div>
                            </Col>


                          </Row>
                          <p></p>
                          <Row >
                            <Col>
                              {" "}
                              <div class="form-group" style={{ marginTop: 10, marginBottom: 10 }}>
                                <h5
                                  class="text-gray-dark"
                                  style={{ fontSize: 13 }}
                                >
                                  Minimum Amount
                                </h5>

                                <Select
                                  style={{ fontFamily: 'sans-serif'}}
                                  isMulti
                                  value={filterOption["minimum_investment"]}
                                  onChange={this.handleChangeMinInvestment}
                                  options={
                                    this.state.masterList["minimum_investment"]
                                  }
                                  isSearchable={false}
                                // components={{ SingleValue, Option }}

                                
                                />
                              </div>
                            </Col>

                            <Col>
                              {" "}
                              <div
                                class="form-group"
                                style={{ marginBottom: 10, marginTop: 10 }}
                              >
                                <h5
                                  class="text-gray-dark"
                                  style={{ fontSize: 13 }}
                                >
                                  Investment Mode
                                </h5>

                                <Select
                                  isMulti
                                  value={filterOption["investment_method"]}
                                  onChange={this.handleChangeInvestmentMode}
                                  options={
                                    this.state.masterList["investment_method"]
                                  }
                                  isSearchable={false}
                                />
                              </div>
                            </Col>
                          </Row>

                          <div
                            class="form-group"
                            style={{ marginBottom: 10, marginTop: 10 }}
                          ></div>
                        </Container>
                      </div>
                    )}
                  </div>

                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    onClick={() =>
                      this.resetFilter()
                    }
                  >
                    Reset Filter
                  </Button>
                  {`      `}
                  <Button
                    
                    onClick={() =>
                      this.setState({ show_modal_filter: false })
                    }

                    style={{backgroundColor:"green", color:"#FAF9F6"}}
                  >
                  Apply
                  </Button>
                </Modal.Footer>
              </Modal>
            )}

        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  result: state.master.result,
  loading: state.master.loading,
});
export default connect(mapStateToProps, { listSchemes })(SchemeList);