import React, { } from 'react';
import { Link } from 'react-router-dom';
import { Button, Spinner, Modal } from 'react-bootstrap';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { validateOtp, verifyEmail } from '../../jsx/actions/auth';
import { Redirect } from 'react-router-dom';
import Popup from './popup';
import Background from '../../images/background/bg-14.jpg';
import OTPInput, { ResendOTP } from "otp-input-react";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";

class VerifyPin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            otp: '',
            pin: '',
            confirm_pin: '',
            data: '',
            token_id: ``,
            verify_pin: false,
            route_parameters: null,
            show_popup: false,
            popup_message: '',
            isloading: this.props.loading,
            errorMessage: ''
        };

    }

    componentDidMount() {

        if (this.props.location && this.props.location.state && this.props.location.state.otp_result) {
            let _state = this.props.location.state.otp_result['state_parameters'];
            _state['isloading'] = false;
            _state['route_parameters'] = {};

            this.setState(Object.assign(_state, {
                token_id: this.props.location.state.otp_result['token_id']
            }));

            window.history.replaceState(null, '');

        }
    }

    closePopup = () => {
        this.setState({
            show_popup: false
        })
    }

    onSetPin = (request_mode) => {

        this.setState({ isloading: true })
        let body_parameters = {
            login_email: this.state.email,
            login_role: "customer",
            login_request_from: "pin",
            login_request_mode: request_mode,
            login_hint: this.state.pin,
            is_external_bb_access_token: true,
            bb_access_token: this.state.token_id,
        }

        this.props.verifyEmail(body_parameters)
            .then(response => {

                alert(this.props.email_otp_result['messageText']);
                this.setState({ isloading: false });

                this.props.history.push('/' + this.props.email_otp_result['navigateScreen'] + '/');
            })
            .catch(err => {
                console.log(err);
                //alert(err)
            });

    };

    render() {

        // if(this.props.email_otp_result && this.props.email_otp_result['code'] === '000' && 
        //    this.props.email_otp_result['navigateScreen']) {

        //     return <Redirect to = {this.props.email_otp_result['navigateScreen']} />;
        // }

        return (
            <>
                <div style={{
                    background: `url(${Background})`,
                    backgroundRepeat: 'repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',

                }}>
                    <div className="authincation section-padding">
                        <div className="container h-100">
                            <div className="row justify-content-center h-100 align-items-center">
                                <div className="col-xl-5 col-md-6">
                                    <div className="mini-logo text-center my-1">
                                        <Link to={"./"}>
                                            <img src={require("./../../images/logo.png")} alt="" width="200px" />
                                        </Link>
                                    </div>
                                    <div >
                                        {this.state.isloading &&
                                            <Spinner animation="border" variant="secondary"
                                                style={{ position: "fixed", top: "50%", left: "50%" }} />
                                        }

                                        <div className="card-header justify-content-center">
                                            <div>
                                                <h4 className="text-center">
                                                    Setup Secutiry Pin    
                                                </h4>
                                                <p>(Set up your 4 digit pin for two factor security)</p>
                                            </div>
                                        </div>

                                        <div className="card-body">

                                            {!this.state.route_parameters &&
                                                <Card sx={{ backgroundColor: '#edf3f0', p: 2, borderRadius: 2 }}>
                                                    <CardContent  >
                                                        <Stack direction="row">
                                                            <Typography flex={1} whiteSpace="pre-line" >
                                                                Sorry the information you are looking for could not be found
                                                            </Typography>
                                                        </Stack>
                                                    </CardContent>
                                                    <CardContent>
                                                        <p>
                                                            <span>
                                                                <Link to={"/signin"} title="OrderList">
                                                                    Click here
                                                                </Link> to Sign-In again.
                                                            </span>
                                                        </p>
                                                    </CardContent>
                                                </Card>
                                            }

                                            {this.state.route_parameters &&
                                                <form action="#">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control text-center font-weight-bold"
                                                            value={this.state.email}
                                                            readOnly={true} />
                                                    </div>
                                                </form>
                                            }

                                        </div>


                                        {this.state.route_parameters &&
                                            <div className="card-body">

                                                <form action="#">
                                                    <div className="form-group">
                                                        <p /*className="text-center"*/>Enter New PIN</p>
                                                        <OTPInput
                                                            value={this.state.pin}
                                                            onChange={event => this.setState({ pin: event })}
                                                            autoFocus
                                                            OTPLength={4}
                                                            otpType="number"
                                                            disabled={false}
                                                            isInputNum={true}
                                                        />
                                                        {/* <input type="password" className="form-control text-center font-weight-bold"
                                                    value={this.state.pin}  
                                                    maxLength="4"
                                                    onChange={event => 
                                                        {
                                                            const targetValue = event.target.value.replace(/\s/g, '');
                                                            if ((targetValue.match(/^[0-9]+$/) 
                                                                || targetValue === "") && targetValue.toString().length <= 4) 
                                                            {
                                                                this.setState({ 
                                                                    pin: targetValue,
                                                                    errorMessage: ''
                                                                });
                                                            }
                                                            else{
                                                                this.setState({errorMessage:"Please enter valid pin number"})
                                                            }
                                                        }
                                                    } /> */}
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="text-danger">{this.state.errorMessage}</label>
                                                    </div>
                                                    <div className="form-group">
                                                        <p /*className="text-center"*/>Re-Enter New PIN</p>
                                                        <OTPInput
                                                            value={this.state.confirm_pin}
                                                            onChange={event => this.setState({ confirm_pin: event })}
                                                            OTPLength={4}
                                                            otpType="number"
                                                            disabled={false}
                                                            isInputSecure={true}
                                                            isInputNum={true}
                                                        />
                                                        {/* <input type="text" className="form-control text-center font-weight-bold"
                                                    value={this.state.confirm_pin}  
                                                    maxLength="4"
                                                    onChange={event => 
                                                        {
                                                            const targetValue = event.target.value.replace(/\s/g, '');
                                                            if ((targetValue.match(/^[0-9]+$/) 
                                                                || targetValue === "") && targetValue.toString().length <= 4) 
                                                            {
                                                                this.setState({ 
                                                                    confirm_pin: targetValue
                                                                });
                                                            }  
                                                            else{
                                                                // this.setState({errorMessage:"Please enter valid pin number"})
                                                            }                      
                                                        }
                                                    } /> */}
                                                    </div>
                                                    {
                                                        this.state.confirm_pin.trim().length > 0 &&
                                                        this.state.pin.trim() !== this.state.confirm_pin.trim() &&
                                                        <div className="form-group">
                                                            <label className="text-danger">
                                                                Both of the PINs does not match.
                                                            </label>
                                                        </div>
                                                    }
                                                    <div className="text-center" style={{ marginTop: 20 }}>
                                                        <Button
                                                            className="btn btn-success btn-block"
                                                            variant="success"
                                                            disabled={
                                                                this.state.isloading ||
                                                                this.state.pin.length < 4 || this.state.pin.length > 6 ||
                                                                (this.state.pin.trim() !== this.state.confirm_pin.trim())
                                                            }
                                                            onClick={() => this.onSetPin("verifypin")}

                                                        >Submit</Button>
                                                    </div>
                                                </form>

                                            </div>
                                        }


                                    </div>
                                    {this.state.show_popup && (
                                        <Modal show={this.state.show_popup} onHide={() => this.closePopup()}>
                                            <Modal.Header>

                                            </Modal.Header>
                                            <Modal.Body>
                                                <p>{this.state.popup_message}</p>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="primary" onClick={() => this.closePopup()}>
                                                    Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    )}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

VerifyPin.propTypes = {
    validateOtp: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,

};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    email_otp_result: state.auth.email_otp_result,
    login_otp_result: state.auth.login_otp_result
});
export default connect(mapStateToProps, { verifyEmail, validateOtp })(VerifyPin);
