import axios from 'axios';
import store from '../../store';
import { LOGOUT } from '../actions/types';
import { AppConstants } from '../constants/constants';
import { History } from 'react-router';

const api = axios.create({
  baseURL: AppConstants.API_BASE_URL,  //.STAGING_BASE_URL OR .PRODUCTION_BASE_URL
  headers: {
    'content-type': 'application/json',
    // 'Access-Control-Allow-Origin': '*'
  }
  /*
  headers: {
    'content-type': 'application/x-www-form-urlencoded;',
    'Access-Control-Allow-Origin': '*'
  }
  */
});

/**
 intercept any error responses from the api
 and check if the token is no longer valid.
 ie. Token has expired or user is no longer
 authenticated.
 logout the user if the token has expired
**/

api.interceptors.request.use(function (config) {

  //api.defaults.headers.common['bb-access-token'] = localStorage.);
  // console.log(config);
  
  let _body_parameters = (typeof config['data'] === 'string' ? 
    JSON.parse(config['data']) : config['data']);

  // console.log(_body_parameters)
  if(_body_parameters && _body_parameters['is_external_bb_access_token'] 
      && _body_parameters['bb_access_token']) 
  {
      config.headers['bb-access-token'] = _body_parameters['bb_access_token'];
  }
  else {
    config.headers['bb-access-token'] = localStorage.getItem('token');
  }
  
  _body_parameters = null;
  return config;

}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

api.interceptors.response.use(function (response) {

  // console.log(response);
  
  if (response['data']['erase_token_id'] || response['data']['navigateScreen'] === 'signin' ||
      ['003', '004'].indexOf(response['data']['code']) > -1 ||
      response['data']['navigateScreen'] === './signin' ||
      response['data']['token_id'] === 'g6Eg1l0aId3yzoosDaSfxeblOy2lXoUgXgFeHdnoYuUtIDIeIQ') 
  {

    localStorage.removeItem('token');
    localStorage.removeItem('profileData'); 
    if( (window.location.href || ``).toLowerCase().indexOf('/signin') === -1)
       window.location.replace(window.location.origin + '/signin/loggedout'); // window.location = '/signin/loggedout';

  }
  else {

    if (response['data'] && response['data']['code'] &&
      ['003', '004'].indexOf(response['data']['code']) > -1) 
      {

        if( (window.location.href || ``).toLowerCase().indexOf('/signin') === -1) 
        {
          localStorage.removeItem('token'); 
          localStorage.removeItem('profileData'); 
          localStorage.removeItem('masterList'); 
          window.location.replace(window.location.origin + '/signin/loggedout'); //window.location = '/signin/loggedout';
        }

      }
      else {

        if (response['data'] && response['data']['token_id']) {
            if(response['data']['is_authenticated'/*'is_ignore_localstorage'*/])          
              localStorage.setItem('token', response['data']['token_id']);
            else
              sessionStorage.setItem('token', response['data']['token_id']);
        }

      }

  }

  return response || { data: { code: '011', messageText: 'internal error' } };

}, function (error) {

  // Any status codes that falls outside the range of 2xx cause this function to trigger
  store.dispatch({ type: LOGOUT });

  return Promise.reject(error);

});

/*
api.interceptors.response.use(
  res => res,
  err => {
    console.log(123 + err)
    if (err || (err.response && err.response.status === 401)) {
      store.dispatch({ type: LOGOUT });
    }
    return Promise.reject(err);
  }
);
*/



export default api;
