import React, { Component } from 'react'
import PersonalDetails from '../personaldetails/personaldetails'
import IncomeDetails from '../incomedetails/incomedetails';
import NomineeDetails from '../personaldetails/nomineedetails';
import Nominee2factorAuth from '../personaldetails/nominee2factorAuth';

import Documentsupload from '../documentsupload/documentsupload';
import EsignkycVerification from '../esignkyc/esignkycVerification'

import {validate, validateincome, validatenominee, imageverification} from '../../utils/validate';
import ImageVerification from '../personaldetails/imageVerification';
import { connect } from "react-redux";
import { loadStageInfo, updatePersonalInfo, verifyIdProof } from '../../actions/verify';
import { twoFactorNomineeValidation, twoFactorAuthentication } from "../../actions/orders";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { Camera } from "react-camera-pro";
import Webcam from "react-webcam";

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "environment",
  screenshotQuality: 1,
};

class PersonalVerification extends Component {

  constructor(props) {

    super(props);
    this.state = {
      step: 1,
      pin : "",
      email:'',
      first_name : "",
      last_name : "",
      do_birth : "",
      gender : "",
      marital_status : "",
      age : 1,
      occupation: "",
      source_of_wealth: "",
      annual_income: "",
      errors:'',
      errorincome:'',
      errornominee:'',
      errorimage : '',
      is_income_outside_india: false,
      is_political_india: false,
      is_political_foreign: false,
      address_information: { 
        address: "",
        locality_or_post_office: "",
        district_or_city: "",
        city: '',
        state: "",
        statecode: "",
        pincode: ""
      },
      err_address_information: false,
      nominee_information : {
        name: '',
        relationship: '',
        id_proof: '',
        id_information: {},
        poi: '',
        attachment_id: '',
        attachment_list: [],
        guardian_information: {},
        is_minor: false
      },

      is_stage_verified: false,
      is_verify_enabled: false,
      is_confirm_chk_enabled: false,
      is_confirm_chk_checked: false,
      file_nominee: null,
      file_photograph: null,
      src_file_nominee: null,
      src_file_photograph: null,
      file_photograph_1: null,
      file_nominee_1: null,
      src_file_nominee_1: null,
      src_file_photograph_1: null,
      file_nominee_guardian: null,
      src_file_nominee_guardian: null,
      master_list: {},
      file_nominee_show_popup: false,
      file_nominee_popup_messagetext: 'Upload file format should be of PNG, JPEG, JPG only',
      loading: false,

      show_popup_aadhaar_confirmation: false,

      is_bse_nominee_verified: false,
      two_factor_authentication: {
        mobile_otp: '',
        email_otp: '',
        twofactor_token_id: ''
      },

      cam: null,
      webcam: null
      
    }

  }

  componentDidMount()
  {

    window.addEventListener("scroll", () => {

      if (document.getElementById("navbar-personalinfo-container") &&
        document.getElementById("navbar") &&
        document.getElementById("navbar").classList) {
        const topBorder = document
          .getElementById("navbar-personalinfo-container")
          .getBoundingClientRect().top;

        topBorder >= 0
          ? document.getElementById("navbar").classList.remove("fixed-personalinfo")
          : document.getElementById("navbar").classList.add("fixed-personalinfo");
      }

    });

    this.onLoadStageInfo();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', null);
  }

  // go back to previous step
  prevStep = () => {
    window.scrollTo(0, 0);
    const { step } = this.state;
    this.setState({ step: step - 1 });
  }

  // proceed to the next step
  nextStep = () => {

    const { step } = this.state;
    let validationerrors = validate(this.state);
    let incomevalidate = validateincome(this.state);
    let nomineevalidate = validatenominee(this.state);

    window.scrollTo(0, 0);

    if(step === 1)
    {
    
      if(Object.keys(validationerrors).length > 0) {
        this.setState({errors: validationerrors});
      }
      else {

        let _body_parameters = this.state['address_information'];
        _body_parameters['update_attribute'] = 'address';
        _body_parameters['marital_status'] = this.state.marital_status;
        if(this.state.login_stage === 'personal')
           this.updatePersonalInfo(_body_parameters, step);

      }

    }   
    else if((step) === 2) {
      
      if(Object.keys(incomevalidate).length > 0){
        this.setState({ errorincome: incomevalidate });
      }
      else {

        let _body_parameters = this.state;
        _body_parameters['update_attribute'] = 'income';
        
        if(this.state.login_stage === 'personal')
           this.updatePersonalInfo(_body_parameters, step);

      }
    }
    else if((step) === 3) {
      
      if(Object.keys(nomineevalidate).length > 0)
        this.setState({ errornominee: nomineevalidate });
      else
      {

        let _body_parameters = this.state;
        _body_parameters['update_attribute'] = 'nominee';
        if(this.state.login_stage === 'personal')
           this.updatePersonalInfo(_body_parameters, step);

      }
    }
    else if((step) === 4) {
      
      /*
      if(Object.keys(nomineevalidate).length > 0)
        this.setState({ errornominee: nomineevalidate });
      else
      {*/

        let _body_parameters = this.state;
        _body_parameters['update_attribute'] = 'two factor authentication nominee';
        if(this.state.login_stage === 'personal')
           this.validate2FactorAuthentication(_body_parameters, step);

      /*}*/

    }    
    else
      this.setState({ step: step + 1 });

  }

  // Handle fields change
  handleChange = input => e => {
    let errorvalues = validate(input);
    
    this.setState({ [input]:  e.target.value ,errors:errorvalues   });
  }

  handleInputChange = (attribute, input, pattern) => e => {
  
    // validate(input);
    // this.setState({ nominee_information:  {...this.state.nominee_information,[input]:e.target.value}  });
      if(pattern) {    
        
        const targetValue = e.target.value; //.replace(/\s/g, '');
        // alert(targetValue.match(/^[0-9a-zA-Z]+$/))
        const re = /^[A-Za-z]+$/;
        if (targetValue === "" || re.test(targetValue))
          this.setState({ [attribute]:  {...this.state[attribute], [input]: targetValue}  });
        
      }
      else
        this.setState({ [attribute]:  {...this.state[attribute], [input]:e.target.value}  });

  }

  handleObjectInputChange = (attribute, input, pattern) => e => {
    
    this.setState({loading:true});

    if(pattern) {    
      
      const targetValue = e.target.value; //.replace(/\s/g, '');
      this.setState({ ['err_' + attribute]:  !pattern.test(targetValue)  });
      if(pattern.test(e.target.value))
        this.setState({ 
          loading: false, 
          [attribute]: {...this.state[attribute], [input]:e.target.value}  
        });
    }
    else
      this.setState({ 
        loading: false,
        [attribute]: {...this.state[attribute], [input]:e.target.value}  
      });
      
  }

  handleSelectInputChange = (input, attribute)  => e =>{
    
    let errorvalues = (attribute === 'income' ? this.state.errorincome : 
    (attribute === 'nominee' ? this.state.errornominee: this.state.errors) );
       
    if(e.value && e.value.length > 1)
      delete errorvalues[(attribute === 'nominee' ? 'nominee_' : '') + input];
      
    this.setState({ 
      [attribute]:  attribute ? {...this.state[attribute], [input]: e.value } : e.value,
      errornominee: errorvalues  
    });
    
  }

  handleRadioChange = input => e => {
      // validate(input);
      this.setState({ [input]:  this.str2bool(e.target.value)   });

  }

  handleSelectChange = (attribute, input) => e => {
      
      let errorvalues = (attribute === 'income' ? this.state.errorincome : 
        (attribute === 'nominee' ? this.state.errornominee: this.state.errors) ); 
      
      if(e.value && e.value.length > 1)
        delete errorvalues[input];

      this.setState({ 
        [input]:  e.value,
        errorincome: errorvalues    
      });
  }

  handleDropdownChange = (attribute, input, pattern) => e => {
    
    // this.setState({loading:true});

    const targetValue = e.value; //.replace(/\s/g, '');

    if(pattern) {
      this.setState({ ['err_' + attribute]:  !pattern.test(targetValue)  });
      if(pattern.test(targetValue))
        this.setState({ 
          // loading: false, 
          [attribute]: {...this.state[attribute], [input]:targetValue}  
        });
    }
    else
      this.setState({ 
        // loading: false,
        [attribute]: {...this.state[attribute], [input]:targetValue}  
      });
      
  }

  handleInputValidation = (attribute, list_input) => {

      let _validate = true;
      list_input.forEach(el => {
          if(_validate)
            _validate = !el['pattern'].test(el['value']);
      })
      this.setState({ ['err_' + attribute]:  !_validate  });
    
  }

  handle2FactorChange = (input, attribute)  => e => {
    
    if(e)
      this.setState({ 
        [attribute]:  {...this.state[attribute], [input]: e }
      });
    else
      this.setState({ [input]: e });

  }

  str2bool = (value) => {

    if (value && typeof value === "string") {
        if (value.toLowerCase() === "true") return true;
        if (value.toLowerCase() === "false") return false;
        else
            return value;
    }
    return value;
  }

  onLoadStageInfo = () => {

    this.setState({loading:true});

    this.props.loadStageInfo({ stage_name: 'personal'}).then(response => {

        if(this.props.personal_result['code'] !== '000') {
            this.setState({loading:false})
            alert(this.props.personal_result['messageText']);}
        else {

          if(this.props.personal_result['previous_stage_verified']) {

            let _step = 1;

            if(sessionStorage.getItem('personal_info_step') && 
              !isNaN(sessionStorage.getItem('personal_info_step')) ) {
                _step = parseInt(sessionStorage.getItem('personal_info_step')) ;
            }

            if(_step === 4) {
              if(this.props.personal_result['id_information'] 
                && this.props.personal_result['id_information']['nominee_information'] 
                && this.props.personal_result['id_information']['nominee_information']['attachment_id'].length < 10) {
                  _step = 3;
                }
            }
            
            if(this.props.personal_result['personal_stage_verified'])
              _step = 0;

            this.setState(this.props.personal_result['id_information']);
            this.setState({ 
              master_list: this.props.personal_result['master_list'],
              loading: false, 
              step: _step
            });

          }
          else
            this.setState({ 
              loading: false, 
              step: -1
            });
  
        }

    })
    .catch(err => {
      alert(err)
    });

    
  };

  handleFileChange = (selectedFile, id_type, category, attribute) => (event) => {
    
    if(event.target.files[0] && (event.target.files[0].type === 'image/png' || 
      event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/jpg') ) {
      
      const uploaded_file = { 
          [selectedFile]: event.target.files[0], 
          ['src_' + selectedFile]:  URL.createObjectURL(event.target.files[0])
        };

      let isValid = true;

      if(id_type === 'aadhaar' && category === 'nominee') {

        if(selectedFile === 'file_nominee') {
          isValid = (this.state.file_nominee_1 !== null);
        }
        else if(selectedFile === 'file_nominee_1') {
          isValid = (this.state.file_nominee !== null && event.target.files[0] !== null);
        }
        
        window.scrollTo(0, document.body.scrollHeight + 100);

      }

      if(isValid)
      {

        this.setState({loading:true});

        let formData = new FormData();
        if(id_type === 'aadhaar' && category === 'nominee') {
           formData.append("upload_file", this.state.file_nominee);
           formData.append("upload_file",  this.state.file_nominee_1 || event.target.files[0]);
          //  if(selectedFile === `file_nominee`)
          //    formData.append("id_direction", "front");
          //  else if(selectedFile === `file_nominee`)
          //    formData.append("id_direction", "back");
        }
        else
          formData.append("upload_file", event.target.files[0]);
          
        formData.append("id_type", id_type);
        formData.append("folder_name", id_type);
        formData.append("attachment_category", category /*'nominee'*/);
        formData.append("is_update_collection", attribute['is_update']);
        formData.append("attribute_name", attribute['name']);
        formData.append("source_data", JSON.stringify(attribute['data'] || {}));

        this.props.verifyIdProof(formData, { attachment_category : category }, response => {

          this.setState({loading:false});

          if(response['code'] === '000') 
          {   
            // this.setState(uploaded_file);
            if(category === 'nominee') 
            {

              this.setState(Object.assign(uploaded_file, response['id_information']));
              /*
                this.setState({ 
                  nominee_information:  {
                    ...this.state.nominee_information, id_information: response['id_information'],
                    name: response['id_information']['name'],
                    is_minor: response['id_information']['nominee_is_minor'],
                    poi: response['id_information']['id_type'],
                  }  
                });
              */
            }
          }
          else{
            alert(response['messageText']);
          }
        });

        // .catch(err => {
        //   alert(err)
        // });

      }
      else {
        if(id_type === 'aadhaar' && category === 'nominee')
           this.setState(uploaded_file);
      }

    }
    else {

      if(event.target.files.length > 0)
        this.setState({ 
          [selectedFile + '_show_popup']:  true
        });

    }

  }

  handleAadhaarFileChange = (selectedFile, id_type, category, attribute) => (event) => {
    
    if(event.target.files[0] && (event.target.files[0].type === 'image/png' || 
      event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/jpg') ) {
      
      const uploaded_file = { 
          [selectedFile]: event.target.files[0], 
          ['src_' + selectedFile]:  URL.createObjectURL(event.target.files[0])
        };

      this.setState(uploaded_file);

    }
    else {

      if(event.target.files.length > 0)
        this.setState({ 
          [selectedFile + '_show_popup']:  true
        });

    }

  }

  handlePhotographChange = (selectedFile, imageSrc) => (event) => {
    
    this.setState({ 
        [selectedFile]: imageSrc
      });

  }

  handlePopupChange = (state_name) => (event) => {
      
      this.setState({ 
        [state_name]:  false
      });

  }

  handleAadhaarPopupChange = (state_name, boolValue) => (event) => {
      
    this.setState({ 
      [state_name]:  boolValue
    });

  }

  detectFileChange = (selectedFile, id_type, category, attribute) => (event) => {
    // alert(this.state[_attribute])
    // if(this.state[_attribute])
    // this.state[_attribute] = null;
    // this.handleFileChange((selectedFile, id_type, category, attribute));
  }

  updatePersonalInfo = (body_parameters, step) => {
    
    this.setState({ loading:true });

    this.props.updatePersonalInfo(body_parameters, response => {

        if(response['code'] !== '000'){
            alert(response['messageText']);
            this.setState({loading:false})
        }
        else {
          
          sessionStorage.setItem('personal_info_step', step /*+ (
              body_parameters['update_attribute'] === 'two factor authentication nominee' && this.state.is_bse_nominee_verified ? 
              2 : 0 )*/
          );
          this.setState({ 
            loading: false, 
            two_factor_authentication: {
              ...this.state.two_factor_authentication, 
              mobile_otp: '',
              email_otp: '',
              twofactor_token_id: response['twofactor_id']
            },
            step: step + 1
            /*(
              body_parameters['update_attribute'] === 'nominee' && this.state.is_bse_nominee_verified ? 
              2 : 1
            ) 
            */
          });

          // if(step === 2)
          //    this.setState(response['id_information']);
        }

    })
    
  };

  validate2FactorAuthentication = (body_parameters, step) => {

    this.setState({ loading: true });

    this.props
      .twoFactorNomineeValidation({
          two_factor_authentication: body_parameters['two_factor_authentication'],
          update_attribute: body_parameters['update_attribute']
      })
      .then((response) => {

        if (this.props.order_result["code"] !== "000") {
          this.setState({ loading: false });
          alert(this.props.order_result["messageText"]);
        }
        else {

          let _result = this.props.order_result; //["result"];

          if (_result['result']) {
            
            this.setState({ 
              loading: false, 
              is_bse_nominee_verified: _result['result']['is_bse_nominee_verified'],
              step: step + 1 
            });

            sessionStorage.setItem('personal_info_step', step);

          }

        }
      })
      .catch((err) => {
        alert(err);
      });

  };

  on2FactorAuthentication = () => {

    this.setState({ isloading: true });
    
    this.props 
      .twoFactorAuthentication({
        client_info: {
          two_factor_authentication: this.state.two_factor_authentication,
          request_action: 'nominee update'
        },
      })
    .then((response) => {

      if (this.props.order_result["code"] !== "000") {
        this.setState({ isloading: false });
        alert(this.props.order_result["messageText"]);
      }
      else {

        let _result = this.props.order_result; //["result"];

        if (_result['result'] && _result['twofactor_id']) {
          this.setState({
            isloading: false,
            two_factor_authentication: {
              ...this.state.two_factor_authentication, 
              mobile_otp: '',
              email_otp: '',
              twofactor_token_id: _result['twofactor_id']
            }
          });

        }

      }
    })
    .catch((err) => {
      alert(err);
    });

  };

  setRef = (webcam) => (event) => {
    // this.webcam = webcam;
    this.setState({webcam : webcam});
  };

  setCameraRef = (cam) => {
    // this.cam = cam;
    this.setState({cam : cam});
  };

  capture = () => (event) => {
    const imageSrc = this.webcam.getScreenshot();
    let file_photograph = this.dataURLtoFile(
      imageSrc,
      "photograph" /*uuidv4()*/
    );

    this.setState({
      file_photograph: file_photograph,
      src_file_photograph: imageSrc,
    });
  };

  Recapture = (file_photograph_attribute) => {
    this.setState({ src_file_photograph: null });
  };

  captureCam = () => {
    
    const imageSrc = this.state.cam.takePhoto()
    let file_photograph = this.dataURLtoFile(
      imageSrc,
      "photograph" /*uuidv4()*/
    );

    this.setState({
      file_photograph: file_photograph,
      src_file_photograph: imageSrc,
    });
  };

  recaptureCam = () => {
    this.setState({ src_file_photograph: null });
  };

  dataURLtoFile(dataurl, filename) {
    this.setState({ isloading: true });
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    this.setState({
      file_photograph_tiff: new File([u8arr], filename + "_photograph.tiff", {
        type: mime.split("/")[0] + "/tiff",
      }),
    });
    this.setState({ isloading: false });
    return new File([u8arr], filename + "." + mime.split("/")[1], {
      type: mime,
    });
  }

  handleConfirmCheck = (event) => {
    this.setState({
      is_confirm_chk_checked: !this.state.is_confirm_chk_checked,
    });
  };

  /*
  verifyAccount = () => {

    this.setState({ isloading: true });
    let formData = new FormData();

    formData.append("upload_facephoto", this.state.file_photograph);
    formData.append("upload_facephoto", this.state.file_photograph_tiff);

    let parameters = this.props.values;

    let _nomineeUpload =
      !parameters["nominee_information"] ||
      !parameters["nominee_information"]["attachment_id"] ||
      parameters["nominee_information"]["attachment_id"].length < 4;

    for (var key of Object.keys(parameters)) {
      if (
        (!_nomineeUpload && key === "file_nominee") ||
        key === "file_photograph"
      ) {
        if (parameters[key]) formData.append("upload_file", parameters[key]);
      } else {
        if (typeof parameters[key] === "object")
          formData.append(key, JSON.stringify(parameters[key]));
        else formData.append(key, parameters[key]);
      }
    }

    formData.append("id_type", "personal");
    formData.append("folder_name", "personal");
    formData.append("attachment_category", "personal");
    formData.append("verify_stage", "personal");

    // this.props.verifyAccount(formData, (response) => {
    this.props.verifyAccount(formData)
      .then(response => {

        this.setState({ isloading: false });
        let _personal_result = this.props.personal_result;

        if (_personal_result['code'] === '000') {

          if (
            _personal_result && _personal_result["personal_stage_verified"]
          ) {

            this.props.history.push({
              pathname: "/" + _personal_result["navigateScreen"]
            });
            // this.setState({ step: 0})

          }
          else {
            alert(_personal_result['messageText']);
          }

        }
        else
          alert(_personal_result['messageText']);
      })
      .catch(err => {
        alert(err);
      });

  };
  */
 
  render() {

    // if (this.props.personal_result && this.props.personal_result['personal_stage_verified']) {
      
    //   this.props.history.push(
    //     { 
    //       pathname: '/' + this.props.personal_result['navigateScreen'] + '/'
    //     }
    //   );

    // }

    const { 
      step,
      pin ,
      first_name ,
      last_name ,
      do_birth ,
      gender ,
      marital_status ,
      email,
      occupation,
      source_of_wealth,
      annual_income,
      is_income_outside_india,
      is_political_india,
      is_political_foreign,
      address,
      locality_or_post_office,
      district_or_city,
      city,
      state,
      pincode,
      address_information,
      err_address_information,
      nominee_information,

      file_photograph,
      file_nominee,
      file_nominee_guardian,

      src_file_photograph,
      src_file_nominee,
      src_file_nominee_guardian,

      file_photograph_1,
      file_nominee_1,
      
      src_file_nominee_1,
      src_file_photograph_1,

      master_list,
      file_nominee_show_popup,
      file_nominee_popup_messagetext,

      is_bse_nominee_verified,
      two_factor_authentication,

    } = this.state;

    const values = {
      pin ,
      first_name ,
      last_name ,
      do_birth ,
      gender ,
      marital_status ,
      email,
      occupation,
      source_of_wealth,
      annual_income,
      is_income_outside_india,
      is_political_india,
      is_political_foreign,
      address,
      locality_or_post_office,
      district_or_city,
      city,
      state,
      pincode,
      address_information,
      err_address_information,
      nominee_information,
      file_photograph,
      file_nominee,
      src_file_nominee,
      src_file_photograph,
      file_photograph_1,
      file_nominee_1,
      src_file_nominee_1,
      src_file_photograph_1,
      file_nominee_guardian,
      src_file_nominee_guardian,
      master_list,
      file_nominee_show_popup,
      file_nominee_popup_messagetext,

      is_bse_nominee_verified,
      two_factor_authentication,

    }
    
    switch(step) {
      case -1:
        return (
          <>
          <Header1 />
          <Sidebar />
          <div className="authincation section-padding" style={{ marginTop: 50 }}>
              <div className="content-body">
                      <Card sx={{ backgroundColor: '#edf3f0', p: 2, borderRadius: 2 }}>
                          <CardContent  >
                              <Stack direction="row">
                                  <Typography flex={1} whiteSpace="pre-line" >
                                      Sorry the information you are looking for could not be found
                                  </Typography>
                              </Stack>
                          </CardContent>
                      </Card>
                </div>
          </div>
          </>
        )

      case 0:
        return (
          <EsignkycVerification />
        )
      case 1: 
        return (
          <PersonalDetails 
            nextStep={ this.nextStep }
            handleChange={ this.handleChange }
            handleObjectInputChange = {this.handleObjectInputChange }
            handleRadioChange={ this.handleRadioChange }
            handleInputChange={ this.handleInputChange }
            handleInputValidation={this.handleInputValidation}
            handleDropdownChange = {this.handleDropdownChange}
            values={ values }
            errors = {this.state.errors}
            loading={this.state.loading}
          />
        )
      case 2: 
        return (
          <IncomeDetails 
            prevStep={ this.prevStep }
            nextStep={ this.nextStep }
            handleChange={ this.handleChange }
            handleSelectChange = {this.handleSelectChange}
            handleRadioChange={ this.handleRadioChange }
            values={ values }
            errors = {this.state.errorincome}
            loading={this.state.loading}
          />
        )
      case 3: 
        return (
          <NomineeDetails 
          prevStep={ this.prevStep }
          nextStep={ this.nextStep }
          handleChange={ this.handleChange }
          handleSelectChange = {this.handleSelectChange}
          handleRadioChange={ this.handleRadioChange }
          handleInputChange={ this.handleInputChange }
          handleObjectInputChange = {this.handleObjectInputChange}
          handleSelectInputChange={ this.handleSelectInputChange }
          handleFileChange = {this.handleFileChange}
          handleAadhaarFileChange = {this.handleAadhaarFileChange}
          handlePopupChange = {this.handlePopupChange}
          handleAadhaarPopupChange = {this.handleAadhaarPopupChange}
          detectFileChange = {this.detectFileChange}
          values={ values }
          errors = {this.state.errornominee}
          loading={this.state.loading}
          />
        )
      case 4: 
        return (
          <Nominee2factorAuth 
            prevStep={ this.prevStep }
            nextStep={ this.nextStep }
            on2FactorAuthentication={ this.on2FactorAuthentication }
            values={ values }
            handle2FactorChange = {this.handle2FactorChange}
            errors = {this.state.errorimage}
            loading={this.state.loading}
          />
        )
      case 5: 
          return (
            <ImageVerification 
              prevStep={ this.prevStep }
              nextStep={ this.nextStep }
              values={ values }
              errors = {this.state.errorimage}
              loading={this.state.loading}
              _props={this.props}
              /*
              setRef={this.setRef}
              setCameraRef={this.setCameraRef}
              captureCam={this.captureCam}
              recaptureCam={this.recaptureCam}
              handleConfirmCheck={this.handleConfirmCheck}
              verifyAccount={this.verifyAccount}
              */
            />
          )
      /*
      case 6: 
          return (
            <Documentsupload 
              prevStep={ this.prevStep }
              nextStep={ this.nextStep }
              values={ values }
              loading={this.state.loading}
            />
        )
      */
      default: 
          // do nothing
    }


  }

}


const mapStateToProps = state => ({
  personal_result: state.verify.personal_result,
  order_result: state.order.order_result
});

export default connect(mapStateToProps, 
  {  
    loadStageInfo, updatePersonalInfo, verifyIdProof,
    twoFactorNomineeValidation, twoFactorAuthentication 
  })
  ( PersonalVerification );
