import React from "react";
import { connect } from "react-redux";
import {
  createSipOrder,
  uploadMandate,
  registerMandate,
  loadBankAccountList,
  loadNachMandateForm,
  loadNomineeList,
  refreshMandateStatus,
  cancelMandate,
  twoFactorAuthentication,
  downloadNominee,
} from "../../actions/orders";
import { eNachMandate } from "../../actions/auth";
import { downloadFile } from "../../actions/general";

import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import { PDFReader } from "reactjs-pdf-reader";
import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
// import fileDownload from 'js-file-download';
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";

import {
  Table,
  Collapse,
  Modal,
  Form,
  Row,
  Col,
  Button,
  ListGroup,
  Spinner,
} from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import OTPInput, { ResendOTP } from "otp-input-react";

class SipCheckout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_order: {},
      sip_order: {},
      list_bank_account: [],
      list_mandate_initial: [],
      list_mandate: [],
      list_pending_mandate: [],
      list_mandate_debit: [],
      src_nach_form: "",
      nach_form_download_url: ``,
      selected_mandate_id: "",
      is_nach_form_download_url_new_window: false,
      list_nach_form: [],

      is_enach_mandate: false,

      list_nach_authorize_type: [],

      is_mandate_selected: false,
      is_mandate_registered: false,
      is_mandate_uploaded: false,
      is_register_mandate_allowed: true,
      is_debit_max_limit: false,
      mandate_nach_list: [],

      mandate_authorize_type: `ENACH`,
      mandate_amount: 25000,

      mandate_id: "",
      mandate_date_validation: {
        message_text: "",
      },
      is_first_order_today: false,
      loading: false,
      is_nach: true,
      nach_option: "existing",
      nach_option_new_processed: false,
      nach_option_new_processed_message_text: "",
      sip_information_text: "You need to make payment physically on ",
      render_pdf: null,
      nachmandateFile: null,
      src_nachmandateFile: null,
      byte_nachmandateFile: null,

      show_upload_popup: false,
      modal_upload_mandate: {},
      modal_nachmandateFile: null,
      src_modal_nachmandateFile: null,
      modal_nachmandate_download_url: null,

      /****** NOMINEE OPTIONS *******/
      list_nominee: [],
      nominee_option: "existing",
      existing_nominee_id: "",
      existing_nominee_selected: false,
      is_no_nominee_registered: false,
      no_nominee_form: {},
      nonomineeformFile: null,
      src_nonomineeformFile: null,
      byte_nonomineeformFile: null,
      nominee_form_download_url: ``,
      no_nominee_form_download_url: ``,
      is_download_url_new_window: false,
      no_nominee_form_download_timeout: 4500,
      client_code: "",

      initate_file_download_url: ``,
      /****** NOMINEE OPTIONS *******/

      show_popup: false,
      popup_messageText: "",

      show_confirm_popup: false,
      confirm_popup_messageText: ``,
      confirm_popup_request_mode: ``,
      confirm_popup_mandate_info: {},

      invest_more_amount: 0.0,
      redeemable_amount: 0.0,
      is_redeem_full_checked: false,
      is_stage_verified: false,
      is_verify_enabled: false,
      is_verified_editable: false,
      esignkyc_information: {},
      esignkyc_attachment_list: {},
      is_esign_verified: false,
      is_esign_button_disabled: false,
      isloading: false,

      wizard_step: 1,
      show_previous_button: false,
      show_next_button: false,

      is_otp_enabled: false,
      two_factor_authentication: {
        mobile_otp: "",
        email_otp: "",
        twofactor_token_id: "",
      },
      show_modal_confirm_dialog: false,
      modal_confirm_dialog_messagetext: `Are you sure to confirm your sip order ?`,
    };
  }

  componentDidMount() {
    console.log(this.state.list_mandate);

    window.addEventListener("scroll", () => {
      if (
        document.getElementById("navbar-sipcheckout-container") &&
        document.getElementById("navbar") &&
        document.getElementById("navbar").classList
      ) {
        const topBorder = document
          .getElementById("navbar-sipcheckout-container")
          .getBoundingClientRect().top;

        topBorder >= 0
          ? document.getElementById("navbar").classList.remove("fixed-chart")
          : document.getElementById("navbar").classList.add("fixed-chart");
      }
    });

    window.scrollTo(0, 0);

    this.setState(this.props.location.state);
    /*
    this.setState({
      selected_order: this.props.location.state.selected_order,
      sip_order: this.props.location.state.sip_order,
      isloading: true
    });
    */
    this.onLoadBankAccountList();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", null);
  }

  onLoadBankAccountList = () => {
    this.props
      .loadBankAccountList({
        sip_date: this.props.location.state.sip_order["sip_date"],
        scheme_name: this.props.location.state.sip_order["scheme_name"],
        scheme_code: this.props.location.state.sip_order["scheme_code"],
      })
      .then((response) => {
        this.setState({ isloading: false });
        if (this.props.order_result["code"] !== "000") {
          alert(this.props.order_result["messageText"]);
        } else {
          let _result = this.props.order_result["result"];

          if (_result) {
            let _customer_pending_mandate_list =
              _result["customer_pending_mandate_list"] || [];
            let _nach_form =
              _customer_pending_mandate_list.length > 0
                ? _result["mandate_nach_list"].filter((el) => {
                    return (
                      el["mandate_id"] ===
                      _customer_pending_mandate_list[0]["mandate_id"]
                    );
                  })
                : [];

            let setStateObject = {
              isloading: false,
              list_bank_account: _result["customer_bank_list"],
              list_mandate_initial: _result["customer_mandate_list"],
              list_mandate: _result["customer_mandate_list"],
              list_pending_mandate: _customer_pending_mandate_list,
              list_mandate_debit: _result["customer_mandate_debit_list"],
              mandate_date_validation: _result["mandate_date_validation"],

              is_enach_mandate:
                _nach_form.length > 0
                  ? _nach_form[0]["is_enach_mandate"]
                  : false,

              is_register_mandate_allowed:
                _result["is_register_mandate_allowed"],
              is_mandate_registered:
                _customer_pending_mandate_list.filter((obj) => {
                  return obj.is_selected === true;
                }).length > 0,

              mandate_nach_list: _result["mandate_nach_list"],
              list_nach_form: _nach_form,
              src_nach_form:
                _nach_form.length > 0 ? _nach_form[0]["nach_form"] : "",
              nach_form_download_url:
                _nach_form.length > 0 ? _nach_form[0]["download_url"] : ``,

              selected_mandate_id:
                _nach_form.length > 0 ? _nach_form[0]["mandate_id"] : "",

              is_nach_form_download_url_new_window:
                _nach_form.length > 0
                  ? _nach_form[0]["is_download_url_new_window"]
                  : false,

              list_nominee: _result["nominee_list"].filter((el) => {
                return el["id"] !== "none";
              }),
              is_no_nominee_registered: _result["is_no_nominee_registered"],
              no_nominee_form:
                _result["no_nominee_form"] ||
                this.props.location.state["no_nominee_form"],
              no_nominee_form_download_url:
                _result["no_nominee_form_download_url"],
              is_download_url_new_window: _result["is_download_url_new_window"],
              no_nominee_form_download_timeout:
                _result["no_nominee_form_download_timeout"] || 4500,
              initate_file_download_url: _result["initate_file_download_url"],

              list_nach_authorize_type: _result["list_nach_authorize_type"],
            };

            if(_customer_pending_mandate_list.filter((obj) => {
              return obj.is_selected === true;
            }).length > 0) {
                setStateObject['mandate_amount'] = _customer_pending_mandate_list.find(
                    (obj) => obj.is_selected === true
                )['amount']
            }

            this.setState(setStateObject);
          } else
            this.setState({
              list_mandate: [],
              list_mandate_initial: [],
              list_pending_mandate: [],
              list_mandate_debit: [],
              list_nach_authorize_type: [],

              mandate_nach_list: [],
              mandate_date_validation: {
                message_text: "",
              },
              is_mandate_registered: false,
              list_nach_form: [],
              src_nach_form: "",
              nach_form_download_url: ``,
              selected_mandate_id: "",
              is_nach_form_download_url_new_window: false,
            });
        }
        // console.log(this.state.list_mandate);
      })
      .catch((err) => {
        console.log("line 146 :");
        console.log(err);
        alert(err);
      });
  };

  onloadNachMandateForm = () => {
    this.setState({ isloading: true });
    this.props
      .loadNachMandateForm(this.state.selected_order)
      .then((response) => {
        this.setState({ isloading: false });
        if (this.props.order_result["code"] !== "000")
          alert(this.props.order_result["messageText"]);
        else {
          if (this.props.order_result["render_pdf"])
            this.setState({
              render_pdf: atob(this.props.order_result["pdf_base64"]),
            });
        }
      })
      .catch((err) => {
        console.log("line 168 :");
        console.log(err);
        alert(err);
      });
  };

  refreshMandateStatus = (mandate_id) => {
    this.setState({ isloading: true });
    this.props
      .refreshMandateStatus(
        Object.assign(this.state.selected_order, { mandate_id: mandate_id })
      )
      .then((response) => {
        this.setState({ isloading: false });
        if (this.props.order_result["code"] !== "000")
          alert(this.props.order_result["messageText"]);
        else {
          if (this.props.order_result["render_pdf"])
            this.setState({
              render_pdf: atob(this.props.order_result["pdf_base64"]),
            });
          else {
            let _result = this.props.order_result["result"];

            if (_result && _result["status"]) {
              let _list = this.state.list_mandate_initial;

              _list.forEach((el) => {
                if (el["mandate_id"] === _result["mandateId"]) {
                  el["is_processed"] = _result["is_processed"];
                  el["is_reupload"] = _result["is_reupload"];

                  el["status"] = _result["status"];
                }
              });

              this.setState({
                list_mandate_initial: _list,
                list_mandate: _list,
              });
            }
          }
        }
      })
      .catch((err) => {
        console.log("line 168 :");
        console.log(err);
        alert(err);
      });
  };

  onCreateSipOrder = () => {
    this.setState({ show_modal_confirm_dialog: false, isloading: true });

    let _bank_information =
      this.state.list_bank_account.filter((el) => {
        return el["is_primary"] === true;
      })[0] || {};

    this.props
      .createSipOrder({
        client_info: {
          selected_order: this.state.selected_order,
          mfapi_scheme_code: this.state.selected_order["mfapi_scheme_code"],
          scheme_code: this.state.sip_order["scheme_code"],
          scheme_name: this.state.sip_order["scheme_name"],
          buy_sell: "P",
          buy_sell_type: "FRESH",
          sip_installment_amount: this.state.sip_order["sip_amount"],
          sip_frequency_type: this.state.sip_order["sip_frequency"],
          sip_no_of_installments:
            this.state.sip_order["sip_installment_numbers"],
          sip_start_date: this.state.sip_order["sip_date"],
          mandate_id: this.state.selected_mandate_id,
          sip_mode: "NEW",
          sip_id: "",
          sip_folio_no: "", //this.state.selected_mandate_id,
          sip_type: this.state.is_nach ? "xsip" : "sip",
          is_first_order_today: this.state.is_first_order_today,
          transaction_mode: this.state.sip_order["transaction_mode"],
          minimum_installment_amount:
            this.state.selected_order["minimum_installment_amount"],
          maximum_installment_amount:
            this.state.selected_order["maximum_installment_amount"],
          bank_account_information: _bank_information,
          is_pause_allowed: this.state.selected_order["is_pause_allowed"],
          pause_minimum_installments:
            this.state.selected_order["pause_minimum_installments"],
          pause_maximum_installments:
            this.state.selected_order["pause_maximum_installments"],
          nominee_option: this.state.nominee_option,
          nominee_id: this.state.existing_nominee_id,
        },
        two_factor_authentication: this.state.two_factor_authentication,
      })
      .then((response) => {
        if (this.props.order_result["code"] !== "000") {
          alert(this.props.order_result["messageText"]);
          this.setState({ isloading: false, show_modal_confirm_dialog: false });
        } else {
          this.setState({
            isloading: false,
            is_otp_enabled: false,
            two_factor_authentication: {
              mobile_otp: "",
              email_otp: "",
              twofactor_token_id: "",
            },
          });

          let state_parameters = Object.assign(
            this.props.order_result["result"],
            {
              is_otp_enabled: false,
              two_factor_authentication: {
                mobile_otp: "",
                email_otp: "",
                twofactor_token_id: "",
              },
            }
          );

          this.props.history.push({
            pathname: this.props.order_result["navigateScreen"], //'/paymentPage/',
            state: state_parameters, //{ selected_order: this.order_result['result'] }
          });
        }
      })
      .catch((err) => {
        console.log("line 236 :");
        console.log(err);
        alert(err);
      });
  };

  onRegisterMandate = () => {
    this.setState({ isloading: true });

    let _bank_information =
      this.state.list_bank_account.filter((el) => {
        return el["is_primary"] === true;
      })[0] || {};

    this.props
      .registerMandate({
        client_info: {
          bank_name: _bank_information["beneficiary_bank_name"],
          branch_name: _bank_information["beneficiary_bank_branch_name"],
          account_no: _bank_information["beneficiary_account_no"],
          account_type: _bank_information["beneficiary_account_type"],
          ifsc_code: _bank_information["beneficiary_ifsc"],
          account_holder_name:
            _bank_information["beneficiary_account_holder_name"],
          sip_date: this.state.sip_order["sip_date"],
          start_date: this.state.sip_order["sip_date"],
          debit_amount: this.state.mandate_amount,
          request_mode: "web",
          mandate_type: "X",
          mandate_authorize_type: this.state.mandate_authorize_type,
        },
      })
      .then((response) => {
        this.setState({ isloading: false });

        if (this.props.order_result["code"] !== "000")
          alert(this.props.order_result["messageText"]);
        else {
          let _result = this.props.order_result["result"];

          if (_result) {
            let _customer_pending_mandate_list =
              _result["customer_pending_mandate_list"];
            let _nach_form = _result["mandate_nach_list"].filter((el) => {
              return (
                el["mandate_id"] ===
                _customer_pending_mandate_list[0]["mandate_id"]
              );
            });

            this.setState({
              list_mandate: _result["customer_mandate_list"],
              list_pending_mandate: _customer_pending_mandate_list,
              list_mandate_debit: _result["customer_mandate_debit_list"],
              mandate_date_validation: _result["mandate_date_validation"],
              is_mandate_registered:
                _customer_pending_mandate_list.filter((obj) => {
                  return obj.is_selected === true;
                }).length > 0,

              list_nach_form: _nach_form,
              src_nach_form:
                _nach_form.length > 0 ? _nach_form[0]["nach_form"] : ``,
              nach_form_download_url:
                _nach_form.length > 0 ? _nach_form[0]["download_url"] : ``,
              selected_mandate_id:
                _nach_form.length > 0 ? _nach_form[0]["mandate_id"] : "",
              is_nach_form_download_url_new_window:
                _nach_form.length > 0
                  ? _nach_form[0]["is_download_url_new_window"]
                  : false,

              is_enach_mandate: _result["is_enach_mandate"],
            });
          }
        }
      })
      .catch((err) => {
        console.log("line 303 :");
        console.log(err);
      });
  };

  onUploadMandate = (request_from) => {
    this.setState({ isloading: true });
    let formData = new FormData();

    let _nachmandateFile =
      request_from === "modal"
        ? this.state.modal_nachmandateFile
        : this.state.nachmandateFile;

    if (_nachmandateFile) {
      formData.append("upload_file", _nachmandateFile);
      formData.append("id_type", "scanmandate");
      formData.append("mandateId", this.state.selected_mandate_id);
      formData.append("mandateType", "XSIP");
      formData.append("imageName", _nachmandateFile.name);
      formData.append("imageType", _nachmandateFile.type);
      // formData.append("pFileBytes", this.state.byte_nachmandateFile);

      this.props
        .uploadMandate(formData)
        .then((response) => {
          this.setState({ isloading: false });
          if (this.props.order_result["code"] !== "000") {
            this.setState({
              isloading: false,
              show_popup: true,
              popup_messageText: this.props.order_result["messageText"],
            });
          } else {
            if (this.props.order_result["state_parameters"]) {
              this.setState(
                Object.assign(
                  {
                    isloading: false,
                    show_popup: true,
                    show_upload_popup:
                      request_from === "modal"
                        ? false
                        : this.state.show_upload_popup,
                    popup_messageText:
                      this.props.order_result["state_parameters"][
                        "nach_option_new_processed_message_text"
                      ],
                  },
                  this.props.order_result["state_parameters"]
                )
              );
            }
            this.onLoadBankAccountList();
          }
        })
        .catch((err) => {
          console.log("line 340 :");
          console.log(err);
          alert(err);
        });
    }
  };

  onCancelMandate = (mandate_id) => {
    this.setState({ isloading: true });
    this.props
      .cancelMandate(
        Object.assign(this.state.selected_order, { mandate_id: mandate_id })
      )
      .then((response) => {
        this.setState({ isloading: false });

        let _result = this.props.order_result["result"];

        if (this.props.order_result["code"] !== "000") {
          this.setState({
            isloading: false,
            show_popup: true,
            popup_messageText: this.props.order_result["messageText"],
            show_confirm_popup: false,
            confirm_popup_messageText: ``,
            confirm_popup_request_mode: ``,
            confirm_popup_mandate_info: {},
          });
        } else {
          if (this.props.order_result["render_pdf"])
            this.setState({
              render_pdf: atob(this.props.order_result["pdf_base64"]),
            });
          else {
            if (_result && _result["customer_mandate_list"]) {
              let _customer_pending_mandate_list =
                _result["customer_pending_mandate_list"];
              let _nach_form = (_result["mandate_nach_list"] || []).filter(
                (el) => {
                  return (
                    el["mandate_id"] ===
                    _customer_pending_mandate_list[0]["mandate_id"]
                  );
                }
              );

              let setStateObject = {
                list_mandate: _result["customer_mandate_list"],
                list_pending_mandate: _customer_pending_mandate_list,
                is_mandate_registered:
                  _customer_pending_mandate_list.filter((obj) => {
                    return obj.is_selected === true;
                  }).length > 0,

                list_nach_form: _nach_form,
                src_nach_form:
                  _nach_form.length > 0 ? _nach_form[0]["nach_form"] : "",
                nach_form_download_url:
                  _nach_form.length > 0 ? _nach_form[0]["download_url"] : ``,

                selected_mandate_id:
                  _nach_form.length > 0 ? _nach_form[0]["mandate_id"] : "",

                is_nach_form_download_url_new_window:
                  _nach_form.length > 0
                    ? _nach_form[0]["is_download_url_new_window"]
                    : false,

                show_confirm_popup: false,
                confirm_popup_messageText: ``,
                confirm_popup_request_mode: ``,
                confirm_popup_mandate_info: {},

                show_popup: true,
                popup_messageText: this.props.order_result["messageText"],
              };

              this.setState(setStateObject);
            }
          }
        }
      })
      .catch((err) => {
        console.log("line 168 :");
        console.log(err);
        alert(err);
      });
  };

  handleMandateCheck = (e, item) => {
    if (
      this.state.sip_order["sip_frequency"].toLowerCase() === "monthly" &&
      parseInt(this.state.sip_order["sip_amount"] || 0) >
        parseInt(item["amount"] || 0) - parseInt(item["limit_used"] || 0)
    ) {
      this.setState({
        show_popup: true,
        popup_messageText:
          `This mandate does not have sufficient available limit for your ` +
          this.state.sip_order["sip_frequency"].toLowerCase() +
          ` amount. Kindly create new mandate.`,
      });
    } else {
      const _list = this.state.list_mandate_initial;

      _list.forEach((el) => {
        el["is_selected"] = false;

        if (el["mandate_id"] === item["mandate_id"])
          el["is_selected"] = e.target.checked;
      });

      let _selected_mandate = _list.filter((el) => {
        return el["is_selected"] === true;
      });

      this.setState({
        list_mandate: _selected_mandate.length > 0 ? _selected_mandate : _list,
        is_mandate_selected: _selected_mandate.length > 0,
        selected_mandate_id:
          _selected_mandate.length > 0
            ? _selected_mandate[0]["mandate_id"]
            : "",
      });

      window.scrollTo(0, document.body.scrollHeight);
    }
  };

  handleMandateIdSelect = (item) => {
    this.setState({ isloading: true });
    const _list = this.state.list_pending_mandate;
    _list.forEach((el) => {
      if (el["mandate_id"] === item.value) item["is_selected"] = !item.value;
      else item["is_selected"] = false;
    });
    this.setState({ isloading: false });
    this.setState({
      selected_mandate_id: item.value,
      list_pending_mandate: _list,
      is_mandate_selected:
        _list.filter((el) => {
          return el["is_selected"] === true;
        }).length > 0,
    });
  };

  handleInvestAmount(event, attribute) {
    const re = /^\d+(\.\d{0,3})?$/; ///^\d*\.?\d*$/ ///^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      this.setState({
        /*invest_more_amount*/ [attribute]: event.target.value.replace(
          /\b0+/g,
          ""
        ),
      });
    }
  }

  changeHandler = async (event) => {
    if (
      event.target.files[0] &&
      (event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpeg" ||
        event.target.files[0].type === "image/jpg")
    ) {
      {
        const file = event.target.files[0];
        const buffer = await file.arrayBuffer();

        let byteArray = new Int8Array(buffer);
        this.setState({
          nachmandateFile: file,
          byte_nachmandateFile: byteArray,
          src_nachmandateFile: URL.createObjectURL(file),
        });
      }
    } else {
      this.setState({
        show_popup: true,
        popup_messageText:
          "Upload file format should be of PNG, JPEG, JPG only",
      });
    }
  };

  changeHandlerModal = async (event) => {
    if (
      event.target.files[0] &&
      (event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpeg" ||
        event.target.files[0].type === "image/jpg")
    ) {
      {
        const file = event.target.files[0];
        const buffer = await file.arrayBuffer();

        let byteArray = new Int8Array(buffer);
        this.setState({
          modal_nachmandateFile: file,
          src_modal_nachmandateFile: URL.createObjectURL(file),
        });
      }
    } else {
      this.setState({
        show_popup: true,
        popup_messageText:
          "Upload file format should be of PNG, JPEG, JPG only",
      });
    }
  };

  handleRadioChange = (value, attribute) => {
    this.setState({ [attribute]: value });
  };

  base64toBlob = (data, pdfContentType) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substr(
      `data:${pdfContentType};base64,`.length
    );

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    let _blob = new Blob([out], { type: pdfContentType });

    return URL.createObjectURL(_blob);
    // return new Blob([out], { type: pdfContentType });
  };

  async downloadNachForm() {
    if (this.state.src_nach_form && this.state.src_nach_form.length > 255) {
      /*
      const win = window.open("/downloadMandate/" +
        localStorage.getItem('token') + '/' + this.state.selected_mandate_id, "_blank");
      win.focus();
      */
    }
  }

  eNachmandatePage(request_mode, mandate_info) {
    this.setState({ isloading: true });

    // window.history.replaceState(null, '');
    this.props
      .eNachMandate({
        mandate_info: mandate_info,
      })
      .then(async (response) => {
       

        if (this.props.payment_initiate_result["code"] !== "000") {
          this.setState({
            isloading: false,
            show_popup: true,
            popup_messageText:
              this.props.payment_initiate_result["messageText"],
          });
        } else {
          this.setState({
            isloading: false,
          });

          localStorage.setItem(
            "recent_enach_mandate_id",
            mandate_info["mandate_id"]
          );
          window.location.replace(
            this.props.payment_initiate_result["redirectUrl"]
          );
        }
      })
      .catch((err) => {
        alert(err);
      });
  }

  /************** NOMINEE OPTION *****************/
  handleNomineeCheck = (e, item) => {
    let _existing_nominee_selected = false,
      _existing_nominee_id = "";
    const _list = this.state.list_nominee;
    _list.forEach((el) => {
      if (el["id"] === item["id"]) {
        item["is_selected"] = !item["is_selected"];

        _existing_nominee_selected = item["is_selected"];
        if (_existing_nominee_selected) _existing_nominee_id = item["id"];
        else _existing_nominee_id = "";
      } else item["is_selected"] = false;
    });

    this.setState({
      list_nominee: _list,
      existing_nominee_selected: _existing_nominee_selected,
      existing_nominee_id: _existing_nominee_id,
      // show_next_button: _existing_nominee_selected
    });
  };

  changeNomineeHandler = async (event) => {
    if (
      event.target.files[0] &&
      (event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpeg" ||
        event.target.files[0].type === "image/jpg")
    ) {
      if (
        event.target.files[0].name.toUpperCase() ===
          `NONOMINEE_DECLARATION_` + this.state.client_code + `.PNG` ||
        event.target.files[0].name.toUpperCase() ===
          `NONOMINEE_DECLARATION_` + this.state.client_code + `.JPEG` ||
        event.target.files[0].name.toUpperCase() ===
          `NONOMINEE_DECLARATION_` + this.state.client_code + `.JPG`
      ) {
        const file = event.target.files[0];
        const buffer = await file.arrayBuffer();

        let byteArray = new Int8Array(buffer);
        this.setState({
          nonomineeformFile: file,
          byte_nonomineeformFile: byteArray,
          src_nonomineeformFile: URL.createObjectURL(file),
        });
      } else {
        this.setState({
          show_popup: true,
          popup_messageText:
            `Upload file name should be ` +
            `NONOMINEE_DECLARATION_` +
            this.state.client_code +
            `.` +
            event.target.files[0].type.split("/")[1] +
            ` only`,
        });
      }
    } else {
      this.setState({
        show_popup: true,
        popup_messageText:
          "Upload file format should be of PNG, JPEG, JPG only",
      });
    }
  };

  async downloadNomineeForm(src_nominee_form, download_file_name) {
    if (src_nominee_form && src_nominee_form.length > 255) {
      const win = window.open(
        "/downloadNominee/" +
          localStorage.getItem("token") +
          "/none/" +
          this.state.client_code,
        "_blank"
      );

      /*
      const byteCharacters = atob(src_nominee_form);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // const { data } = await getTicketsPdf()
      const blob = new Blob([byteArray], { type: "application/pdf" });
      saveAs(blob, download_file_name + ".pdf");
      */
    }
  }

  onUploadNomineeDeclaration = (request_from) => {
    this.setState({ isloading: true });
    let formData = new FormData();

    let _nonomineeformFile = this.state.nonomineeformFile;

    if (_nonomineeformFile) {
      formData.append("file", _nonomineeformFile);
      formData.append("id_type", "no-nominee");
      formData.append("imageName", _nonomineeformFile.name);
      formData.append("imageType", _nonomineeformFile.type);
      // formData.append("pFileBytes", this.state.byte__nonomineeformFile);
      formData.append(
        "nominee_information",
        JSON.stringify({
          id: "none",
          name: "",
          relationship: "",
          poi: "",
          attachment_id: "",
          attachment_list: [],
          id_information: {
            name: "",
            fathers_name: "",
            id_no: "",
            dob: "",
            id_type: "",
            part: "",
            encoded_signature: "",
            encoded_image: "",
            nominee_is_minor: false,
          },
        })
      );

      this.props
        .uploadNominee(formData)
        .then((response) => {
          this.setState({ isloading: false });
          if (this.props.order_result["code"] !== "000")
            alert(this.props.order_result["messageText"]);
          else {
            if (this.props.order_result["state_parameters"]) {
              this.setState(this.props.order_result["state_parameters"]);
            }
          }
        })
        .catch((err) => {
          console.log("line 340 :");
          console.log(err);
          alert(err);
        });
    }
  };
  /************** NOMINEE OPTION *****************/

  closePopup = () => {
    this.setState({
      show_popup: false,
    });
  };

  fnDateOrdinalformat(date, tmp) {
    return [
      (tmp = date.getDate()) + ([, "st", "nd", "rd"][/1?.$/.exec(tmp)] || "th"),
      [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ][date.getMonth()],
      date.getFullYear(),
    ].join(" ");
  }

  sendOTP = () => {
    this.setState({ isloading: true });

    this.props
      .twoFactorAuthentication({
        client_info: {
          two_factor_authentication: this.state.two_factor_authentication,
          request_action: "sip order registration",
          is_otp_email_required: true,
        },
      })
      .then((response) => {
        if (this.props.order_result["code"] !== "000") {
          this.setState({ isloading: false });
          alert(this.props.order_result["messageText"]);
        } else {
          let _result = this.props.order_result; //["result"];

          if (_result && _result["twofactor_id"]) {
            this.setState({
              isloading: false,
              is_otp_enabled: true,
              two_factor_authentication: {
                mobile_otp: "",
                email_otp: "",
                twofactor_token_id: _result["twofactor_id"],
              },
            });
          }
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  handle2FactorChange = (input, attribute) => (e) => {
    if (e)
      this.setState({
        [attribute]: { ...this.state[attribute], [input]: e },
      });
    else this.setState({ [input]: e });
  };

  downloadDocument = (
    download_url,
    file_name,
    window_timeout,
    is_download_url_new_window
  ) => {
    setTimeout(() => {
      this.setState({ isloading: false });
    }, window_timeout || 4500);

    if (is_download_url_new_window) {
      // const win = window.open("/downloadNominee/" + localStorage.getItem('token')
      //   + `/` + this.state.sip_order["scheme_code"] + `/none/` + file_name, "_blank");
      const win = window.open(download_url, "_blank");
      // win.focus();
    }

    // const win = window.open("https://www.africau.edu/images/default/sample.pdf", "_blank");

    // this.props
    //   .downloadFile({
    //     url_value: download_url,
    //   })
    //   .then((response) => {

    //     console.log(response);

    //     if (this.props.general_result["code"] !== "000") {
    //       alert(this.props.general_result["messageText"]);
    //     }
    //     else {

    //     }
    //   })
    //   .catch((err) => {
    //     alert(err);
    //   });

    // axios({
    //   url: download_url,
    //   method: 'GET',
    //   responseType: 'arraybuffer'
    // })
    //   .then((response) => {

    //     const url = window.URL.createObjectURL(new Blob([response.data]));
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', file_name);
    //     document.body.appendChild(link);
    //     link.click();

    //   });
  };

  downloadDocument_1 = (download_url, file_name, window_timeout) => {
    axios({
      url: download_url,
      method: "GET",
      responseType: "arraybuffer",
    }).then((response) => {
      // window.saveAs(new Blob([response.data]), file_name);
      // console.log(response.data);

      let myWindow = null;
      const winHtml = `<!DOCTYPE html><html><head><title>Downloading file, please wait</title><head><body><h1>Downloading file, please wait</h1></body></html>`;
      const winUrl = URL.createObjectURL(
        new Blob([winHtml], { type: "text/html" })
      );
      myWindow = window.open(winUrl, "_blank");

      const url = (window.URL || window.webkitURL).createObjectURL(
        new Blob([response.data])
      );
      if (myWindow === null) window.open(url, "_blank");
      else myWindow.location = url;
    });

    // // window.open('https://m.bullbox.in', "_system", "width=300, height=300");
    // const win = window.open(download_url, "_blank");
    // win.focus();
  };

  downloadDocument_2 = (download_url, file_name, window_timeout) => {
    window.open(download_url, "_blank", "width=300, height=300");
  };

  render() {
    const renderButton = (buttonProps) => {
      return (
        <div class="row">
          <div class="col">
            <button
              onClick={this.sendOTP}
              {...buttonProps}
              class={
                buttonProps.remainingTime !== 0
                  ? `btn btn-outline-secondary`
                  : `btn btn-outline-success`
              }
            >
              {`Resend OTP`}
            </button>
          </div>
          <div class="col">
            {buttonProps.remainingTime !== 0 ? (
              <span>
                You can resend otp in
                <span style={{ fontWeight: "bold", color: "green" }}>
                  {" "}
                  {buttonProps.remainingTime}
                </span>{" "}
                secs.
              </span>
            ) : (
              ``
            )}
          </div>
        </div>
      );
    };
    const renderTime = () => React.Fragment;

    return (
      <>
        <Header1 />
        <Sidebar />

        <div className="content-body">
          <div className="container" style={{ marginBottom: 20 }}>
            <div className="row">
              <div id="navbar-sipcheckout-container">
                <div id="navbar">
                  <div className="card" style={{ backgroundColor: "#f8f8fb" }}>
                    <div className="card-header">
                      <h5>Sip Order - Checkout</h5>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div id="container"> */}
              <div style={{ marginTop: 50 }}>
                {this.state.isloading && (
                  <Spinner
                    animation="border"
                    variant="dark"
                    style={{ position: "fixed", top: "50%", left: "50%" }}
                  />
                )}

                {/* <div class="row">
              <div className="col-lg-12"> */}

                <div>
                  {(this.state.wizard_step === 1 ||
                    this.state.wizard_step === 4) && (
                    <div>
                      {/* <div className="card"> */}

                      <div className="card-body">
                        <h6>{this.state.sip_order["scheme_name"]}</h6>
                        <table style={{ width: "100%" }}>
                          <tr>
                            <td
                              style={{
                                height: 30,
                                border: "1px solid #e2e0e4",
                                backgroundColor: "#f7f5f5",
                                textAlign: "left",
                              }}
                            >
                              <span>SCHEME CODE</span>
                            </td>
                            <td
                              style={{
                                height: 30,
                                border: "1px solid #e2e0e4",
                                textAlign: "center",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: 14,
                                }}
                              >
                                <span style={{ fontSize: 13 }}>
                                  {this.state.sip_order["scheme_code"]}
                                </span>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                height: 30,
                                border: "1px solid #e2e0e4",
                                backgroundColor: "#f7f5f5",
                                textAlign: "left",
                              }}
                            >
                              <span>FRQUENCY</span>
                            </td>
                            <td
                              style={{
                                height: 30,
                                border: "1px solid #e2e0e4",
                                textAlign: "center",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: 14,
                                }}
                              >
                                {this.state.sip_order["display_text"]}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                height: 30,
                                border: "1px solid #e2e0e4",
                                backgroundColor: "#f7f5f5",
                                textAlign: "left",
                              }}
                            >
                              <span>INVESTMENT AMOUNT</span>
                            </td>
                            <td
                              style={{
                                height: 30,
                                border: "1px solid #e2e0e4",
                                textAlign: "center",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: 14,
                                }}
                              >
                                <span style={{ fontFamily: "sans-serif" }}>
                                  &#8377;
                                </span>
                                {this.state.sip_order["sip_amount"]}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                height: 30,
                                border: "1px solid #e2e0e4",
                                backgroundColor: "#f7f5f5",
                                textAlign: "left",
                              }}
                            >
                              <span>NO OF INSTALLMENTS</span>
                            </td>
                            <td
                              style={{
                                height: 30,
                                border: "1px solid #e2e0e4",
                                textAlign: "center",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: 14,
                                }}
                              >
                                {
                                  this.state.sip_order[
                                    "sip_installment_numbers"
                                  ]
                                }
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>

                      {this.state.wizard_step !== 4 &&
                        this.state.sip_order["sip_date"] && (
                          <div className="card-body">
                            <span style={{ backgroundColor: "#dbcec5" }}>
                              SIP amount will be debited from primary account on
                              <b>
                                {" "}
                                {/* {this.fnDateOrdinalformat(this.state.sip_order['sip_date']).split(' ')[0]}
                              {` of every `} */}
                                {this.state.sip_order["sip_date_value"]}
                              </b>
                              {/* {this.state.sip_order['sip_frequency'].toLowerCase().replace('ly', '')} */}
                            </span>
                          </div>
                        )}

                      {/* <div className="card">
                        <div className="card-body" > */}
                      <table style={{ marginLeft: 15 }}>
                        {(this.state.list_bank_account || []).map(
                          (item, index) => (
                            <table style={{ width: "100%" }}>
                              <tr>
                                <td
                                  style={{
                                    height: 30,
                                    border: "1px solid #e2e0e4",
                                    backgroundColor: "#f7f5f5",
                                    textAlign: "left",
                                  }}
                                >
                                  <span>Bank Name</span>
                                </td>
                                <td
                                  style={{
                                    height: 30,
                                    border: "1px solid #e2e0e4",
                                    textAlign: "center",
                                  }}
                                >
                                  {item["beneficiary_bank_name"]}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    width: 120,
                                    height: 30,
                                    border: "1px solid #e2e0e4",
                                    backgroundColor: "#f7f5f5",
                                    textAlign: "left",
                                  }}
                                >
                                  <span>Account Number</span>
                                </td>
                                <td
                                  style={{
                                    height: 30,
                                    border: "1px solid #e2e0e4",
                                    textAlign: "center",
                                  }}
                                >
                                  {item["beneficiary_account_no"]}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    height: 30,
                                    border: "1px solid #e2e0e4",
                                    backgroundColor: "#f7f5f5",
                                    textAlign: "left",
                                  }}
                                >
                                  <span>Name</span>
                                </td>
                                <td
                                  style={{
                                    height: 30,
                                    border: "1px solid #e2e0e4",
                                    textAlign: "center",
                                  }}
                                >
                                  {item["beneficiary_account_holder_name"]}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    height: 30,
                                    border: "1px solid #e2e0e4",
                                    backgroundColor: "#f7f5f5",
                                    textAlign: "left",
                                  }}
                                >
                                  <span>Account Type</span>
                                </td>
                                <td
                                  style={{
                                    height: 30,
                                    border: "1px solid #e2e0e4",
                                    textAlign: "center",
                                  }}
                                >
                                  {item["beneficiary_account_type"]}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    height: 30,
                                    border: "1px solid #e2e0e4",
                                    backgroundColor: "#f7f5f5",
                                    textAlign: "left",
                                  }}
                                >
                                  <span>IFSC</span>
                                </td>
                                <td
                                  style={{
                                    height: 30,
                                    border: "1px solid #e2e0e4",
                                    textAlign: "center",
                                  }}
                                >
                                  {item["beneficiary_ifsc"]}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    height: 30,
                                    border: "1px solid #e2e0e4",
                                    backgroundColor: "#f7f5f5",
                                    textAlign: "left",
                                  }}
                                >
                                  <span>Branch</span>
                                </td>
                                <td
                                  style={{
                                    height: 30,
                                    border: "1px solid #e2e0e4",
                                    textAlign: "center",
                                  }}
                                >
                                  {item["beneficiary_bank_branch_name"]}
                                </td>
                              </tr>
                            </table>
                          )
                        )}
                      </table>
                      {/* </div>
                      </div> */}
                    </div>
                  )}

                  {this.state.wizard_step === 2 && (
                    <div className="card-body">
                      <p>Do you want to add nominee ?</p>
                      <div className="row">
                        <div className="col">
                          <Form.Check
                            type="radio"
                            label="Yes"
                            name="nominee_option"
                            id="existing"
                            value="existing"
                            checked={this.state.nominee_option === "existing"}
                            onChange={(e) =>
                              this.setState({ nominee_option: "existing" })
                            }
                          />
                        </div>
                        <div className="col">
                          <Form.Check
                            type="radio"
                            label="No"
                            name="nominee_option"
                            id="none"
                            value="none"
                            checked={this.state.nominee_option === "none"}
                            onChange={(e) =>
                              this.setState({ nominee_option: "none" })
                            }
                            disabled={true}
                          />
                        </div>
                      </div>
                      <hr />
                      {this.state.nominee_option === "none" && (
                        <div class="row">
                          <h6>
                            As per SEBI guidelines as an investor you are
                            required to sign the declaration form (only for
                            first time in our app).
                            {/* Signed declaration form will be used for your subsequent fresh orders. */}
                          </h6>

                          <div style={{ marginLeft: -120 }}>
                            {this.state.is_no_nominee_registered &&
                              this.state.no_nominee_form &&
                              this.state.no_nominee_form.length > 255 && (
                                <PDFReader
                                  data={atob(this.state.no_nominee_form)}
                                  width="700"
                                />
                              )}
                          </div>

                          {!this.state.is_no_nominee_registered && (
                            <div>
                              <div className="row">
                                <div
                                  style={{
                                    textAlign: "left",
                                  }}
                                >
                                  <b>Step 1</b>.{` `}
                                  {this.state.is_download_url_new_window ===
                                  true ? (
                                    <button
                                      type="button"
                                      className="btn-sm btn-primary"
                                      style={{ color: "white", width: "140px" }}
                                      onClick={() => {
                                        this.downloadDocument(
                                          this.state
                                            .no_nominee_form_download_url,
                                          `NONOMINEE_DECLARATION_` +
                                            this.state.client_code +
                                            `.pdf`,
                                          this.state
                                            .no_nominee_form_download_timeout,
                                          this.state.is_download_url_new_window
                                        );
                                      }}
                                    >
                                      Download Form
                                    </button>
                                  ) : (
                                    // <a
                                    //   className="btn-sm btn-primary"
                                    //   style={{ color: 'white', width: '140px' }}
                                    //   href={this.state.no_nominee_form_download_url}
                                    //   onClick={() => {
                                    //     this.setState({ isloading: true });
                                    //     setTimeout(() => { this.setState({ isloading: false }); }, 4500)
                                    //   }
                                    //   }
                                    // >
                                    //   Download Form
                                    // </a>
                                    <a
                                      className="btn-sm btn-info"
                                      // variant="info"
                                      download={
                                        `NONOMINEE_DECLARATION_` +
                                        this.state.client_code +
                                        `.pdf`
                                      }
                                      disabled={this.state.isloading}
                                      href={
                                        this.state.no_nominee_form_download_url
                                      }
                                      onClick={() => {
                                        this.setState({ isloading: true });
                                        setTimeout(() => {
                                          this.setState({ isloading: false });
                                        }, 4500);
                                      }}
                                    >
                                      Download Form
                                    </a>
                                  )}
                                  {/* <br/>
                              <br/>
                              <button type="button"
                                  className="btn-sm btn-primary"
                                  style={{ color: 'white', width: '140px' }}
                                  onClick={() => {
                                    this.downloadDocument_1(this.state.no_nominee_form_download_url, 
                                      `NONOMINEE_DECLARATION_` + this.state.client_code + `.pdf`, this.state.no_nominee_form_download_timeout);
                                  }}
                                >
                                  Download Form 1
                              </button> */}
                                  {/* <br/>
                              <br/>
                              <button type="button"
                                className="btn-sm btn-primary"
                                style={{ color: 'white', width: '140px' }}
                                onClick={() => {
                                  this.downloadDocument_2(this.state.no_nominee_form_download_url, 
                                    `NONOMINEE_DECLARATION_` + this.state.client_code + `.pdf`, this.state.no_nominee_form_download_timeout);
                                }}
                              >
                                Download Here
                              </button> */}
                                </div>

                                {/* {
                              <div>
                                <br></br>
                                <PDFDownloadLink
                                  document={
                                    <Document>
                                      <Page>
                                      <span>
                                        Sadai Vanakkam
                                      </span>
                                        
                                      </Page>
                                    </Document>
                                  }
                                  fileName="sadaimandhi.pdf">
                                  {({ blob, url, loading, error }) =>
                                    loading ? 'Loading document...' : 'Download now!'
                                  }
                                </PDFDownloadLink>
                              </div>
                            } */}
                                <hr />
                                <p
                                  style={{
                                    textAlign: "left",
                                  }}
                                >
                                  <b>Step 2</b>. Put your signature at Unit
                                  Holder (1)
                                </p>
                                <p
                                  style={{
                                    textAlign: "left",
                                  }}
                                >
                                  <b>Step 3</b>. Scan or Take photo and sava as
                                  png/jpeg format. Do not tilt the image while
                                  uploading.
                                  <p
                                    style={{
                                      textAlign: "left",
                                    }}
                                  >
                                    File name should be strictly as
                                    <span
                                      style={{
                                        marginLeft: -5,
                                        color: "darkgreen",
                                      }}
                                    >
                                      {` NONOMINEE_DECLARATION_` +
                                        this.state.client_code +
                                        `.png`}
                                    </span>
                                    <div
                                      style={{
                                        fontSize: 15,
                                        textAlign: "center",
                                      }}
                                    >
                                      or
                                    </div>
                                    <span
                                      style={{
                                        marginLeft: -5,
                                        color: "darkgreen",
                                      }}
                                    >
                                      {` NONOMINEE_DECLARATION_` +
                                        this.state.client_code +
                                        ".jpeg"}
                                      {` `}
                                    </span>
                                  </p>
                                </p>
                                <Form.Control
                                  type="file"
                                  onChange={this.changeNomineeHandler}
                                />
                              </div>

                              {this.state.src_nonomineeformFile && (
                                <div className="row">
                                  <img
                                    width="150"
                                    src={this.state["src_nonomineeformFile"]}
                                  />
                                </div>
                              )}
                              <hr />
                              <div className="row">
                                <div class="col-3"></div>
                                <div class="col">
                                  <Button
                                    className="btn btn-success btn-sm"
                                    variant="success"
                                    onClick={() => {
                                      this.onUploadNomineeDeclaration("main");
                                    }}
                                    disabled={
                                      this.state.isloading ||
                                      !this.state.src_nonomineeformFile
                                    }
                                  >
                                    Upload Declaration
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {this.state.nominee_option === "existing" && (
                        <div class="row">
                          <table style={{ marginLeft: 15 }}>
                            <thead>
                              <tr>
                                <th colSpan="5" style={{ fontSize: 12 }}>
                                  Name
                                </th>
                                <th colSpan="4" style={{ fontSize: 12 }}>
                                  Id Proof
                                </th>
                                <th colSpan="2" style={{ fontSize: 12 }}>
                                  Select
                                </th>
                              </tr>
                            </thead>
                            {(this.state.list_nominee || []).map(
                              (item, index) => (
                                <tbody key={index}>
                                  <tr>
                                    <td colSpan={5}>
                                      <span style={{ fontSize: 13 }}>
                                        {item["name"]}
                                        {"("}
                                        {item["relationship"] || ""}
                                        {")"}
                                      </span>
                                      <br />
                                      <Button
                                        className="btn btn-success btn-sm"
                                        variant="link"
                                        style={{ fontSize: 12 }}
                                        onClick={() => {
                                          this.setState({
                                            show_nominee_form_modal: true,
                                            modal_nominee: {
                                              ...this.state.modal_nominee,
                                              info: item,
                                              form: item["nominee_form"],
                                            },
                                          });
                                        }}
                                      >
                                        Show Form
                                      </Button>
                                    </td>
                                    <td colSpan={4}>
                                      <span style={{ fontSize: 13 }}>
                                        {item["poi"]}
                                        <br />
                                        {" ("}
                                        {item["id_information"]["id_no"]}
                                        {")"}
                                      </span>
                                    </td>
                                    <td colSpan={2}>
                                      <label>
                                        <Form.Group
                                          className="mb-3"
                                          controlId="formBasicCheckbox"
                                        >
                                          <Form.Check
                                            type="checkbox"
                                            // label="Choose"
                                            onChange={(e) => {
                                              this.handleNomineeCheck(e, item);
                                            }}
                                            defaultChecked={item["is_selected"]}
                                          />
                                        </Form.Group>
                                      </label>
                                    </td>
                                  </tr>
                                  {/* {item['is_selected'] && item['nominee_form'].length > 77 &&
                                    <tr>
                                      <td colSpan={10}>
                                        <PDFReader
                                          data={atob(
                                            item['nominee_form']
                                          )}
                                          width="800"
                                        />
                                      </td>
                                    </tr>
                                  } */}
                                </tbody>
                              )
                            )}
                          </table>
                        </div>
                      )}
                    </div>
                  )}

                  {this.state.wizard_step === 3 &&
                    ((this.state.nominee_option === "existing" &&
                      this.state.existing_nominee_selected) ||
                      (this.state.nominee_option === "none" &&
                        this.state.is_no_nominee_registered)) && (
                      <div className="card-body">
                        <div>
                          <div className="form">
                            {
                              <Form.Group as={Row} className="mb-3">
                                <Col sm={12}>
                                  {this.state.is_nach && (
                                    <div class="row">
                                      <div class="col-6">
                                        <Form.Check
                                          type="radio"
                                          label={
                                            <span>
                                              <span
                                                style={{
                                                  backgroundColor: "#f5f7f6",
                                                  fontSize: 18,
                                                  padding: 4,
                                                  paddingLeft: 8,
                                                  paddingRight: 8,
                                                  margin: 4,
                                                  borderRadius: 4,
                                                }}
                                              >
                                                {`Choose`}
                                              </span>
                                            </span>
                                          }
                                          name="nach_option"
                                          id="existing"
                                          value="existing"
                                          checked={
                                            this.state.nach_option ===
                                            "existing"
                                          }
                                          onChange={(e) =>
                                            this.handleRadioChange(
                                              "existing",
                                              "nach_option"
                                            )
                                          }
                                        />
                                      </div>
                                      <div class="col-6">
                                        <Form.Check
                                          type="radio"
                                          label={
                                            <span>
                                              <span
                                                style={{
                                                  backgroundColor: "#f5f7f6",
                                                  fontSize: 18,
                                                  padding: 4,
                                                  paddingLeft: 8,
                                                  paddingRight: 8,
                                                  margin: 4,
                                                  borderRadius: 4,
                                                }}
                                              >
                                                {`Register`}
                                              </span>
                                            </span>
                                          }
                                          name="nach_option"
                                          id="new"
                                          value="new"
                                          checked={
                                            this.state.nach_option === "new"
                                          }
                                          onChange={(e) =>
                                            this.handleRadioChange(
                                              "new",
                                              "nach_option"
                                            )
                                          }
                                          disabled={
                                            !this.state
                                              .is_register_mandate_allowed
                                          }
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {
                                    /*this.state.is_nach && */
                                    (this.state.nach_option === "new" ||
                                      this.state.nach_option === "existing") &&
                                      this.state.sip_order["sip_frequency"] /*&&
                                  (!this.state.is_mandate_selected ||
                                    this.state.nach_option ===
                                    "new")*/ && (
                                        <div class="row">
                                          {
                                            <div>
                                              <hr />

                                              <div>
                                                {this.state.nach_option ===
                                                  "new" &&
                                                  this.state
                                                    .list_pending_mandate
                                                    .length > 0 && (
                                                    <div>
                                                      <div class="row">
                                                        <div className="col-6">
                                                          Mandate Id <br />
                                                          <Select
                                                            width="140px"
                                                            value={this.state.list_pending_mandate.find(
                                                              (obj) =>
                                                                obj.is_selected ===
                                                                true
                                                            )}
                                                            onChange={(e) =>
                                                              this.handleMandateIdSelect(
                                                                e
                                                              )
                                                            }
                                                            options={
                                                              this.state
                                                                .list_pending_mandate
                                                            }
                                                            isDisabled={
                                                              this.state
                                                                .nach_option_new_processed
                                                            }
                                                            isSearchable={false}
                                                          />
                                                        </div>
                                                        <div className="col-6">
                                                          Allowed Limit <br />
                                                          <Select
                                                            value={this.state.list_mandate_debit.find(
                                                              (obj) =>
                                                                obj.value.toString() ===
                                                                this.state.mandate_amount.toString()
                                                            )}
                                                            onChange={(e) =>
                                                              this.setState({
                                                                mandate_amount:
                                                                  e.value,
                                                              })
                                                            }
                                                            options={
                                                              this.state
                                                                .list_mandate_debit
                                                            }
                                                            isDisabled={
                                                              this.state
                                                                .is_mandate_registered
                                                            }
                                                          />
                                                        </div>
                                                      </div>

                                                      <div
                                                        style={{ height: 10 }}
                                                      />

                                                      <div class="row">
                                                        <div class="col">
                                                          <Button
                                                            className="btn btn-warning btn-block"
                                                            variant="success"
                                                            onClick={(e) =>
                                                              this.setState({
                                                                show_confirm_popup: true,
                                                                confirm_popup_messageText: `Are you sure want to cancel the this mandate ?`,
                                                                confirm_popup_request_mode: `cancel mandate`,
                                                                confirm_popup_mandate_info:
                                                                  this.state
                                                                    .list_pending_mandate[0][
                                                                    "mandate_id"
                                                                  ],
                                                              })
                                                            }
                                                            disabled={
                                                              this.state
                                                                .isloading
                                                            }
                                                          >
                                                            Cancel Mandate
                                                          </Button>
                                                        </div>
                                                      </div>

                                                      {/* <div class="row">
                                                <div className="col-6" style={{ fontWeight: 'bold', marginTop: 5, fontSize: 16 }}>
                                                  Choose Mandate
                                                </div>
                                                <div class="col-6">
                                                  <Select
                                                    value={this.state.list_pending_mandate.find(
                                                      (obj) =>
                                                        obj.is_selected ===
                                                        true
                                                    )}
                                                    onChange={(e) =>
                                                      this.handleMandateIdSelect(
                                                        e
                                                      )
                                                    }
                                                    options={
                                                      this.state
                                                        .list_pending_mandate
                                                    }
                                                    isDisabled={
                                                      this.state
                                                        .nach_option_new_processed
                                                    }
                                                    isSearchable={
                                                      false
                                                    }
                                                  />
                                                </div>
                                              </div> */}
                                                    </div>
                                                  )}
                                                {this.state.nach_option ===
                                                  "new" && (
                                                  <div class="row">
                                                    <div>
                                                      <br />
                                                      {this.state
                                                        .list_pending_mandate
                                                        .length === 0 ? (
                                                        <div>
                                                          <table
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  height: 30,
                                                                  border:
                                                                    "1px solid #e2e0e4",
                                                                  backgroundColor:
                                                                    "#f7f5f5",
                                                                  textAlign:
                                                                    "left",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 14,
                                                                  }}
                                                                >
                                                                  Allowed Limit
                                                                </span>
                                                              </td>
                                                              <td
                                                                style={{
                                                                  height: 30,
                                                                  border:
                                                                    "1px solid #e2e0e4",
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                <Select
                                                                  value={this.state.list_mandate_debit.find(
                                                                    (obj) =>
                                                                      obj.value.toString() ===
                                                                      this.state.mandate_amount.toString()
                                                                  )}
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    this.setState(
                                                                      {
                                                                        mandate_amount:
                                                                          e.value,
                                                                      }
                                                                    )
                                                                  }
                                                                  options={
                                                                    this.state
                                                                      .list_mandate_debit
                                                                  }
                                                                  isDisabled={
                                                                    this.state
                                                                      .is_mandate_registered
                                                                  }
                                                                  isSearchable={
                                                                    false
                                                                  }
                                                                />
                                                              </td>
                                                            </tr>
                                                          </table>
                                                          <div
                                                            style={{
                                                              height: 7,
                                                            }}
                                                          />
                                                          <table
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  height: 30,
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 14,
                                                                  }}
                                                                >
                                                                  Authorize Type
                                                                </span>
                                                              </td>
                                                            </tr>
                                                          </table>
                                                          <div
                                                            style={{
                                                              height: 3,
                                                            }}
                                                          />
                                                          <table
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  height: 30,
                                                                  border:
                                                                    "1px solid #e2e0e4",
                                                                  textAlign:
                                                                    "left",
                                                                }}
                                                              >
                                                                <Select
                                                                  value={this.state.list_nach_authorize_type.find(
                                                                    (obj) =>
                                                                      obj.value.toString() ===
                                                                      this.state.mandate_authorize_type.toString()
                                                                  )}
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    this.setState(
                                                                      {
                                                                        mandate_authorize_type:
                                                                          e.value,
                                                                      }
                                                                    )
                                                                  }
                                                                  options={
                                                                    this.state
                                                                      .list_nach_authorize_type
                                                                  }
                                                                  isDisabled={
                                                                    this.state
                                                                      .is_mandate_registered
                                                                  }
                                                                  isSearchable={
                                                                    false
                                                                  }
                                                                />
                                                              </td>
                                                            </tr>
                                                          </table>
                                                          <div
                                                            style={{
                                                              height: 3,
                                                            }}
                                                          />
                                                          {(
                                                            this.state
                                                              .list_nach_authorize_type ||
                                                            []
                                                          ).length > 0 && (
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "darkgreen",
                                                              }}
                                                            >
                                                              YOU HAVE OPTED FOR{" "}
                                                              {
                                                                (
                                                                  this.state
                                                                    .list_nach_authorize_type ||
                                                                  []
                                                                ).filter(
                                                                  (obj) => {
                                                                    return (
                                                                      obj.value.toString() ===
                                                                      this.state.mandate_authorize_type.toString()
                                                                    );
                                                                  }
                                                                )[0]["label"]
                                                              }
                                                            </span>
                                                          )}
                                                          <div
                                                            style={{
                                                              height: 5,
                                                            }}
                                                          />
                                                          <Button
                                                            className="btn btn-success btn-block"
                                                            variant="success"
                                                            disabled={
                                                              (this.state
                                                                .mandate_date_validation[
                                                                "message_text"
                                                              ].length > 15 &&
                                                                !this.state
                                                                  .is_first_order_today) ||
                                                              this.state
                                                                .isloading
                                                            }
                                                            onClick={
                                                              this
                                                                .onRegisterMandate
                                                            }
                                                          >
                                                            Register Mandate
                                                          </Button>
                                                        </div>
                                                      ) : (
                                                        <div>
                                                          {this.state
                                                            .is_enach_mandate && (
                                                            <div class="row">
                                                              <div>
                                                                {(
                                                                  this.state
                                                                    .list_pending_mandate[0][
                                                                    "umrn_no"
                                                                  ] || ``
                                                                ).length > 7 ? (
                                                                  <span>
                                                                    eNACH
                                                                    Authentication
                                                                    Successfull.
                                                                    It takes T+2
                                                                    working days
                                                                    for approval{" "}
                                                                    <br />
                                                                  </span>
                                                                ) : (
                                                                  <Button
                                                                    className="btn btn-success btn-block"
                                                                    variant="success"
                                                                    onClick={(
                                                                      e
                                                                    ) =>
                                                                      this.eNachmandatePage(
                                                                        `enach authentication`,
                                                                        this
                                                                          .state
                                                                          .list_pending_mandate[0]
                                                                      )
                                                                    }
                                                                    disabled={
                                                                      this.state
                                                                        .isloading
                                                                    }
                                                                  >
                                                                    Verify eNACH
                                                                    Now
                                                                  </Button>
                                                                )}
                                                              </div>
                                                            </div>
                                                          )}
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                )}
                                              </div>

                                              {this.state.nach_option ===
                                                "new" &&
                                                this.state.list_pending_mandate
                                                  .length === 0 && (
                                                  <div>
                                                    <div class="col">
                                                      <h6>
                                                        <p></p>
                                                        Mandate amount limit is
                                                        checked for each
                                                        transaction and not sum
                                                        total of transaction.
                                                        For example: If your
                                                        Mandate amount is Rs.
                                                        25,000, you can set up
                                                        as many Systematic Plans
                                                        you want using this
                                                        mandate however the
                                                        transaction amount of
                                                        each of such Systematic
                                                        Plan (+ charges if any)
                                                        should be less than Rs
                                                        25,000.
                                                      </h6>
                                                    </div>
                                                  </div>
                                                )}

                                              {this.state.nach_option ===
                                                "existing" && (
                                                <div>
                                                  {(
                                                    this.state.list_mandate ||
                                                    []
                                                  ).length > 0 ? (
                                                    <ListGroup>
                                                      {(
                                                        this.state
                                                          .list_mandate || []
                                                      ).map((item) => (
                                                        <ListGroup.Item
                                                          key={
                                                            item["mandate_id"]
                                                          }
                                                          style={{
                                                            marginBottom: 5,
                                                            border:
                                                              "1px solid #a8acb3",
                                                            borderCollapse:
                                                              "collapse",
                                                          }}
                                                          action
                                                          variant="light"
                                                          className="wallet-address text-dark"
                                                        >
                                                          <table
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  height: 30,
                                                                  border:
                                                                    "1px solid #e2e0e4",
                                                                  backgroundColor:
                                                                    "#f7f5f5",
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                <span>
                                                                  Mandate Id
                                                                </span>
                                                              </td>
                                                              <td
                                                                style={{
                                                                  height: 30,
                                                                  border:
                                                                    "1px solid #e2e0e4",
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {
                                                                  item[
                                                                    "mandate_id"
                                                                  ]
                                                                }
                                                              </td>
                                                              <td
                                                                style={{
                                                                  width: 10,
                                                                }}
                                                              ></td>
                                                              {!item[
                                                                "is_processed"
                                                              ] ? (
                                                                <td
                                                                  style={{
                                                                    height: 30,
                                                                    textAlign:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  (
                                                                  {item[
                                                                    "is_reupload"
                                                                  ] ? (
                                                                    <Button
                                                                      className="btn btn-success btn-sm"
                                                                      variant="success"
                                                                      onClick={() => {
                                                                        const _list_nach_form =
                                                                          this.state.mandate_nach_list.filter(
                                                                            (
                                                                              el
                                                                            ) => {
                                                                              return (
                                                                                el[
                                                                                  "mandate_id"
                                                                                ] ===
                                                                                item[
                                                                                  "mandate_id"
                                                                                ]
                                                                              );
                                                                            }
                                                                          );

                                                                        this.setState(
                                                                          {
                                                                            show_upload_popup: true,
                                                                            modal_upload_mandate:
                                                                              Object.assign(
                                                                                item,
                                                                                {
                                                                                  nach_form_download_url:
                                                                                    _list_nach_form[0][
                                                                                      "download_url"
                                                                                    ],
                                                                                  is_nach_form_download_url_new_window:
                                                                                    _list_nach_form[0][
                                                                                      "is_download_url_new_window"
                                                                                    ],
                                                                                }
                                                                              ),
                                                                          }
                                                                        );
                                                                      }}
                                                                    >
                                                                      Re-Upload
                                                                    </Button>
                                                                  ) : (
                                                                    <button
                                                                      type="button"
                                                                      className="btn btn-success btn-sm"
                                                                      variant="success"
                                                                      onClick={(
                                                                        e
                                                                      ) =>
                                                                        this.refreshMandateStatus(
                                                                          item[
                                                                            "mandate_id"
                                                                          ]
                                                                        )
                                                                      }
                                                                      disabled={
                                                                        this
                                                                          .state
                                                                          .isloading ||
                                                                        item[
                                                                          "is_cancelled"
                                                                        ]
                                                                      }
                                                                    >
                                                                      Refresh
                                                                    </button>
                                                                  )}
                                                                  )
                                                                </td>
                                                              ) : (
                                                                <td
                                                                  style={{
                                                                    height: 30,
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  <Form.Group controlId="formBasicCheckbox">
                                                                    <Form.Check
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                      type="checkbox"
                                                                      label="Select"
                                                                      checked={
                                                                        item[
                                                                          "is_selected"
                                                                        ]
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        this.handleMandateCheck(
                                                                          e,
                                                                          item
                                                                        );
                                                                      }}
                                                                      defaultChecked={
                                                                        item[
                                                                          "is_primary"
                                                                        ]
                                                                      }
                                                                      disabled={
                                                                        item[
                                                                          "is_cancelled"
                                                                        ] ||
                                                                        !item[
                                                                          "is_processed"
                                                                        ] ||
                                                                        !item[
                                                                          "is_active"
                                                                        ]
                                                                      }
                                                                    />
                                                                  </Form.Group>
                                                                </td>
                                                              )}
                                                            </tr>
                                                          </table>

                                                          <table
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  height: 30,
                                                                  border:
                                                                    "1px solid #e2e0e4",
                                                                  backgroundColor:
                                                                    "#f7f5f5",
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                <span>
                                                                  Amount Limit
                                                                </span>
                                                              </td>
                                                              <td
                                                                style={{
                                                                  height: 30,
                                                                  border:
                                                                    "1px solid #e2e0e4",
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontFamily:
                                                                      "sans-serif",
                                                                    fontSize: 14,
                                                                  }}
                                                                >
                                                                  &#8377;
                                                                </span>
                                                                {item[
                                                                  "amount"
                                                                ] || `-`}
                                                              </td>
                                                              <td
                                                                style={{
                                                                  width: 10,
                                                                }}
                                                              ></td>
                                                              <td
                                                                style={{
                                                                  height: 30,
                                                                  border:
                                                                    "1px solid #e2e0e4",
                                                                  backgroundColor:
                                                                    "#f7f5f5",
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                <span>
                                                                  Available
                                                                </span>
                                                              </td>
                                                              <td
                                                                style={{
                                                                  height: 30,
                                                                  border:
                                                                    "1px solid #e2e0e4",
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontFamily:
                                                                      "sans-serif",
                                                                    fontSize: 14,
                                                                  }}
                                                                >
                                                                  &#8377;
                                                                </span>
                                                                {item[
                                                                  "amount"
                                                                ] -
                                                                  item[
                                                                    "limit_used"
                                                                  ]}
                                                              </td>
                                                            </tr>
                                                          </table>

                                                          <table
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  height: 30,
                                                                  textAlign:
                                                                    "left",
                                                                }}
                                                              >
                                                                <span>
                                                                  <i class="fa fa-check-square-o"></i>
                                                                  {` Validity till `}{" "}
                                                                  {
                                                                    item[
                                                                      "end_date"
                                                                    ]
                                                                  }
                                                                </span>
                                                              </td>
                                                            </tr>
                                                          </table>
                                                          <table
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  height: 30,
                                                                  textAlign:
                                                                    "left",
                                                                }}
                                                              >
                                                                <span>
                                                                  <span
                                                                    style={{
                                                                      fontWeight:
                                                                        "bold",
                                                                    }}
                                                                  >
                                                                    {item[
                                                                      "status"
                                                                    ] ||
                                                                      "awiting bank approval"}
                                                                  </span>
                                                                </span>
                                                              </td>
                                                            </tr>
                                                          </table>
                                                          <table
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <tr>
                                                              {!item[
                                                                "is_processed"
                                                              ] ? (
                                                                <td
                                                                  style={{
                                                                    height: 70,
                                                                    textAlign:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  (item['is_reupload'])
                                                                  ?
                                                                  <Button
                                                                    className="btn btn-success btn-sm"
                                                                    variant="success"
                                                                    onClick={() => {
                                                                      const _list_nach_form =
                                                                        this.state.mandate_nach_list.filter(
                                                                          (
                                                                            el
                                                                          ) => {
                                                                            return (
                                                                              el[
                                                                                "mandate_id"
                                                                              ] ===
                                                                              item[
                                                                                "mandate_id"
                                                                              ]
                                                                            );
                                                                          }
                                                                        );

                                                                      this.setState(
                                                                        {
                                                                          show_upload_popup: true,
                                                                          modal_upload_mandate:
                                                                            Object.assign(
                                                                              item,
                                                                              {
                                                                                nach_form_download_url:
                                                                                  _list_nach_form[0][
                                                                                    "download_url"
                                                                                  ],
                                                                                is_nach_form_download_url_new_window:
                                                                                  _list_nach_form[0][
                                                                                    "is_download_url_new_window"
                                                                                  ],
                                                                              }
                                                                            ),
                                                                        }
                                                                      );
                                                                    }}
                                                                    style={{
                                                                      position:
                                                                        "relative",
                                                                      bottom: 0,
                                                                      marginRight:
                                                                        "50px",
                                                                    }}
                                                                  >
                                                                    Re-Upload
                                                                  </Button>
                                                                  {!item[
                                                                    "is_cancelled"
                                                                  ] && (
                                                                    <Button
                                                                      className="btn btn-success btn-sm"
                                                                      variant="success"
                                                                      onClick={(
                                                                        e
                                                                      ) =>
                                                                        this.refreshMandateStatus(
                                                                          item[
                                                                            "mandate_id"
                                                                          ]
                                                                        )
                                                                      }
                                                                      disabled={
                                                                        this
                                                                          .state
                                                                          .isloading
                                                                      }
                                                                      style={{
                                                                        position:
                                                                          "relative",
                                                                        bottom: 0,
                                                                        marginRight:
                                                                          "20px",
                                                                      }}
                                                                    >
                                                                      Refresh
                                                                    </Button>
                                                                  )}
                                                                </td>
                                                              ) : (
                                                                <td
                                                                  style={{
                                                                    height: 30,
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  <Form.Group controlId="formBasicCheckbox">
                                                                    <Form.Check
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                      type="checkbox"
                                                                      label="Select"
                                                                      checked={
                                                                        item[
                                                                          "is_selected"
                                                                        ]
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        this.handleMandateCheck(
                                                                          e,
                                                                          item
                                                                        );
                                                                      }}
                                                                      defaultChecked={
                                                                        item[
                                                                          "is_primary"
                                                                        ]
                                                                      }
                                                                      disabled={
                                                                        item[
                                                                          "is_cancelled"
                                                                        ] ||
                                                                        !item[
                                                                          "is_processed"
                                                                        ] ||
                                                                        !item[
                                                                          "is_active"
                                                                        ]
                                                                      }
                                                                    />
                                                                  </Form.Group>
                                                                </td>
                                                              )}
                                                            </tr>
                                                          </table>
                                                        </ListGroup.Item>
                                                      ))}
                                                    </ListGroup>
                                                  ) : (
                                                    <div>
                                                      {this.state
                                                        .list_pending_mandate
                                                        .length === 0 ? (
                                                        <p>
                                                          <span
                                                            style={{
                                                              width: 400,
                                                              fontSize: 12,
                                                              backgroundColor:
                                                                "#d9b998",
                                                              padding: 4,
                                                              paddingLeft: 8,
                                                              paddingRight: 8,
                                                              margin: 4,
                                                              borderRadius: 4,
                                                            }}
                                                          >
                                                            Kindly register new
                                                            mandate.
                                                          </span>
                                                        </p>
                                                      ) : (
                                                        <p>
                                                          <span
                                                            style={{
                                                              width: 400,
                                                              fontSize: 12,
                                                              backgroundColor:
                                                                "#d9b998",
                                                              padding: 4,
                                                              paddingLeft: 8,
                                                              paddingRight: 8,
                                                              margin: 4,
                                                              borderRadius: 4,
                                                            }}
                                                          >
                                                            You have created an
                                                            mandate recently.
                                                          </span>
                                                          <hr />
                                                          <a
                                                            role="button"
                                                            style={{
                                                              fontSize: 16,
                                                              color: "#e05d38",
                                                              textDecoration:
                                                                "underline",
                                                            }}
                                                            onClick={() => {
                                                              this.setState({
                                                                nach_option:
                                                                  "new",
                                                              });
                                                            }}
                                                          >
                                                            Click here
                                                          </a>
                                                          <span
                                                            style={{
                                                              whiteSpace:
                                                                "pre-wrap",
                                                              overflowWrap:
                                                                "break-word",
                                                            }}
                                                          >
                                                            {`  `} to complete
                                                            mandate upload
                                                            process.
                                                          </span>
                                                        </p>
                                                      )}
                                                    </div>
                                                  )}
                                                </div>
                                              )}

                                              <div class="row">
                                                <div class="col">
                                                  <Form.Group
                                                    className="mb-3"
                                                    controlId="formBasicCheckbox"
                                                  >
                                                    {this.state
                                                      .mandate_date_validation[
                                                      "message_text"
                                                    ].length > 15 && (
                                                      <div>
                                                        <p
                                                          style={{
                                                            color: "red",
                                                          }}
                                                        >
                                                          {
                                                            this.state
                                                              .mandate_date_validation[
                                                              "message_text"
                                                            ]
                                                          }
                                                        </p>

                                                        <Form.Check
                                                          type="checkbox"
                                                          label="Is First Order Today"
                                                          onChange={(e) =>
                                                            this.setState({
                                                              is_first_order_today:
                                                                !this.state
                                                                  .is_first_order_today,
                                                            })
                                                          }
                                                          defaultChecked={
                                                            this.state
                                                              .is_first_order_today
                                                          }
                                                          disabled={true}
                                                        />
                                                      </div>
                                                    )}
                                                  </Form.Group>
                                                </div>
                                              </div>
                                            </div>
                                          }

                                          {this.state.nach_option === "new" &&
                                            this.state.is_mandate_registered &&
                                            !this.state
                                              .nach_option_new_processed && (
                                              <div>
                                                {!this.state
                                                  .is_enach_mandate && (
                                                  <div class="row">
                                                    <div class="col">
                                                      <div class="row">
                                                        <p
                                                          style={{
                                                            textAlign: "left",
                                                          }}
                                                        >
                                                          <b>Step 1</b>.
                                                          {this.state
                                                            .is_nach_form_download_url_new_window ===
                                                          true ? (
                                                            <button
                                                              type="button"
                                                              className="btn-sm btn-primary"
                                                              style={{
                                                                color: "white",
                                                                width: "140px",
                                                              }}
                                                              onClick={() => {
                                                                this.downloadDocument(
                                                                  this.state
                                                                    .nach_form_download_url,
                                                                  this.state
                                                                    .selected_mandate_id +
                                                                    `.pdf`,
                                                                  4500,
                                                                  this.state
                                                                    .is_nach_form_download_url_new_window
                                                                );
                                                              }}
                                                            >
                                                              Download Mandate
                                                            </button>
                                                          ) : (
                                                            <a
                                                              className="btn-sm btn-success"
                                                              // variant="success"
                                                              download={
                                                                this.state
                                                                  .selected_mandate_id +
                                                                `.pdf`
                                                              }
                                                              disabled={
                                                                this.state
                                                                  .isloading
                                                              }
                                                              href={
                                                                this.state
                                                                  .nach_form_download_url
                                                              }
                                                              onClick={() => {
                                                                this.setState({
                                                                  isloading: true,
                                                                });
                                                                setTimeout(
                                                                  () => {
                                                                    this.setState(
                                                                      {
                                                                        isloading: false,
                                                                      }
                                                                    );
                                                                  },
                                                                  4500
                                                                );
                                                              }}
                                                            >
                                                              Download Mandate
                                                            </a>
                                                          )}
                                                          {/* <Button
                                                    className="btn btn-success btn-sm"
                                                    variant="success"
                                                    onClick={() =>
                                                      this.downloadNachForm()
                                                    }
                                                  >
                                                    Download Mandate
                                                  </Button> */}
                                                        </p>
                                                        <p
                                                          style={{
                                                            textAlign: "left",
                                                          }}
                                                        >
                                                          <b>Step 2</b>. Put
                                                          your signature above
                                                          your name
                                                        </p>
                                                        <p
                                                          style={{
                                                            textAlign: "left",
                                                          }}
                                                        >
                                                          <b>Step 3</b>. Scan or
                                                          Take photo and sava as
                                                          png/jpeg format. Do
                                                          not tilt the image
                                                          while uploading.
                                                        </p>
                                                        <p
                                                          style={{
                                                            textAlign: "left",
                                                          }}
                                                        >
                                                          File name should be
                                                          strictly as
                                                          <span
                                                            style={{
                                                              fontWeight:
                                                                "bold",
                                                              color:
                                                                "darkgreen",
                                                            }}
                                                          >
                                                            {
                                                              //this.state.selected_mandate_id +
                                                              ` ` +
                                                                this.state
                                                                  .list_pending_mandate[0][
                                                                  "mandate_id"
                                                                ] +
                                                                `.png`
                                                            }
                                                          </span>
                                                          {` `}
                                                          <span
                                                            style={{
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            or
                                                          </span>
                                                          <span
                                                            style={{
                                                              fontWeight:
                                                                "bold",
                                                              color:
                                                                "darkgreen",
                                                            }}
                                                          >
                                                            {` ` +
                                                              this.state
                                                                .list_pending_mandate[0][
                                                                "mandate_id"
                                                              ] +
                                                              ".jpeg"}
                                                            {` `}
                                                          </span>
                                                        </p>
                                                        <Form.Control
                                                          type="file"
                                                          onChange={
                                                            this.changeHandler
                                                          }
                                                        />
                                                      </div>

                                                      {this.state
                                                        .src_nachmandateFile && (
                                                        <div class="row">
                                                          <div class="col-6">
                                                            <img
                                                              width="150"
                                                              src={
                                                                this.state[
                                                                  "src_nachmandateFile"
                                                                ]
                                                              }
                                                            />
                                                          </div>
                                                          <div class="col-6">
                                                            <br />
                                                            <Button
                                                              className="btn btn-success btn-sm"
                                                              variant="success"
                                                              onClick={() => {
                                                                this.onUploadMandate(
                                                                  "main"
                                                                );
                                                              }}
                                                              disabled={
                                                                this.state
                                                                  .isloading
                                                              }
                                                            >
                                                              Upload Mandate
                                                            </Button>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                )}

                                                <div class="row">
                                                  {this.state.src_nach_form
                                                    .length > 255 && (
                                                    <div class="col">
                                                      <div
                                                        style={{
                                                          marginLeft: -40,
                                                        }}
                                                      >
                                                        <u>
                                                          Your Mandate File
                                                          Sample
                                                        </u>
                                                        <PDFReader
                                                          data={atob(
                                                            this.state
                                                              .src_nach_form
                                                          )}
                                                          width="350"
                                                          height="350"
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                        </div>
                                      )
                                  }
                                </Col>
                              </Form.Group>
                            }
                          </div>
                        </div>

                        {this.state.render_pdf !== null && (
                          <PDFReader
                            data={this.state.render_pdf}
                            width="100px"
                            height="100px"
                          />
                        )}
                      </div>
                    )}

                  {this.state.wizard_step === 4 &&
                    ((this.state.nominee_option === "existing" &&
                      this.state.existing_nominee_selected) ||
                      (this.state.nominee_option === "none" &&
                        this.state.is_no_nominee_registered)) && (
                      <div className="card-body">
                        {((this.state.nach_option === "existing" &&
                          this.state.is_mandate_selected) ||
                          (this.state.nach_option === "new" &&
                            this.state.nach_option_new_processed)) && (
                          <div style={{ margin: 20 }}>
                            <div class="row">
                              {/* {this.state.nach_option === "new" && ( */}
                              <h5 className="card-title">
                                {
                                  this.state
                                    .nach_option_new_processed_message_text
                                }
                              </h5>
                              {/* )} */}
                            </div>
                            <div class="row">
                              <span
                                style={{
                                  backgroundColor: "#dbcec5",
                                  padding: 4,
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                  margin: 4,
                                  borderRadius: 4,
                                }}
                              >
                                SIP amount of
                                <span
                                  style={{
                                    fontFamily: "sans-serif",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {" "}
                                  &#8377;
                                </span>
                                {this.state.sip_order["sip_amount"]} will be
                                debited from primary account on
                                <b>
                                  {" "}
                                  {/* {this.fnDateOrdinalformat(this.state.sip_order['sip_date']).split(' ')[0]} */}
                                  {this.state.sip_order["sip_date_value"]}
                                </b>
                                {/* {` of every `} */}
                                {/* {this.state.sip_order['sip_frequency'].toLowerCase().replace('ly', '')} */}
                              </span>
                            </div>
                            <br />

                            {this.state.is_otp_enabled ? (
                              <div>
                                <div class="row">
                                  <div class="col">
                                    <Form.Group
                                      className="mb-3"
                                      controlId="exampleForm.ControlInput1"
                                    >
                                      <label>
                                        Enter OTP received on your registered
                                        mobile
                                      </label>
                                      <OTPInput
                                        value={
                                          this.state.two_factor_authentication[
                                            "mobile_otp"
                                          ]
                                        }
                                        onChange={this.handle2FactorChange(
                                          "mobile_otp",
                                          "two_factor_authentication"
                                        )}
                                        autoFocus
                                        OTPLength={6}
                                        otpType="number"
                                        secure
                                        isInputNum={true}
                                      />
                                    </Form.Group>
                                  </div>
                                </div>
                                <br />
                                <Form.Group className="mb-3">
                                  <label>
                                    Enter OTP received on your registered email
                                  </label>
                                  <OTPInput
                                    value={
                                      this.state.two_factor_authentication[
                                        "email_otp"
                                      ]
                                    }
                                    onChange={this.handle2FactorChange(
                                      "email_otp",
                                      "two_factor_authentication"
                                    )}
                                    OTPLength={6}
                                    otpType="number"
                                    secure
                                    isInputNum={true}
                                  />
                                </Form.Group>
                                <div class="row">
                                  <ResendOTP
                                    maxTime={120}
                                    renderButton={renderButton}
                                    style={{ color: "black", marginTop: 20 }}
                                    renderTime={renderTime}
                                    onResendClick={(e) => {
                                      this.sendOTP();
                                    }}
                                  />
                                </div>
                                <hr />
                                <div class="row">
                                  <div class="col" style={{ marginBottom: 30 }}>
                                    <button
                                      className="btn btn-success btn-sm"
                                      onClick={() => {
                                        this.setState({
                                          show_modal_confirm_dialog: true,
                                        });

                                        // const confirmBox = window.confirm(
                                        //   "Are you sure to confirm your sip order ?"
                                        // )
                                        // if (confirmBox === true) {
                                        //   this.onCreateSipOrder();
                                        // }
                                      }}
                                      disabled={
                                        this.state.isloading ||
                                        (
                                          this.state.two_factor_authentication[
                                            "mobile_otp"
                                          ] || ""
                                        ).length < 6
                                      }
                                    >
                                      Confirm Order
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div class="row">
                                <div class="col">
                                  <Button
                                    className="btn btn-success btn-block"
                                    variant="success"
                                    onClick={() => {
                                      this.sendOTP();
                                    }}
                                    disabled={
                                      (this.state.mandate_date_validation[
                                        "message_text"
                                      ].length > 15 &&
                                        !this.state.is_first_order_today) ||
                                      this.state.isloading
                                    }
                                  >
                                    Confirm Sip Order
                                  </Button>

                                  {this.state.isloading && (
                                    <Spinner
                                      animation="border"
                                      variant="dark"
                                      style={{
                                        position: "fixed",
                                        top: "50%",
                                        left: "50%",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        {this.state.is_nach === false && (
                          <div class="row">
                            <p>
                              {" "}
                              {this.state.sip_information_text}{" "}
                              {this.state.sip_order["sip_date"].toISOString()}{" "}
                              of every{" "}
                              {this.state.sip_order[
                                "sip_frequency"
                              ].toLowerCase()}
                            </p>

                            <Button
                              className="btn btn-success btn-block"
                              variant="success"
                              onClick={() => {
                                this.onCreateSipOrder();
                              }}
                              disabled={this.state.isloading}
                            >
                              Confirm Sip Order
                            </Button>
                          </div>
                        )}
                      </div>
                    )}

                  <Modal
                    show={this.state.show_upload_popup}
                    onHide={() => {
                      this.setState({
                        modal_upload_mandate: {},
                        show_upload_popup: false,
                      });
                    }}
                  >
                    <Modal.Header>
                      <h5>Re-Upload Mandate</h5>
                    </Modal.Header>
                    <Modal.Body>
                      {this.state.modal_upload_mandate["mandate_id"] && (
                        <div class="row">
                          {this.state.isloading && (
                            <Spinner
                              animation="border"
                              variant="dark"
                              style={{
                                position: "fixed",
                                top: "50%",
                                left: "50%",
                              }}
                            />
                          )}
                          <p
                            style={{
                              textAlign: "left",
                            }}
                          >
                            <span>
                              <b>Step 1</b>.
                              {this.state
                                .is_nach_form_download_url_new_window ===
                              true ? (
                                <button
                                  type="button"
                                  className="btn-sm btn-primary"
                                  style={{ color: "white", width: "140px" }}
                                  onClick={() => {
                                    this.downloadNachForm(
                                      this.state.nach_form_download_url,
                                      4500
                                    );
                                  }}
                                >
                                  Download Mandate
                                </button>
                              ) : (
                                <a
                                  className="btn btn-success btn-sm"
                                  variant="success"
                                  download={
                                    this.state.modal_upload_mandate[
                                      "mandate_id"
                                    ] + `.pdf`
                                  }
                                  disabled={this.state.isloading}
                                  href={
                                    this.state.modal_upload_mandate[
                                      "nach_form_download_url"
                                    ]
                                  }
                                  onClick={() => {
                                    this.setState({ isloading: true });
                                    setTimeout(() => {
                                      this.setState({ isloading: false });
                                    }, 4500);
                                  }}
                                >
                                  Download Mandate
                                </a>
                              )}
                            </span>
                            <p>
                              <b>Step 2</b>. Put your signature above your name
                            </p>
                            <p>
                              <b>Step 3</b>. Scan or Take photo & save as
                              png/jpeg format. Do not tilt the image while
                              uploading.
                            </p>
                          </p>
                          <p
                            style={{
                              textAlign: "left",
                            }}
                          >
                            File name should be strictly as {` `}
                            <span
                              style={{ fontWeight: "bold", color: "darkgreen" }}
                            >
                              {
                                //this.state.selected_mandate_id +
                                ` ` +
                                  this.state.modal_upload_mandate[
                                    "mandate_id"
                                  ] +
                                  `.png`
                              }
                            </span>
                            {` `}
                            or
                            <span
                              style={{ fontWeight: "bold", color: "darkgreen" }}
                            >
                              {` ` +
                                this.state.modal_upload_mandate["mandate_id"] +
                                ".jpeg"}
                              {` `}
                            </span>
                          </p>
                          <Form.Control
                            type="file"
                            onChange={this.changeHandlerModal}
                          />
                        </div>
                      )}

                      {this.state.src_modal_nachmandateFile && (
                        <div class="row">
                          <div class="col-6">
                            <img
                              width="150"
                              src={this.state["src_modal_nachmandateFile"]}
                            />
                          </div>
                          <div class="col-6">
                            <br />
                            <Button
                              className="btn btn-success btn-sm"
                              variant="success"
                              onClick={() => {
                                this.onUploadMandate("modal");
                              }}
                              disabled={this.state.isloading}
                            >
                              Upload Mandate
                            </Button>
                          </div>
                        </div>
                      )}
                    </Modal.Body>
                    {!this.state.isloading && (
                      <Modal.Footer>
                        <Button
                          variant="primary"
                          onClick={() => {
                            this.setState({
                              modal_upload_mandate: {},
                              show_upload_popup: false,
                            });
                          }}
                        >
                          Undo
                        </Button>
                      </Modal.Footer>
                    )}
                  </Modal>

                  {!this.state.isloading && this.state.wizard_step > 0 && (
                    <Fab
                      style={{ bottom: 22, left: -15, transform: "scale(0.8)" }}
                      // event="hover"
                      alwaysShowTitle={true}
                      mainButtonStyles={{
                        backgroundColor: "#d45950",
                      }}
                      icon={<i className="fa fa-arrow-left  fa-lg"></i>}
                      onClick={() => {
                        if (this.state.wizard_step > 1) {
                          /*if(this.state.wizard_step === 3 && this.state.is_mandate_selected) 
                          {
                            this.setState({
                                is_mandate_selected: false
                            });
                          }
                          else*/
                          this.setState({
                            wizard_step: this.state.wizard_step - 1,
                          });
                        } else this.props.history.goBack();
                      }}
                    ></Fab>
                  )}

                  {!this.state.isloading &&
                    (this.state.wizard_step == 1 ||
                      (this.state.wizard_step == 2 &&
                        ((this.state.nominee_option === "existing" &&
                          this.state.existing_nominee_selected) ||
                          (this.state.nominee_option === "none" &&
                            this.state.is_no_nominee_registered))) ||
                      (this.state.wizard_step === 3 &&
                        this.state.is_mandate_selected)) &&
                    this.state.nach_option === "existing" && (
                      <Fab
                        style={{
                          bottom: 22,
                          right: -18,
                          transform: "scale(0.8)",
                        }}
                        // event="hover"
                        alwaysShowTitle={true}
                        mainButtonStyles={{
                          backgroundColor: "#2ea34d",
                        }}
                        icon={<i className="fa fa-arrow-right  fa-lg"></i>}
                        onClick={() =>
                          this.setState({
                            wizard_step: this.state.wizard_step + 1,
                          })
                        }
                      ></Fab>
                    )}
                </div>

                {this.state.wizard_step == 2 &&
                  this.state.show_nominee_form_modal && (
                    <Modal
                      show={this.state.show_nominee_form_modal}
                      onHide={() =>
                        this.setState({
                          show_nominee_form_modal: false,
                        })
                      }
                      size="lg"
                      centered
                      keyboard={false}
                      fullscreen={true}
                      backdrop="static"
                    >
                      <Modal.Header></Modal.Header>
                      <Modal.Body>
                        <div style={{ marginLeft: -50 }}>
                          <PDFReader
                            data={atob(this.state.modal_nominee["form"])}
                            width="390"
                          />
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="primary"
                          onClick={() =>
                            this.setState({
                              show_nominee_form_modal: false,
                            })
                          }
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  )}

                {this.state.show_modal_confirm_dialog && (
                  <Modal
                    show={this.state.show_modal_confirm_dialog}
                    centered
                    keyboard={false}
                    backdrop="static"
                  >
                    <Modal.Header></Modal.Header>
                    <Modal.Body>
                      <p>{this.state["modal_confirm_dialog_messagetext"]}</p>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="primary"
                        onClick={() => this.onCreateSipOrder()}
                      >
                        Yes
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() =>
                          this.setState({
                            show_modal_confirm_dialog: false,
                          })
                        }
                      >
                        No
                      </Button>
                    </Modal.Footer>
                  </Modal>
                )}

                <Modal show={this.state.show_confirm_popup} centered>
                  <Modal.Header>
                    <Modal.Title></Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {this.state.confirm_popup_messageText}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        if (
                          this.state.confirm_popup_request_mode ===
                          "cancel mandate"
                        )
                          this.onCancelMandate(
                            this.state.confirm_popup_mandate_info
                          );
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        this.setState({
                          show_confirm_popup: false,
                          confirm_popup_messageText: ``,
                          confirm_popup_request_mode: ``,
                          confirm_popup_mandate_info: {},
                        });
                      }}
                    >
                      No
                    </Button>
                  </Modal.Footer>
                </Modal>

                {this.state.show_popup && (
                  <Modal
                    show={this.state.show_popup}
                    onHide={() => this.closePopup()}
                    centered
                  >
                    <Modal.Header></Modal.Header>
                    <Modal.Body>
                      <p>{this.state.popup_messageText}</p>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="primary"
                        onClick={() => this.closePopup()}
                      >
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                )}
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}
// OrderCheckout.propTypes = {
//     validateOtp: PropTypes.func.isRequired,
//     isAuthenticated: PropTypes.bool
// };

/*
const mapStateToProps = state => ({
    loading: state.order.loading,
    order_result: state.order.order_result
});
*/

const mapStateToProps = ({ order, general, auth }) => {
  const { loading, order_result } = order;
  const { general_result } = general;
  const { payment_initiate_result } = auth;
  return {
    loading,
    order_result,
    general_result,
    payment_initiate_result,
  };
};

export default connect(mapStateToProps, {
  createSipOrder,
  uploadMandate,
  registerMandate,
  loadBankAccountList,
  loadNachMandateForm,
  loadNomineeList,
  refreshMandateStatus,
  cancelMandate,
  twoFactorAuthentication,
  downloadNominee,
  downloadFile,
  eNachMandate,
})(SipCheckout);
