import React from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { Button, Modal, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  modifyOrder,
  additionalOrder,
  redeem2FactorOrder,
  twoFactorAuthentication
} from "../../actions/orders";
import Select from "react-select";
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";

import OTPInput, { ResendOTP } from "otp-input-react";
import { PDFReader } from 'reactjs-pdf-reader';
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { Fab, Action } from 'react-tiny-fab';

class SipEditCheckout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      request_mode: "",
      selected_order: {},
      bank_information: {},
      loading: false,
      render_pdf: null,
      enachmandateFile: null,
      src_enachmandateFile: null,
      byte_enachmandateFile: null,
      invest_more_amount: 0.0,
      redeemable_amount: 0.0,
      is_redeem_full_checked: false,
      is_activity_completed: false,
      nofitication_html: ``,
      isloading: false,
      is_redeem_button_clicked: false,
      mobile_otp: '',
      email_otp: '',
      twofactor_token_id: '',
      pay_bank_mode_list: [],
      selected_pay_bank_mode: '',

      /****** NOMINEE OPTIONS *******/
      list_nominee: [],
      nominee_option: 'existing',
      existing_nominee_id: '',
      existing_nominee_selected: false,
      is_no_nominee_registered: false,
      no_nominee_form: {},
      nonomineeformFile: null,
      src_nonomineeformFile: null,
      byte_nonomineeformFile: null,
      client_code: '',

      /****** NOMINEE OPTIONS *******/

      is_otp_enabled: false,
      two_factor_authentication: {
        mobile_otp: '',
        email_otp: '',
        twofactor_token_id: ''
      },

      wizard_step: 1,
      show_previous_button: false,
      show_next_button: false,

      show_popup: false,
      pop_messagetext: ``,

    };
  }

  componentDidMount() {

    window.addEventListener("scroll", () => {

      if (document.getElementById("navbar-orderlist-container") &&
        document.getElementById("navbar") &&
        document.getElementById("navbar").classList) {
        const topBorder = document
          .getElementById("navbar-orderlist-container")
          .getBoundingClientRect().top;

        topBorder >= 0
          ? document.getElementById("navbar").classList.remove("fixed-orderlist")
          : document.getElementById("navbar").classList.add("fixed-orderlist");
      }

    });

    if (this.props.match && this.props.match.path === '/order2factorCheckout/:redeem_id') { }
    else {

      if (this.props.location.state) {

        let _selected_order = this.props.location.state.selected_order;
        _selected_order['selected_pay_bank_mode'] = '';
        _selected_order['upi_id'] = '';

        _selected_order['redeemable_minimum_amount'] = _selected_order['mf_order_minimum_redeemable_amount'];
        _selected_order['redeemable_maximum_amount'] = _selected_order['mf_order_maximum_redeemable_amount'];

        /*
        _selected_order['redeemable_maximum_amount'] =
          _selected_order['mf_order_scheme_specifications'] && _selected_order['mf_order_scheme_specifications']['redemption'] ?
            (_selected_order['mf_order_scheme_specifications']['redemption']['maximum_amount'] ||
              (_selected_order["mf_order_redeemable_amount"] || 0))
            : (_selected_order["mf_order_redeemable_amount"] || 0);

        _selected_order['redeemable_minimum_amount'] = _selected_order['mf_order_scheme_specifications']['redemption']['minimum_amount'];
        */

        this.setState({
          request_mode: this.props.location.state.request_mode || _selected_order["request_mode"],
          selected_order: _selected_order,
          bank_information: _selected_order["bank_information"],
          pay_bank_mode_list: _selected_order['pay_bank_mode_list'],
          list_nominee: _selected_order['nominee_list']
        });

      }

    }

  }

  componentWillUnmount() {
    window.removeEventListener('scroll', null);
  }

  onInvestOrder = () => {

    this.setState({ isloading: true });

    this.props
      .additionalOrder({
        client_info: {
          buy_sell: "P",
          buy_sell_type: "ADDITIONAL",
          order_id: this.state.selected_order["_id"],
          order_mode: "MOD",
          order_amount: this.state.invest_more_amount,
          nominee_option: ``
        },
        two_factor_authentication: this.state.two_factor_authentication
      })
      .then((response) => {

        this.setState({ isloading: false });

        if (this.props.order_result["code"] !== "000")
          alert(this.props.order_result["messageText"]);
        else {

          if (this.props.order_result["result"]) {

            let _selected_order = this.props.order_result["result"];
            _selected_order['selected_pay_bank_mode'] = '';
            _selected_order['upi_id'] = '';

            this.setState({
              request_mode: _selected_order["request_mode"],
              selected_order: _selected_order,
              bank_information: _selected_order["bank_information"],
              pay_bank_mode_list: _selected_order['pay_bank_mode_list']
            });
          }

        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  onRedeem2FactorOrder = () => {

    this.setState({ isloading: true });

    this.props
      .redeem2FactorOrder({
        client_info: {
          buy_sell: "R",
          order_id: this.state.selected_order["_id"],
          order_number: this.state.selected_order["mf_order_number"],
          order_mode: "MOD",
          order_amount: this.state.redeemable_amount,
          request_action: 'redeem order',
          is_otp_email_required: true
        },
      })
      .then((response) => {

        if (this.props.order_result["code"] !== "000") {
          this.setState({ isloading: false });
          alert(this.props.order_result["messageText"]);
        }
        else {

          let _result = this.props.order_result; //["result"];

          if (_result['twofactor_id'])
            this.setState({
              isloading: false,
              twofactor_token_id: _result['twofactor_id'],
              is_redeem_button_clicked: true
              // selected_order: _result,
            });
        }
      })
      .catch((err) => {
        alert(err);
      });

  };

  onRedeemOrder = () => {

    this.setState({ isloading: true });

    this.props
      .modifyOrder({
        client_info: {
          buy_sell: "R",
          buy_sell_type: `FRESH`, // FRESH / ADDITIONAL
          order_id: this.state.selected_order["_id"],
          order_mode: "MOD",
          order_amount: this.state.redeemable_amount,
        },
        twofactor_token_id: this.state.twofactor_token_id,
        mobile_otp: this.state.mobile_otp,
        email_otp: this.state.email_otp
      })
      .then((response) => {

        this.setState({
          isloading: false,
          // selected_order: this.props.order_result["result"],
          is_redeem_button_clicked: false,
          show_popup: true,
          pop_messagetext: this.props.order_result["messageText"]
        });

      })
      .catch((err) => {
        alert(err);
      });

  };

  sendOTP = () => {

    this.setState({ isloading: true });

    this.props
      .twoFactorAuthentication({
        client_info: {
          two_factor_authentication: this.state.two_factor_authentication,
          request_action: 'single order registration',
          is_otp_email_required: false
        },
      })
      .then((response) => {

        if (this.props.order_result["code"] !== "000") {
          this.setState({ isloading: false });
          alert(this.props.order_result["messageText"]);
        }
        else {

          let _result = this.props.order_result; //["result"];

          if (_result && _result['twofactor_id']) {

            this.setState({
              isloading: false,
              is_otp_enabled: true,
              two_factor_authentication: {
                mobile_otp: '',
                email_otp: '',
                twofactor_token_id: _result['twofactor_id']
              }
            });

          }

        }
      })
      .catch((err) => {
        alert(err);
      });

  }

  handleConfirmCheck = (event) => {

    let is_checked = !this.state.is_redeem_full_checked;
    this.setState({
      is_redeem_full_checked: is_checked,
      redeemable_amount: is_checked
        ? this.state.selected_order["redeemable_maximum_amount"]
        : 0.0,
    });
  };

  handleInvestAmount(event, attribute) {

    const re = /^\d+(\.\d{0,3})?$/; ///^\d*\.?\d*$/ ///^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      this.setState({
          /*invest_more_amount*/[attribute]: event.target.value.replace(
        /\b0+/g,
        ""
      ),
      });
    }

  }

  handleNomineeCheck = (e, item) => {

    let _existing_nominee_selected = false, _existing_nominee_id = '';
    const _list = this.state.list_nominee;
    _list.forEach(el => {

      if (el['id'] === item['id']) {

        item['is_selected'] = !item['is_selected'];
        _existing_nominee_selected = item['is_selected'];

        if (_existing_nominee_selected) {
          _existing_nominee_id = item['id'];
        }
        else
          _existing_nominee_id = '';
      }
      else
        item['is_selected'] = false;

    });

    this.setState({
      list_nominee: _list,
      existing_nominee_selected: _existing_nominee_selected,
      existing_nominee_id: _existing_nominee_id
    });

  }

  createDefaultMarkup(html_text) {

    return {
      __html: html_text,
    };

  }

  gotoPaymentPage() {

    this.props.history.push({
      pathname: "/paymentPage/",
      state: { selected_order: this.state.selected_order },
    });
  }

  gotoOrderListPage() {
    this.props.history.push({
      pathname: "/orderList/",
    });
  }

  handle2FactorChange = (input, attribute) => e => {

    if (e)
      this.setState({
        [attribute]: { ...this.state[attribute], [input]: e }
      });
    else
      this.setState({ [input]: e });

  }

  closePopup = () => {
    if (this.props.order_result['code'] !== '000') {
      this.setState({
        mobile_otp: ``,
        email_otp: ``,
        show_popup: false,
        pop_messagetext: ``,
      });
    }
    else
      this.setState({
        show_popup: false,
        pop_messagetext: ``,
      });
  };

  render() {

    const renderButton = buttonProps => {
      return (
        <div className="row">
          <div className="col-6">
            <button onClick={this.sendOTP} {...buttonProps}
              class={parseInt(buttonProps.remainingTime) > 0 ? `btn btn-outline-secondary` : `btn btn-outline-success`}>
              {`Resend OTP`}
            </button>
          </div>
          <div className="col-5">
            {buttonProps.remainingTime !== 0
              ? <span>You can resend otp in
                <span style={{ fontWeight: 'bold', color: 'green' }}>{' '}{buttonProps.remainingTime}</span> secs.</span>
              : `Use "Resend OTP" button now`}
          </div>

        </div>
      );
    };

    const renderTime = () => React.Fragment;
    return (
      <>
        <Header1 />
        <Sidebar />

        <div className="content-body">
          <div className="container" style={{ marginBottom: 50 }}>
            <div className="row">

              <div id="navbar-orderlist-container">
                <div id="navbar" >
                  <div className="card" style={{ backgroundColor: '#f8f8fb' }}>
                    <div className="card-header">
                      <span >
                        <span style={{ fontSize: 16 }}>
                          {this.state.request_mode === "redeem" ? 'Redeem Order ' :
                            (this.state.request_mode === "invest_more" ? `Additional Order` : 'New Order')}
                        </span>
                        {this.state.request_mode === "redeem" &&
                          <span style={{
                            fontWeight: 'bold', backgroundColor: '#f0e7e6', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                          }} >
                            Folio# {" "}
                            {
                              this.state.selected_order[
                              "mf_order_folio_no"
                              ]
                            }
                          </span>
                        }
                      </span>
                    </div>

                    <div class="row" style={{ marginTop: '-15px' }}>
                      <hr />
                    </div>
                  </div>

                </div>
              </div>

              <div style={{ marginTop: 60 }}>

                {this.state.isloading && (
                  <Spinner
                    animation="border"
                    variant="dark"
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                      zIndex: 899,
                    }}
                  />
                )}

                {!this.props.location.state &&
                  <Card>
                    <CardContent  >
                      <Stack direction="row">
                        <Typography flex={1} whiteSpace="pre-line" >
                          Sorry the information you are looking for could not be found
                          <p></p>
                          <Link className="page-back text-muted" to={'/orderList'}><span><i
                            className="fa fa-angle-left"></i></span> Go to Order List</Link>

                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                }

                {this.state.selected_order[
                  "mf_order_unique_reference_no"
                ] && (
                    <div>
                      
                          {this.state.selected_order["mf_order_scheme_name"] || ``}
                          {` | `}
                          <b>{` Last Nav : `}</b>
                          <span style={{
                            fontWeight: 'bold', backgroundColor: '#f0e7e6', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                          }}>
                            {this.state.selected_order['nav_information'][0]['data'][0]['nav']}
                          </span>

                          <table style={{ width: '100%' }}>
                            <tr>
                              <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                <span>
                                  Lockin Period Enabled?
                                </span>
                              </td>
                              <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                <span>
                                  {this.state.selected_order['lockin_period_flag'] === 'Y' ? 'YES' : 'NO'}
                                </span>
                              </td>
                              {this.state.selected_order['lockin_period_flag'] === 'Y' &&
                                <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                  <span>
                                    Period
                                  </span>
                                </td>
                              }
                              {this.state.selected_order['param_exit_load_flag'] === 'Y' &&
                                <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                  <span>
                                    {this.state.selected_order['param_exit_load']}
                                  </span>
                                </td>
                              }
                            </tr>
                            <tr>
                              <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                <span>
                                  Exit Load Enabled?
                                </span>
                              </td>
                              <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                <span>
                                  {this.state.selected_order['param_exit_load_flag'] === 'Y' ? 'YES' : 'NO'}
                                </span>
                              </td>
                              {this.state.selected_order['param_exit_load_flag'] === 'Y' &&
                                <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                  <span>
                                    Value
                                  </span>
                                </td>
                              }
                              {this.state.selected_order['param_exit_load_flag'] === 'Y' &&
                                <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                  <span>
                                    {this.state.selected_order['param_exit_load']}
                                  </span>
                                </td>
                              }
                            </tr>
                          </table>
                          
                          {this.state.request_mode === "redeem" &&
                          <table style={{ width: '100%' }}>
                              <div  style={{ height:5 }}>
                              </div>
                              <tr>
                                <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                  <span>
                                    Total Investment
                                  </span>
                                </td>
                                <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                  <span style={{ fontFamily: 'sans-serif' }}>&#8377;{
                                    this.state.selected_order[
                                    "mf_order_overall_investment_amount"
                                    ]
                                  }
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                                  <span>
                                    Total Returns
                                  </span>
                                </td>
                                <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                                  <span style={{ fontFamily: 'sans-serif' }}>&#8377;{
                                    this.state.selected_order[
                                    "total_returns_amount"
                                    ]
                                  }
                                  </span>
                                </td>
                              </tr>
                          </table>
                          }

                          {
                            (this.state.selected_order['request_mode'] || '') === 'paynow' &&
                            <p>
                              {this.state.selected_order['mf_order_message']}
                            </p>
                          }
                          
                      <div  style={{ height:7 }}>
                      </div>
                      {this.state.bank_information["beneficiary_bank_name"] &&
                        !this.state.is_otp_enabled &&
                        
                          <table style={{ width: '100%' }}>
                          <tr>
                            <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                              <span>
                                Bank Name
                              </span>
                            </td>
                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                              {this.state.bank_information["beneficiary_bank_name"]}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                              <span>
                                Account Number
                              </span>
                            </td>
                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                              {this.state.bank_information["beneficiary_account_no"]}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                              <span>
                                Name
                              </span>
                            </td>
                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                              {this.state.bank_information["beneficiary_account_holder_name"]}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                              <span>
                                Account Type
                              </span>
                            </td>
                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                              {this.state.bank_information["beneficiary_account_type"]}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                              <span>
                                IFSC
                              </span>
                            </td>
                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                              {this.state.bank_information["beneficiary_ifsc"]}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ height: 30, border: '1px solid #e2e0e4', backgroundColor: '#f7f5f5', textAlign: 'left' }}>
                              <span>
                                Branch
                              </span>
                            </td>
                            <td style={{ height: 30, border: '1px solid #e2e0e4', textAlign: 'center' }}>
                              {this.state.bank_information["beneficiary_bank_branch_name"]}
                            </td>
                          </tr>
                          </table>   
                      }

                      {this.state.request_mode === "payment" && (
                        <div class="row">
                          <div class="col">
                            <h6 style={{ fontSize: 12 }}>
                              Investment Amount: Rs.{" "}
                              {
                                this.state.selected_order[
                                "order_information"
                                ]["request"]["OrderVal"]
                              }
                            </h6>
                          </div>
                          <div class="col">
                            {/* {
                                this.state.selected_order['mf_order_scheme_specifications']['exitload']['value']
                              } */}
                          </div>
                        </div>
                      )}

                      {this.state.request_mode === "redeem" && (
                        <div class="row" >
                          <div class="col">
                            {
                              this.state.selected_order['mf_order_scheme_specifications']['exitload']['is_allowed'] &&
                              <p>
                                <span style={{
                                  fontSize: 14, color: 'InfoText', backgroundColor: 'lightgray', paddingLeft: 6, paddingRight: 6, borderRadius: 4
                                }} >
                                  {
                                    `Fee of ` + this.state.selected_order['mf_order_scheme_specifications']['exitload']['value']
                                  }
                                </span>
                              </p>

                            }
                          </div>

                          <hr />

                        </div>
                      )}

                      {this.state.bank_information["beneficiary_bank_name"] && (

                        <div class="row">
                          {this.state.request_mode === "invest_more" && (
                            <div style={{ marginTop: 10 }}>
                              {!(
                                this.state.selected_order[
                                "mf_order_is_activity_completed"
                                ]
                              ) && (
                                  <Card>
                                    {(this.state.wizard_step === 1) &&
                                      <CardContent>
                                        <p>
                                          Do you want to add nominee ?
                                        </p>

                                        <div class="row">
                                          <div class="col">
                                            <Form.Check
                                              type="radio"
                                              label="Yes"
                                              name="nominee_option"
                                              id="existing"
                                              value="existing"
                                              checked={
                                                this.state.nominee_option === "existing"
                                              }
                                              onChange={(e) =>
                                                this.setState({ ['nominee_option']: 'existing' })
                                              }
                                            />
                                          </div>
                                          <div class="col-5">
                                            <Form.Check
                                              type="radio"
                                              label="No"
                                              name="nominee_option"
                                              id="none"
                                              value="none"
                                              checked={
                                                this.state.nominee_option ===
                                                "none"
                                              }
                                              onChange={(e) =>
                                                this.setState({ ['nominee_option']: 'none' })
                                              }
                                              disabled={true}
                                            />
                                          </div>
                                        </div>
                                        <hr />

                                        {
                                          this.state.nominee_option === 'existing' &&

                                          <div class="row">
                                            <table style={{ marginLeft: 15 }}>
                                              <thead>
                                                <tr>
                                                  <th colSpan="5" style={{ fontSize: 12 }}>Name</th>
                                                  <th colSpan="4" style={{ fontSize: 12 }}>Id Proof</th>
                                                  <th colSpan="2" style={{ fontSize: 12 }}>Select</th>
                                                </tr>
                                              </thead>
                                              {(this.state.list_nominee || []).map((item, index) => (

                                                <tbody key={index} >
                                                  <tr>
                                                    <td colSpan={5}>
                                                      <span style={{ fontSize: 13 }}>
                                                        {item["name"]}
                                                        {"("}
                                                        {item['relationship'] || ''}
                                                        {")"}
                                                      </span>
                                                      <br />
                                                      <Button
                                                        className="btn btn-success btn-sm"
                                                        variant="link"
                                                        style={{ fontSize: 12 }}
                                                        onClick={() => {

                                                          this.setState({
                                                            show_nominee_form_modal: true,
                                                            modal_nominee: {
                                                              ...this.state.modal_nominee,
                                                              info: item,
                                                              form: item['nominee_form']
                                                            },

                                                          })
                                                        }}
                                                      >
                                                        Show Form
                                                      </Button>
                                                    </td>
                                                    <td colSpan={4}>
                                                      <span style={{ fontSize: 13 }}>
                                                        {item["poi"]}
                                                        <br />{" ("}
                                                        {item["id_information"]['id_no']}
                                                        {")"}
                                                      </span>

                                                    </td>
                                                    <td colSpan={2}>
                                                      <label>
                                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                          <Form.Check type="checkbox"
                                                            // label="Choose"
                                                            onChange={e => { this.handleNomineeCheck(e, item) }}
                                                            defaultChecked={item['is_selected']}
                                                          />
                                                        </Form.Group>
                                                      </label>
                                                    </td>
                                                  </tr>

                                                </tbody>

                                              ))}

                                            </table>
                                          </div>
                                        }
                                      </CardContent>
                                    }
                                    {(this.state.wizard_step === 2) &&
                                      <CardContent>
                                        <form action="#">
                                          <div class="row">
                                            {this.state.is_otp_enabled ?

                                              <div class="col">
                                                <h6 style={{ fontSize: 14 }}>
                                                  Your Investment Amount is {" "}
                                                  <span style={{ fontFamily: 'Verdana, sans-serif' }}>
                                                    &#8377;
                                                  </span>{" "}
                                                  {
                                                    this.state.invest_more_amount
                                                  }
                                                </h6>
                                              </div>
                                              :
                                              <div class="col">
                                                <h6 style={{ fontSize: 14 }}>
                                                  Minimum Investment Amount {" "}
                                                  <span style={{ fontFamily: 'Verdana, sans-serif' }}>
                                                    &#8377;
                                                  </span>{" "}
                                                  {
                                                    this.state.selected_order[
                                                    "mf_order_minimum_investment_amount"
                                                    ]
                                                  }
                                                </h6>
                                              </div>
                                            }
                                          </div>
                                          <div>
                                            {this.state.is_otp_enabled ?
                                              <div>

                                                <div class="row">
                                                  <div class="col">
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                      <label>Enter OTP received on your registered mobile</label>
                                                      <OTPInput
                                                        value={this.state.two_factor_authentication['mobile_otp']}
                                                        onChange={this.handle2FactorChange('mobile_otp', 'two_factor_authentication')}
                                                        autoFocus
                                                        OTPLength={6}
                                                        otpType="number"
                                                        secure
                                                        isInputNum={true}
                                                      />
                                                    </Form.Group>
                                                  </div>
                                                </div>

                                                <div class="row">
                                                  <div class="col">
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                      <label>Enter OTP received on your registered email</label>
                                                      <OTPInput
                                                        value={this.state.two_factor_authentication['email_otp']}
                                                        onChange={this.handle2FactorChange('email_otp', 'two_factor_authentication')}
                                                        autoFocus
                                                        OTPLength={6}
                                                        otpType="number"
                                                        secure
                                                        isInputNum={true}
                                                      />
                                                    </Form.Group>
                                                  </div>
                                                </div>

                                                <div class="row">
                                                  <ResendOTP maxTime={120} renderButton={renderButton} renderTime={renderTime}
                                                    onResendClick={(e) => { this.sendOTP() }}
                                                  />
                                                </div>
                                                <hr />
                                                <div class="row">
                                                  <div class="col">
                                                    <button
                                                      className="btn btn-success btn-sm"
                                                      onClick={() => {
                                                        this.onInvestOrder(this.props, this)
                                                      }}
                                                      disabled={this.state.isloading || this.state.two_factor_authentication['mobile_otp'].length < 6
                                                        || this.state.two_factor_authentication['email_otp'].length < 6}
                                                    >
                                                      Confirm Order
                                                    </button>
                                                  </div>
                                                </div>

                                              </div>
                                              :
                                              <div>
                                                <div class="row">
                                                  <div class="col">
                                                    <h6 style={{ fontSize: 12 }}>
                                                      Enter the amount{" ("}
                                                      <span style={{ fontFamily: 'Verdana, sans-serif' }}>
                                                        &#8377;
                                                      </span> {") you wish to invest"}
                                                    </h6>
                                                  </div>
                                                </div>
                                                <div class="row">
                                                  <div class="col-6">
                                                    <input
                                                      pattern="[0-9]*"
                                                      maxLength="7"
                                                      type="tel"
                                                      style={{ fontSize: 15, letterSpacing: 2 }}
                                                      className="form-control text-center font-weight-bold"
                                                      value={this.state.invest_more_amount}
                                                      onChange={(event) =>
                                                        this.handleInvestAmount(
                                                          event,
                                                          "invest_more_amount"
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div class="col-6">
                                                    <Button
                                                      className="btn btn-success btn-block"
                                                      variant="success"
                                                      onClick={() =>
                                                        this.sendOTP()
                                                      }
                                                      disabled={
                                                        this.state.invest_more_amount <
                                                        this.state.selected_order[
                                                        "mf_order_minimum_investment_amount"
                                                        ] ||
                                                        this.state.isloading
                                                      }
                                                    >
                                                      Submit Order
                                                    </Button>
                                                  </div>
                                                </div>
                                              </div>
                                            }
                                          </div>

                                        </form>
                                      </CardContent>
                                    }
                                  </Card>
                                )}

                              {(this.state.selected_order[
                                "mf_order_is_activity_completed"
                              ]) && (
                                  <Card>
                                    <CardContent>
                                      <div class="row">
                                        <div
                                          dangerouslySetInnerHTML={this.createDefaultMarkup(
                                            this.state.selected_order[
                                            "mf_order_notification_message"
                                            ]
                                          )}
                                        />
                                      </div>

                                      <div class="row">
                                        <div class="col">
                                          <label class="mr-sm-2">Payment Mode</label>

                                          <Select
                                            value={this.state.pay_bank_mode_list.find(obj => obj.value === this.state.selected_order['selected_pay_bank_mode'])}
                                            onChange={(e) => {
                                              this.setState({
                                                selected_order: { ...this.state.selected_order, selected_pay_bank_mode: e.value }
                                              });
                                            }}
                                            options={this.state.pay_bank_mode_list}
                                          />

                                        </div>
                                      </div>
                                      {this.state.selected_order['selected_pay_bank_mode'] === 'UPI' &&
                                        <div class="row">
                                          <div class="col">
                                            <br />
                                            <label class="mr-sm-2">Enter UPI ID (as per your linked bank account)</label>
                                            <input
                                              type="text"
                                              className="form-control text-center font-weight-bold"
                                              value={this.state.selected_order['upi_id']}
                                              onChange={(event) =>
                                                this.setState({
                                                  selected_order: { ...this.state.selected_order, upi_id: event.target.value }
                                                })
                                              }
                                              disabled={this.state.selected_order['selected_pay_bank_mode'].length < 3}
                                            />
                                          </div>
                                        </div>}
                                      <hr />
                                      <div class="row">
                                        <div class="col">
                                          <Button
                                            className="btn btn-success btn-block"
                                            variant="success"
                                            onClick={() => {
                                              this.gotoPaymentPage();
                                            }}
                                            disabled={
                                              this.state.selected_order['selected_pay_bank_mode'].length < 3 ||
                                              this.state.isloading
                                            }
                                          >
                                            Pay {` `}
                                            <span style={{ fontFamily: 'sans-serif' }}>
                                              &#8377;{
                                                this.state.selected_order["mf_order_pay_amount"]}
                                            </span>
                                          </Button>
                                        </div>
                                      </div>
                                    </CardContent>
                                  </Card>
                                )}
                            </div>
                          )}

                          {this.state.request_mode === "redeem" &&
                            !this.state.selected_order[
                            "mf_order_is_activity_completed"
                            ] && (
                              <form action="#">
                                <div class="row">
                                  <div class="col">
                                    <span style={{ fontSize: 14 }}>Enter Redeem Amount</span>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-6">
                                    <input
                                      type="tel"
                                      pattern="[0-9]*"
                                      maxLength="8"
                                      style={{ letterSpacing: 2 }}
                                      className="form-control text-center font-weight-bold"
                                      value={this.state.redeemable_amount}
                                      onChange={(event) =>
                                        this.handleInvestAmount(
                                          event,
                                          "redeemable_amount"
                                        )
                                      }
                                      disabled={
                                        this.state.is_redeem_full_checked ||
                                        this.state.selected_order[
                                        "mf_order_is_activity_completed"
                                        ] ||
                                        this.state.is_redeem_button_clicked ||
                                        this.state.isloading
                                      }
                                    />
                                  </div>
                                  <div class="col-6">
                                    <span style={{ fontSize: 16 }}>
                                      Min.{` `}
                                      <span style={{ fontFamily: 'sans-serif' }}>
                                        &#8377;
                                      </span>
                                      {this.state.selected_order['redeemable_minimum_amount'] || 0}
                                      <br />
                                      Max.{` `}
                                      <span style={{ fontFamily: 'sans-serif' }}>
                                        &#8377;
                                      </span>
                                      {" "}
                                      {this.state.selected_order['redeemable_maximum_amount'] || 0
                                      }
                                    </span>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col">
                                    <br />
                                    <label>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicCheckbox"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          label="Redeem whole amount"
                                          onChange={this.handleConfirmCheck}
                                          defaultChecked={
                                            this.state.is_redeem_full_checked
                                          }
                                          disabled={this.state.is_redeem_button_clicked}
                                        />
                                      </Form.Group>
                                    </label>
                                  </div>
                                </div>
                                {
                                  this.state.selected_order['mf_order_scheme_specifications']['exitload']['is_allowed'] &&
                                  !isNaN(this.state.redeemable_amount) &&
                                  parseInt(this.state.redeemable_amount) > 0 &&
                                  <div class="row">
                                    <div class="col" >
                                      <span style={{
                                        width: 220, color: 'InfoText', backgroundColor: '#a3bed1', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                      }} >
                                        <span style={{ fontFamily: 'Verdana, sans-serif' }}>
                                          &#8377;
                                        </span>
                                        {
                                          ((parseInt(this.state.redeemable_amount) *
                                            parseFloat(this.state.selected_order['mf_order_scheme_specifications']['exitload']['percent'])) / 100)
                                          + ` will be deducted as fee`
                                        }
                                      </span>
                                    </div>
                                  </div>
                                }
                                {
                                  this.state.selected_order['mf_order_scheme_specifications']['exitload']['is_allowed'] &&
                                  <br />
                                }
                                {
                                  // this.state.selected_order['mf_order_scheme_specifications']['exitload']['is_allowed'] &&    
                                  !isNaN(this.state.redeemable_amount) &&
                                  parseInt(this.state.redeemable_amount) > 0 &&
                                  <div class="row">
                                    <div class="col" >
                                      <span style={{
                                        width: 330, color: 'InfoText', backgroundColor: '#b7cfe8', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4
                                      }} >
                                        <span style={{ fontFamily: 'Verdana, sans-serif' }}>
                                          &#8377;
                                        </span>
                                        {
                                          (parseInt(this.state.redeemable_amount) - (parseInt(this.state.redeemable_amount) *
                                            parseFloat(this.state.selected_order['mf_order_scheme_specifications']['exitload']['percent'])) / 100)
                                          + ` will be credited to your bank account`
                                        }
                                      </span>
                                    </div>
                                  </div>
                                }{
                                  !isNaN(this.state.redeemable_amount) &&
                                  parseInt(this.state.redeemable_amount) > 0 &&
                                  <br />
                                }
                                <div class="row">
                                  <div class="col" >
                                    <Button class="d-flex justify-content-between mt-3"
                                      className="btn btn-success btn-block"
                                      variant="success"
                                      onClick={() =>
                                        this.onRedeem2FactorOrder()
                                      }
                                      disabled={
                                        this.state.redeemable_amount.length === 0 ||
                                        isNaN(this.state.redeemable_amount) ||
                                        // parseInt(this.state.redeemable_amount) < parseInt(this.state.selected_order['redeemable_minimum_amount'] || 0) ||
                                        // parseInt(this.state.redeemable_amount) >= parseInt(this.state.selected_order['mf_order_scheme_specifications']['redemption']['maximum_amount'] ||
                                        // this.state.selected_order['total_returns_amount']) ||
                                        this.state.is_redeem_button_clicked ||
                                        this.state.isloading ||
                                        (parseInt(this.state.redeemable_amount) > parseInt(this.state.selected_order['redeemable_maximum_amount'])) ||
                                        (parseInt(this.state.redeemable_amount) < parseInt(this.state.selected_order['redeemable_minimum_amount']))
                                      }
                                    >
                                      Redeem Now
                                    </Button>
                                  </div>
                                </div>


                              </form>
                            )}

                          {this.state.request_mode === "redeem" &&
                            this.state.selected_order[
                            "mf_order_is_activity_completed"
                            ] && (
                              <div className="card-body">
                                <div>
                                  <div className="card-body">
                                    <form action="#">
                                      <div class="row">
                                        <div
                                          dangerouslySetInnerHTML={this.createDefaultMarkup(
                                            this.state.selected_order[
                                            "mf_order_notification_message"
                                            ]
                                          )}
                                        />
                                      </div>
                                      <div class="row">
                                        <Button
                                          className="btn btn-success btn-block"
                                          variant="success"
                                          onClick={() => this.gotoOrderListPage()}
                                        >
                                          Ok, Done
                                        </Button>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            )}

                        </div>

                      )}

                      {this.state.request_mode === "invest_more" && this.state.wizard_step > 0 &&
                        <Fab
                          style={{ bottom: 27, left: -10, transform: 'scale(0.8)' }}
                          alwaysShowTitle={true}
                          mainButtonStyles={{
                            backgroundColor: "#d45950"
                          }}
                          icon={<i className="fa fa-arrow-left  fa-lg" ></i>}
                          onClick={() => {
                            if (this.state.wizard_step > 1)
                              this.setState({
                                wizard_step: this.state.wizard_step - 1
                              });
                            else
                              this.props.history.goBack();
                          }
                          }
                        >
                        </Fab>
                      }
                      {this.state.request_mode === "invest_more" &&
                        (
                          this.state.wizard_step === 1 &&
                          ((this.state.nominee_option === 'existing' && this.state.existing_nominee_selected) ||
                            (this.state.nominee_option === 'none' /*&& this.state.is_no_nominee_registered*/))
                        ) &&
                        <Fab
                          style={{ bottom: 27, right: -10, transform: 'scale(0.8)' }}
                          alwaysShowTitle={true}
                          mainButtonStyles={{
                            backgroundColor: "#3d61cc"
                          }}
                          icon={<i className="fa fa-arrow-right  fa-lg" ></i>}
                          onClick={() => {
                            this.setState({
                              wizard_step: this.state.wizard_step + 1
                            });
                          }}
                        >
                        </Fab>
                      }

                      {this.state.request_mode === "redeem" &&
                        !this.state.selected_order["mf_order_is_activity_completed"] &&
                        this.state.is_redeem_button_clicked &&
                        <Modal
                          show={
                            this.state
                              .is_redeem_button_clicked
                          }
                          size="lg"
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                          keyboard={false}
                          // fullscreen={true}
                          backdrop="static"
                        >
                          <Modal.Header>
                            For two factor security we have sent one time code to your registered email & mobile number as per RTA
                          </Modal.Header>
                          <Modal.Body>
                            <div class="row">
                              <div class="col">
                                <label>Enter OTP received on your registered mobile</label>
                              </div>
                            </div>
                            <br />
                            <div class="row">
                              <div class="col">
                                <OTPInput
                                  value={this.state.mobile_otp}
                                  onChange={event => {
                                    this.setState({ mobile_otp: event })
                                  }}
                                  shouldAutoFocus={true}
                                  OTPLength={6}
                                  otpType="number"
                                  disabled={false}
                                  isInputSecure={true}
                                  isInputNum={true}
                                />
                              </div>
                            </div>
                            <br />
                            <div class="row">
                              <div class="col">
                                <label>Enter OTP received on your registered email</label>
                              </div>
                            </div>
                            <br />
                            <div class="row">
                              <div class="col">
                                <OTPInput
                                  value={this.state.email_otp}
                                  onChange={event => {
                                    this.setState({ email_otp: event })
                                  }}
                                  shouldAutoFocus={true}
                                  OTPLength={6}
                                  otpType="number"
                                  disabled={false}
                                  isInputSecure={true}
                                  isInputNum={true}
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div class="col">
                                <ResendOTP maxTime={120} renderButton={renderButton} style={{ color: 'black', marginTop: 20 }} renderTime={renderTime}
                                  onResendClick={(e) => { this.onRedeem2FactorOrder() }}
                                />
                              </div>
                            </div>

                            {this.state.isloading && (
                              <Spinner
                                animation="border"
                                variant="dark"
                                style={{
                                  position: "fixed",
                                  top: "50%",
                                  left: "50%",
                                  zIndex: 899,
                                }}
                              />
                            )}
                          </Modal.Body>
                          <Modal.Footer>
                            <div class="row">
                              <div class="col-8">
                                <Button
                                  style={{ width: 200 }}
                                  className="btn btn-success btn-block"
                                  variant="success"
                                  onClick={this.onRedeemOrder}
                                  disabled={
                                    this.state.mobile_otp < 6 ||
                                    this.state.email_otp < 6 ||
                                    this.state.isloading
                                  }
                                >Verify & Confirm</Button>
                                {"      "}
                              </div>
                              <div class="col-4">
                                {"      "}
                                <button type="button"
                                  class="btn btn-danger"
                                  onClick={() => {
                                    this.setState({ is_redeem_button_clicked: false })
                                  }}
                                >
                                  Undo
                                </button>
                              </div>

                            </div>

                          </Modal.Footer>
                        </Modal>

                      }

                      {this.state.request_mode === "paynow" && (
                        <div >
                          <div
                            class="row"
                          >  <div class="col">
                              <label class="mr-sm-2">Payment Mode</label>

                              <Select
                                value={this.state.pay_bank_mode_list.find(obj => obj.value === this.state.selected_order['selected_pay_bank_mode'])}
                                onChange={(e) => {
                                  this.setState({
                                    selected_order: { ...this.state.selected_order, selected_pay_bank_mode: e.value }
                                  });
                                }}
                                options={this.state.pay_bank_mode_list}
                              />

                            </div>
                          </div>
                          {this.state.selected_order['selected_pay_bank_mode'] === 'UPI' &&
                            <div class="row">
                              <div class="col">
                                <label class="mr-sm-2">Enter UPI ID (as per your linked bank account)</label>
                                <input
                                  type="text"
                                  className="form-control text-center font-weight-bold"
                                  value={this.state.selected_order['upi_id']}
                                  onChange={(event) =>
                                    this.setState({
                                      selected_order: { ...this.state.selected_order, upi_id: event.target.value }
                                    })
                                  }
                                  disabled={this.state.selected_order['selected_pay_bank_mode'].length < 3}
                                />
                              </div>
                            </div>
                          }
                          <div class="row">
                            <div class="col">
                              <label class="mr-sm-2"></label>
                              <Button
                                className="btn btn-success btn-block"
                                variant="success"
                                onClick={() => {
                                  this.gotoPaymentPage();
                                }}
                                disabled={this.state.selected_order['selected_pay_bank_mode'].length < 3}
                              >
                                Pay Rs.{" "}
                                {this.state.selected_order["mf_order_pay_amount"]}
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}

                    </div>
                  )}

              </div>
            </div>
          </div>
        </div >
        {this.state.show_nominee_form_modal &&
          <Modal
            show={
              this.state
                .show_nominee_form_modal
            }
            onHide={() =>
              this.setState({ show_nominee_form_modal: false })
            }
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
            fullscreen={true}
            backdrop="static"
          >
            <Modal.Header>{this.state.modal_nominee['info']['name']}</Modal.Header>
            <Modal.Body>
              {this.state.modal_nominee['form'].length > 77 &&
                <tr>
                  <td >
                    <PDFReader
                      data={atob(
                        this.state.modal_nominee['form']
                      )}
                      width="800"
                    />
                  </td>
                </tr>
              }
            </Modal.Body>
            <Modal.Footer>

              <button
                className="btn btn-warning btn-sm"
                style={{ width: 100 }}
                onClick={() =>
                  this.setState({ show_nominee_form_modal: false })
                }
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
        }

        {this.state.show_popup && (
          <Modal show={this.state.show_popup}
            centered>
            <Modal.Header>
            </Modal.Header>
            <Modal.Body>
              <p>{this.state["pop_messagetext"]}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => {

                this.setState({
                  mobile_otp: ``,
                  email_otp: ``,
                  show_popup: false,
                  pop_messagetext: ``,
                });

                if (this.props.order_result["code"] === "000")
                  this.gotoOrderListPage();
              }}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        )}

      </>
    );
  }
}
// SipEditCheckout.propTypes = {
//     validateOtp: PropTypes.func.isRequired,
//     isAuthenticated: PropTypes.bool
// };

/*
const mapStateToProps = state => ({
    loading: state.order.loading,
    order_result: state.order.order_result
});
*/

const mapStateToProps = ({ order }) => {
  const { loading, order_result } = order;
  return {
    loading,
    order_result
  };
};

export default connect(mapStateToProps, {
  modifyOrder,
  additionalOrder,
  redeem2FactorOrder,
  twoFactorAuthentication
})(SipEditCheckout);
