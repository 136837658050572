import React, { } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Form, Spinner, Modal } from 'react-bootstrap';
import { connect } from "react-redux";
import '../../../css/bankdetails.css';
import { verifyBank, verifyStage, loadStageInfo, verifyIdProof } from '../../../jsx/actions/verify';
import Header1 from "../../layout/header1";
import Sidebar from "../../layout/sidebar";
import Select from "react-select";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";

class BankVerification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      suggestions: [],
      text: '',
      bank_account_number: '',
      bank_account_number_confirm: '',
      bank_ifsc_code: '',
      bank_account_type: '',
      bank_name: {},
      is_stage_verified: false,
      is_verify_enabled: false,
      is_confirm_chk_enabled: false,
      is_verified_editable: false,
      selectedFile: null,
      src_selectedFile: null,
      bank_information: [],
      bank_attachment_list: [],
      file_bank_tiff: null,
      show_popup: false,
      pop_messagetext: '',
      list_bank: [],
      list_account_type: [],
      selectedAccountType: [],
      isloading: this.props.loading,
      accountMatch: true,
      show_modal_confirm_dialog: false,
      modal_confirm_dialog_messagetext: 'You are above to submit the bank account details and once submitted you cannot undone. Are you sure to submit now ?',
      previous_stage_verified: false
    }
  }

  componentDidMount() {
    this.onLoadStageInfo();
  }

  closePopup = () => {
    this.setState({
      show_popup: false,
      pop_messagetext: ''
    })
  }

  suggestionSelected(value) {
    this.setState(() => ({
      text: value,
      suggestions: []
    }));
  }

  handleConfirmCheck = (event) => {
    this.setState({ is_confirm_chk_checked: !this.state.is_confirm_chk_checked })
  }


  onLoadStageInfo = () => {

    this.setState({ isloading: true });

    this.props.loadStageInfo({ stage_name: 'bank' }).then(response => {

      if (this.props.bank_result['code'] !== '000')
        this.setState({
          isloading: false,
          show_popup: true,
          pop_messagetext: this.props.bank_result['messageText'],
        });
      else {

        let _state_parameters = { isloading: false };

        if (this.props.bank_result['master_list']) {
          _state_parameters = {
            list_account_type: this.props.bank_result['master_list']['bank_account_type'] || [],
            list_bank: this.props.bank_result['master_list']['bank_list'] || [],
          }
          /*
          this.setState({
            list_account_type: this.props.bank_result['master_list']['bank_account_type'] || [],
            list_bank: this.props.bank_result['master_list']['bank_list'] || [],
          });
          */
        }

        if (this.props.bank_result['state_parameters']) {
          _state_parameters = Object.assign(_state_parameters, this.props.bank_result['state_parameters']);
          // this.setState(this.props.bank_result['state_parameters']);
        }
        else {
          _state_parameters = Object.assign(_state_parameters, {
            is_stage_verified: this.props.bank_result['is_stage_verified'],
            is_verify_enabled: this.props.bank_result['is_verify_enabled'],
            is_confirm_chk_enabled: this.props.bank_result['is_confirm_chk_enabled'],
            is_verified_editable: this.props.bank_result['is_verified_editable'],
            bank_information: this.props.bank_result['id_information'],
            bank_attachment_list: this.props.bank_result['attachment_list'],
          });
          /*
          this.setState({
            is_stage_verified: this.props.bank_result['is_stage_verified'],
            is_verify_enabled: this.props.bank_result['is_verify_enabled'],
            is_confirm_chk_enabled: this.props.bank_result['is_confirm_chk_enabled'],
            is_verified_editable: this.props.bank_result['is_verified_editable'],
            bank_information: this.props.bank_result['id_information'],
            bank_attachment_list: this.props.bank_result['attachment_list'],
          });
          */
        }

        this.setState(_state_parameters);

      }

    })
      .catch(err => {
        alert(err)
      });


  };

  changeHandler = (event) => {

    this.setState({ isloading: true });

    if (event.target.files[0] && (event.target.files[0].type === 'image/png' ||
      event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/jpg')) {

      const uploaded_file = event.target.files[0];

      {

        let bank_information = {};

        this.state.bank_information.forEach(el => {
          bank_information[
            (el['attribute'] || '').replace(/ /gm, "_").toLowerCase()] = el['text'];
        });

        let formData = new FormData();
        formData.append("upload_addbank", uploaded_file);
        formData.append("id_type", 'cheque');
        formData.append("folder_name", 'cheque');
        formData.append("attachment_category", 'cheque');
        formData.append('is_master_update_required', false);
        formData.append("bank_info", JSON.stringify({
          account_number: bank_information['account_no'] ||
            bank_information['account_number'],
          ifsc_code: bank_information['ifsc_code'],
          account_type: bank_information['account_type'],
          bank_name: bank_information['bank_name'],
          account_holder_name: bank_information['account_holder_name']
        }));

        this.props.verifyIdProof(formData, { attachment_category: 'cheque' }, response => {

          this.setState({
            selectedFile: uploaded_file,
            src_selectedFile: URL.createObjectURL(uploaded_file)
          });

          {
            const file = uploaded_file;

            const reader = new FileReader();
            reader.onloadend = () => {
              this.dataURLtoFile(reader.result, 'bse');
            };
            reader.readAsDataURL(file);
          }

          window.scrollTo(0, 1000);

          if (response['code'] === '000') {

            /*
            const is_validated = this.state.bank_information['beneficiary_account_no'] !== response['id_information']['account_no']
              || this.state.bank_information['beneficiary_ifsc'] !== response['id_information']['ifsc'];

            if(is_validated)*/
            this.setState({
              isloading: false,
              is_confirm_chk_enabled: true,
              show_popup: false,
              pop_messagetext: ''
            });
            /*else
              this.setState({
                is_confirm_chk_enabled: false,
                show_popup: true,
                pop_messagetext: 'Account number in cheque does not match with verified account number'
              });*/

          }
          else
            this.setState({
              isloading: false,
              is_confirm_chk_enabled: false,
              show_popup: true,
              pop_messagetext: response['messageText']
            });

        });
        // .catch(err => {
        //   alert(err)
        // });

      }

      /*
      this.setState({
        selectedFile: event.target.files[0],
        src_selectedFile: URL.createObjectURL(event.target.files[0])
      });

      {
          const file = event.target.files[0];
    
          const reader = new FileReader();
          reader.onloadend = () => {
            this.dataURLtoFile(reader.result, 'bse');
          };
          reader.readAsDataURL(file);
      }
      */

    }
    else {

      if (event.target.files.length > 0)
        this.setState({
          show_popup: true,
          pop_messagetext: 'Upload file format should be of PNG, JPEG, JPG only'
        });
    }

  }

  dataURLtoFile(dataurl, filename) {
    this.setState({ isloading: true });
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    this.setState({
      file_bank_tiff: new File([u8arr], (filename + "_bank.tiff"), { type: mime.split('/')[0] + '/tiff' })
    });
    this.setState({ isloading: false });

  }

  onVerify = () => {

    this.setState({
      show_modal_confirm_dialog: false, isloading: true
    })
    const { otp } = this.state;

    let body_parameters = {
      client_bank_account_number_1: this.state.bank_account_number,
      client_ifsc_code_1: this.state.bank_ifsc_code,
      client_bank_account_type_1: this.state.bank_account_type,
      client_bank: this.state.bank_name
    };

    this.props.verifyBank(body_parameters).then(response => {

      if (this.props.bank_result['code'] !== '000')
        this.setState({
          isloading: false,
          show_popup: true,
          pop_messagetext: this.props.bank_result['messageText'],
        });
      else {

        let _state_parameters = { isloading: false };

        if (this.props.bank_result['state_parameters']) {
          _state_parameters = Object.assign(_state_parameters, this.props.bank_result['state_parameters']);
          // this.setState(this.props.bank_result['state_parameters']);
        }
        else {

          _state_parameters = Object.assign(_state_parameters, {
            show_modal_confirm_dialog: false,
            is_stage_verified: this.props.bank_result['is_stage_verified'],
            is_verify_enabled: this.props.bank_result['is_verify_enabled'],
            is_confirm_chk_enabled: this.props.bank_result['is_confirm_chk_enabled'],
            is_verified_editable: this.props.bank_result['is_verified_editable'],
            bank_information: this.props.bank_result['id_information'],
            bank_attachment_list: this.props.bank_result['attachment_list'],
          });
          /*
          this.setState({
            show_modal_confirm_dialog: false,
            is_stage_verified: this.props.bank_result['is_stage_verified'],
            is_verify_enabled: this.props.bank_result['is_verify_enabled'],
            is_confirm_chk_enabled: this.props.bank_result['is_confirm_chk_enabled'],
            is_verified_editable: this.props.bank_result['is_verified_editable'],
            bank_information: this.props.bank_result['id_information'],
            bank_attachment_list: this.props.bank_result['attachment_list'],
          });
          */

        }

        this.setState(_state_parameters);

      }

    })
      .catch(err => {
        alert(err)
      });


  };

  onVerifyStage = () => {

    let formData = new FormData();
    let body_parameters = { verify_stage: 'bank' };

    if (this.state.selectedFile) {

      formData.append("upload_addbank", this.state.selectedFile);
      if (this.state.file_bank_tiff)
        formData.append("upload_addbank", this.state.file_bank_tiff);

      formData.append("id_type", 'bank');
      formData.append("folder_name", 'bank');
      formData.append("attachment_category", 'bank');
      formData.append("verify_stage", "bank")

    }

    this.setState({
      show_modal_confirm_dialog: false, isloading: true
    });

    this.props.verifyStage(this.state.selectedFile ? formData : body_parameters, 'bank', response => {
      this.setState({ isloading: false });
      this.props.history.push({
        pathname: "/" + this.props.bank_result["navigateScreen"] + "/",
      });

    });

  };

  validateAccount = () => {
    const { bank_account_number, bank_account_number_confirm } = this.state;
    if (bank_account_number === bank_account_number_confirm) {
      this.setState({ accountMatch: true });
    } else {
      this.setState({ accountMatch: false });
    }
  };

  handleChangeSelect = (selectedOption, attribute) => {

    // this.setState({ 'bank_account_type':  selectedOption['value'] });
    if (attribute)
      //    this.setState({ [attribute]:  {...this.state[attribute], [input]: selectedOption.value }  });
      // else
      this.setState({
        [attribute]: attribute === 'bank_name' ?
          selectedOption : selectedOption.value
      });

    // this.filteroption(selectedOption);
  };

  render() {

    return (
      <>
        <Header1 />
        <Sidebar />

        <div className="authincation section-padding" style={{ marginTop: 50 }}>
          <div className="content-body">

            {!this.state.isloading && !this.state.previous_stage_verified &&
              <Card sx={{ backgroundColor: '#edf3f0', p: 2, borderRadius: 2 }}>
                <CardContent  >
                  <Stack direction="row">
                    <Typography flex={1} whiteSpace="pre-line" >
                      Sorry the information you are looking for could not be found
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            }

            {this.state.isloading &&
              <Spinner animation="border" variant="dark"
                style={{ position: "fixed", top: "50%", left: "50%" }} />
            }

            <div className="container">
              <div class="row">
                <div class="col-xl-12">

                  <h4 className="text-center">
                    {this.state.is_stage_verified ? `Your Bank Account Information` : `Verify Bank Account`}
                  </h4>


                  {this.state.previous_stage_verified &&
                    <div>
                      <form action="#">

                        {(this.state.is_verify_enabled || this.state.is_verified_editable) &&

                          <div class="form-group">

                            <div class="row">

                                <label class="mr-sm-2">Select Bank</label>

                              <Select
                                value={this.state.list_bank.find(obj => obj.value === this.state.bank_name['value'] || '')}
                                onChange={(e) => this.handleChangeSelect(e, 'bank_name')}
                                options={this.state.list_bank}
                              />

                            </div>
                            <br />
                            <div class="row">
                              <label class="mr-sm-2">Choose Account Type</label>

                              <Select
                                value={this.state.list_account_type.find(obj => obj.value === this.state.bank_account_type)}
                                onChange={(e) => this.handleChangeSelect(e, 'bank_account_type')}
                                options={this.state.list_account_type}
                              />

                            </div>
                            <br />
                            <div class="row">
                              <div class="col">
                                <label>Enter Account Number</label>
                                <input type="text" className="form-control"
                                  value={this.state.bank_account_number}
                                  maxlength="18"
                                  style={{ letterSpacing: 5 }}
                                  onChange={event => {
                                    const targetValue = event.target.value.replace(/\s/g, '');
                                    if ((targetValue.match(/^[0-9]+$/)
                                      || targetValue === "") && targetValue.toString().length <= 18) {
                                      this.setState({
                                        bank_account_number: targetValue
                                      }, this.validateAccount);
                                    }
                                  }
                                  }

                                />
                              </div>

                            </div>
                            <br />
                            <div class="row">
                              <div class="col">
                                <label>Confirm Account Number</label>
                                <input type="password" className="form-control"
                                  value={this.state.bank_account_number_confirm}
                                  maxlength="18"
                                  style={{ letterSpacing: 5 }}
                                  onChange={event => {
                                    const targetValue = event.target.value.replace(/\s/g, '');
                                    if ((targetValue.match(/^[0-9]+$/)
                                      || targetValue === "") && targetValue.toString().length <= 18) {
                                      this.setState({
                                        bank_account_number_confirm: targetValue
                                      }, this.validateAccount);
                                    }
                                  }
                                  }
                                />
                              </div>
                            </div>
                            <br />
                            {this.state.accountMatch ? null : (
                              <p style={{ color: 'red' }}>Account Number do not match</p>
                            )}
                            <br />
                            <div class="row">
                              <div class="col">
                                <label>IFSC Code</label>
                                <input value={this.state.bank_ifsc_code}
                                  onChange={event => {
                                    const targetValue = event.target.value.replace(/\s/g, '');
                                    if ((targetValue.match(/^[0-9a-zA-Z]+$/)
                                      || targetValue === "") && targetValue.toString().length <= 15) {
                                      this.setState({
                                        bank_ifsc_code: targetValue.toUpperCase()
                                      });
                                    }
                                  }
                                  }
                                  maxLength={15}
                                  className="form-control"
                                  type="text" />
                              </div>
                            </div>
                            <br />
                            <div class="row">
                              <div class="col">
                                { /*this.state.bank_information.length === 0*/
                                  (this.state.is_verify_enabled || this.state.is_verified_editable) &&
                                  (this.state.bank_account_number.length > 4 &&
                                    this.state.bank_ifsc_code.length > 4 &&
                                    this.state.bank_account_type.length > 1 &&
                                    this.state.bank_name['value']) &&
                                  this.state.bank_account_number === this.state.bank_account_number_confirm &&
                                  <div className="text-center">
                                    <Button
                                      className="btn btn-success btn-block"
                                      variant="success"
                                      // onClick={this.onVerify}
                                      onClick={() => {
                                        this.setState({ show_modal_confirm_dialog: true })
                                      }}
                                      disabled={this.state.isloading}

                                    >Verify</Button>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        }
                        {this.state.isloading &&

                          <Spinner animation="border" variant="dark"
                            style={{ position: "fixed", top: "50%", left: "50%" }} />
                        }

                        {this.state.bank_information.length > 0 &&
                          this.state.bank_information[0]['text'] &&
                          <div className="card">

                            <div class="row">

                              {(this.state.bank_information || []).map((el, i) =>
                                <div key={i}>
                                  <label className="text-dark">
                                    {el["attribute"]}{` : `}
                                    <label className="text-success">
                                      {el["text"]}
                                    </label>
                                  </label>
                                  <p></p>
                                </div>
                              )
                              }

                            </div>

                            <div class="row">
                              <div class="col">
                                {!this.state.is_stage_verified &&

                                  <div className="text-left">
                                    <p><span style={{ color: 'green', fontWeight: 'bold' }}>Note : </span>Kindly upload your valid cheque (File format should be png or jpeg or jpg)</p>
                                    <Form.Control type="file"
                                      onClick={(event) => { const { target = {} } = event || {}; target.value = ""; }}
                                      onChange={this.changeHandler} />
                                    {this.state.isloading &&
                                      <Spinner animation="border" variant="dark"
                                        style={{ position: "fixed", top: "50%", left: "50%" }} />
                                    }
                                  </div>
                                }


                                {//(this.state.is_verify_enabled || this.state.is_verified_editable) &&
                                  this.state['src_selectedFile'] &&
                                  <div>
                                    <br />
                                    <img src={this.state['src_selectedFile']}
                                      style={{ width: 340, height: 220 }} />
                                  </div>
                                }

                              </div>
                            </div>
                          </div>
                        }

                        {<div className="card" style={{
                          textAlign: 'center'
                        }}>
                          {(this.state.bank_attachment_list || []).map((el, i) =>
                            <div key={i} className="form-group">
                              {el.length > 5 ?
                                <img className="photo" src={el} />
                                : null}
                            </div>
                          )
                          }
                        </div>
                        }



                        {
                          this.state.show_popup &&
                          this.state.pop_messagetext.length > 4 &&
                          <span style={{ color: 'InfoText', backgroundColor: '#e3e0de', padding: 4, paddingLeft: 8, paddingRight: 8, margin: 4, borderRadius: 4 }} >
                            {this.state.pop_messagetext}
                          </span>
                        }
                        <br />
                        {
                          // !this.state.is_stage_verified && 
                          this.state.bank_information.length > 0 &&
                          this.state.bank_information[0]['text'] &&
                          this.state.is_confirm_chk_enabled &&
                          !this.state.show_popup &&
                          ((this.state.bank_attachment_list || []).length > 0 ||
                            this.state.selectedFile) &&
                          <div className="text-center">
                            <Button
                              className="btn btn-success btn-block"
                              variant="success"
                              onClick={this.onVerifyStage}
                              disabled={this.state.isloading}
                            >
                              Confirm
                            </Button>
                          </div>
                        }

                        {
                          this.state.is_stage_verified &&
                          <table style={{ width: '100%' }}>
                            <tr>
                              <td style={{
                                height: 30,
                                textAlign: this.state.is_stage_verified ? 'left' : 'center'
                              }}>
                                <Link to={'/aadhaarVerification'} type="submit"
                                  className="btn btn-warning pl-5 pr-5">Previous</Link>
                              </td>
                              <td style={{ height: 30, textAlign: 'right' }}>

                                <Link to={'/personalVerification'} type="submit"
                                  className="btn btn-success pl-5 pr-5">Next</Link>
                              </td>
                            </tr>
                          </table>
                        }

                      </form>
                    </div>
                  }

                  {this.state.show_modal_confirm_dialog && (
                    <Modal show={this.state.show_modal_confirm_dialog}
                      // onHide={() => this.setState({
                      //   show_modal_confirm_dialog: false
                      // })}
                      centered
                      keyboard={false}
                      // fullscreen={true}
                      backdrop="static"
                    >
                      <Modal.Header>

                      </Modal.Header>
                      <Modal.Body>

                        <p>{this.state["modal_confirm_dialog_messagetext"]}</p>

                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="primary"
                          onClick={() => this.onVerify()}>
                          Yes
                        </Button>
                        <Button variant="primary"
                          onClick={() => this.setState({
                            show_modal_confirm_dialog: false
                          })}>
                          No
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}


const mapStateToProps = state => ({
  bank_result: state.verify.bank_result
});

export default connect(mapStateToProps, { verifyBank, verifyStage, loadStageInfo, verifyIdProof })
  (BankVerification);